<!--<button>-->
<!--    <mat-icon>add</mat-icon>-->
<!--    Add-->
<!--</button>-->

<ng-container *ngIf="!showStyle">
  <div class="top-bar row-spacebetween">
    <div class="row-centered">
      <h4 class="title">Subtitles</h4>
    </div>

    <button
      *ngIf="this.project.statuses.transcript === transcriptStatusEnum.DONE"
      mat-raised-button
      (click)="showStyle = true">
      <mat-icon>format_color_fill</mat-icon>
      Style
    </button>
  </div>

  <app-empty-subtitles
    *ngIf="
      this.project.statuses.transcript === transcriptStatusEnum.NONE &&
      this.loadingData$.value === false
    "
    (generateSubtitlesClicked)="generateSubtitlesAsync()"
    (click)="haveSubtitles = true"></app-empty-subtitles>
</ng-container>

<app-subtitles-style
  *ngIf="showStyle"
  (backClicked)="showStyle = false"
  [projectId]="this.project.id"></app-subtitles-style>

<div
  *ngIf="
    this.project.statuses.transcript !== transcriptStatusEnum.DONE &&
    this.loadingData$.value === true
  "
  class="spinner-wrapper">
  <div class="lottie-wrapper">
    <ng-lottie
      class="lottie"
      [options]="{
        path: 'https://content.shuffll.com/files/lottie-assets/Animation%20-%201723641312348%20(2).json',
        loop: true,
      }">
    </ng-lottie>
  </div>
  <h2>Generating subtitles...</h2>
  <h4>
    Go and grab yourself a snack or continue editing<br />
    your video
  </h4>
</div>

<div
  *ngIf="this.transcriptManager.isGenerateSubtitlesFailed$.value === true"
  class="subtitle-failed">
  <span>Could not generate subtitles</span>
</div>

<ng-container
  *ngIf="
    videosOnTrack?.length > 0 &&
    !showStyle &&
    this.project.statuses.transcript === transcriptStatusEnum.DONE
  ">
  <app-subtitles-list
    [projectId]="this.project.id"
    [videosOnTrack]="videosOnTrack"></app-subtitles-list>
</ng-container>
