<div class="wrapper row-centered">
  <div class="left-side">
    <div>
      <h2>Enjoy unlimited 14-Day Trial 🤩</h2>
      <h5>Try Shuffll's pro features for free. Cancel anytime.</h5>

      <!--    <h3 class="plan-title">-->
      <!--      {{unifiedProduct?.product.name}} Includes:-->
      <!--    </h3>-->
      <div
        class="feature-list"
        *ngIf="plansService.unifiedProduct$ | async as unifiedProduct">
        <div
          class="row-centered feature"
          *ngFor="
            let feature of plansService.unifiedProduct$.value.product
              ?.marketing_features
          ">
          <mat-icon class="smaller-icon">check_circle</mat-icon>
          {{ feature.name }}
        </div>
      </div>
    </div>

    <!--    <div class="plan-price">-->
    <!--      <div class="price-title">-->
    <!--        Limited time offer-->
    <!--      </div>-->
    <!--      <div class="price">-->
    <!--        ${{unifiedProduct?.price?.unit_amount / 100}} / month-->
    <!--      </div>-->
    <!--    </div>-->

    <div
      class="terms"
      *ngIf="plansService.unifiedProduct$ | async as unifiedProduct">
      You can cancel any time in the 14 day trial and you won't be charged.<br />
      At the end of the 14 day period, you will be charged
      {{ unifiedProduct?.price?.unit_amount / 100 }} USD per month. After the
      trial period, your subscription auto-renews while it is active.
      <!--        By subscribing, you agree to our-->
      <!--        <a routerLink="/terms" target="_blank">Terms of Service</a> and-->
      <!--        <a routerLink="/privacy" target="_blank">Privacy Policy</a>.<br/>-->
    </div>
  </div>

  <div
    class="right-side"
    *ngIf="plansService.unifiedProduct$ | async as unifiedProduct">
    <payment-form
      [price]="unifiedProduct.price"
      [product]="unifiedProduct.product"></payment-form>
  </div>
</div>
