<div class="subtitle-row row-centered">
    <mat-form-field class="main-text-area">
        <textarea matInput cdkTextareaAutosize [ngModel]="subtitle.text"
                  (focusout)="onSubtitleChange($event, subtitle)"
                  (keydown)="onkeydown($event, subtitle)"></textarea>
    </mat-form-field>
    <!-- <div class="times reduced-opacity">
          <div class="time-row row-centered">
              <mat-icon class="in-time  smaller-icon ">access_time</mat-icon>
              <div class="time-label">In</div>
              <mat-form-field class="time">
                  <input matInput [ngModel]="subtitle?.start" type="time">
              </mat-form-field>
          </div>
          <div class="time-row row-centered">
              <mat-icon class="in-time smaller-icon">access_time</mat-icon>
              <div class="time-label">Out</div>
              <mat-form-field class="time">
                  <input matInput [ngModel]="subtitle?.start" type="time">
              </mat-form-field>
          </div>
      </div>
      <button mat-icon-button class="reduced-opacity">
          <mat-icon class="smaller-icon">delete</mat-icon>
      </button> -->

    <button
            mat-icon-button
            class="reduced-opacity"
            matTooltip="Hide"
            (click)="hideSubtitle(subtitle)">
        <mat-icon *ngIf="!this.subtitle.isHidden">visibility</mat-icon>
        <mat-icon *ngIf="this.subtitle.isHidden">visibility_off</mat-icon>
    </button>
</div>
