<div *ngIf="this.project" [ngClass]="storyboardLayout">
  <div class="top-section">
    <mat-button-toggle-group
      [disabled]="!projectCreativeDone"
      class="smaller"
      #group="matButtonToggleGroup"
      [(value)]="storyboardLayout">
      <mat-button-toggle
        [value]="storyBoardLayouts.LIST"
        aria-label="View as list">
        <mat-icon>view_list</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        [value]="storyBoardLayouts.GRID"
        aria-label="View as grid">
        <mat-icon>apps</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        [value]="storyBoardLayouts.SCRIPT"
        aria-label="View as grid">
        <mat-icon>table_rows</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="timeline-description">
    <div *ngIf="project.designGroup.shortDescription" class="description"></div>
  </div>
  <form>
    <mat-list>
      <div
        class="scene"
        *ngFor="let scene of this.scenes; index as i"
        id="{{ scene.id }}">
        <!--  Real Scenes  -->

        <ng-container *ngIf="scene.composition">
          <div class="scene-row column-spacebetween">
            <!-- Title -->
            <h5 class="bold italic scene__name">
              <editable
                [enabled]="projectCreativeDone"
                [class.disabled-edit]="!projectCreativeDone"
                class="minimal-editable"
                (save)="updateSceneTitle(scene)">
                <ng-template viewMode>{{ scene.name }}</ng-template>
                <ng-template editMode>
                  <input
                    editableOnEnter
                    editableFocusable
                    editableOnEscape
                    [name]="scene.id + '-title'"
                    [formControl]="scene.formControls.title" />
                </ng-template>
              </editable>
            </h5>
            <div class="scene-actions__icon"></div>
            <div
              fxLayout.xs="column"
              class="scene-and-script row-centered list-row">
              <div
                class="composition-area"
                [style.aspect-ratio]="currentAspectRatio">
                <div *ngIf="scene.isHidden" class="scene-hide-overlay">
                  Excluded Scene
                  <button
                    mat-mini-fab
                    class="cool-button"
                    color="secondary"
                    [class.active]="true"
                    (click)="hideScene(scene)">
                    <mat-icon *ngIf="!this.scene.isHidden">visibility</mat-icon>
                    <mat-icon *ngIf="this.scene.isHidden"
                      >visibility_off
                    </mat-icon>
                  </button>
                </div>
                <div
                  fxHide
                  fxShow.gt-xs
                  class="scene-edit-overlay"
                  *ngIf="
                    (projectCreativeDone || projectCreativeGeneratingImages) &&
                    !scene.isHidden
                  ">
                  <button
                    mat-flat-button
                    (click)="openSceneSetup(scene, project.designGroup.design)">
                    <div class="icon-wrap">
                      <mat-icon>edit</mat-icon>
                    </div>
                    <div class="button-text">Scene<br />Setup</div>
                  </button>
                  <button mat-flat-button (click)="askToDelete(scene)">
                    <div class="icon-wrap">
                      <mat-icon>delete_outline</mat-icon>
                    </div>
                    <div class="button-text">Delete<br />Scene</div>
                  </button>

                  <button
                    mat-flat-button
                    [matMenuTriggerFor]="addMenu"
                    (click)="setCurrentScene(scene); $event.stopPropagation()">
                    <div class="icon-wrap">
                      <mat-icon>add</mat-icon>
                    </div>
                    <div class="button-text">Add<br />Scene</div>
                  </button>
                </div>

                <app-silhouette
                  *ngIf="scene.stagePositions.length > 1"
                  [stagePositions]="scene.stagePositions"></app-silhouette>
                <dynamic-lottie
                  [ngClass]="{
                    hiddenScene: scene.isHidden,
                  }"
                  *ngIf="scene.chosenTake as take"
                  [class.filtered]="!projectCreativeDone && i === 0"
                  [lottieOptions]="
                    (!projectCreativeDone ||
                      !projectCreativeGeneratingImages) &&
                    i === 0
                      ? { loop: 3 }
                      : { loop: 1 }
                  "
                  [config]="
                    take.lottieComposedConfigs?.composeConfigs
                      ?.videoLottieConfigs[0].lottieData
                  "></dynamic-lottie>
              </div>
              <div fxShow fxHide.gt-xs class="mobile-scene-buttons">
                <button
                  mat-flat-button
                  color="primary"
                  (click)="openSceneSetup(scene, project.designGroup.design)">
                  <mat-icon>edit</mat-icon>
                  Setup
                </button>
                <button
                  mat-stroked-button
                  [matMenuTriggerFor]="addMenu"
                  (click)="setCurrentScene(scene); $event.stopPropagation()"
                  color="primary">
                  <mat-icon>add</mat-icon>
                  Add Scene
                </button>
                <button
                  mat-stroked-button
                  (click)="askToDelete(scene)"
                  color="warn">
                  <mat-icon>delete_outline</mat-icon>
                  Delete
                </button>
              </div>
              <div class="script-area">
                <!--  Typewriter -->
                <ng-container
                  *ngIf="
                    i === 0 &&
                      !projectCreativeDone &&
                      !projectCreativeGeneratingImages;
                    else editableTeplate
                  ">
                  <span
                    #typewriterElement
                    appRendered
                    (rendered)="typewriterRendered()"></span>
                  <!-- Placeholder for typewriter -->
                </ng-container>

                <!-- Normal script -->
                <ng-template #editableTeplate>
                  <!-- (save)="updateSceneScript(scene.id)" -->
                  <editable
                    [enabled]="projectCreativeDone"
                    [class.disabled-edit]="!projectCreativeDone"
                    [class.empty]="!(scene.copy?.script?.trim() !== '')"
                    (save)="updateSceneScript(scene)">
                    <ng-template viewMode>{{
                      scene.copy?.script?.trim() !== ''
                        ? scene.copy?.script
                        : 'Write your script here'
                    }}</ng-template>
                    <ng-template editMode style="width: 100%; height: 100%">
                      <textarea
                        placeholder="Write your script here"
                        cdkTextareaAutosize
                        [formControl]="scene.formControls.script"></textarea>
                    </ng-template>
                  </editable>
                </ng-template>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <mat-menu #addMenu="matMenu">
        <button
          mat-menu-item
          (click)="askToAddScene(AddScenePositionEnum.BEFORE)">
          Add before
        </button>
        <button
          mat-menu-item
          (click)="askToAddScene(AddScenePositionEnum.AFTER)">
          Add after
        </button>
      </mat-menu>
      <!--  Skeleton  Scenes -->
      <ng-container
        *ngFor="let fakeScene of numberOfSkeletonScenes; index as i">
        <div
          class="scene__mock mock-scene"
          *ngIf="!projectCreativeDone && !projectCreativeGeneratingImages">
          <ng-container>
            <div class="scene-row column-spacebetween">
              <div
                fxLayout.xs="column"
                class="scene-and-script row-centered list-row">
                <div
                  class="composition-area animated-placeholder"
                  [style.aspect-ratio]="
                    project.designGroup?.design?.aspectRatio ?? '16/9'
                  "></div>
                <div class="script-area fake">
                  <app-generate-script> ></app-generate-script>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </mat-list>
  </form>
</div>
