<mat-form-field *ngIf="this.edit" class="animation-select-box">
  <mat-select
    [ngModel]="this.edit.plugins.subtitles.strategy"
    (selectionChange)="selectAnimation($event.value)">
    <mat-select-trigger
      *ngIf="this.selectedAnimation$ | async as selectedAnimation">
      <!--      (animationCreated)="onAnimationCreated($event, selectedAnimation.uniqueKey) "-->

      <ng-lottie
        class="subtitle-animation"
        containerClass="subtitle-animation-container"
        [options]="{
          path: selectedAnimation.path,
          autoplay: false,
        }"></ng-lottie>
    </mat-select-trigger>
    <ng-container *ngFor="let animation of this.animationItems">
      <mat-option
        role="listitem"
        (mouseenter)="playAnimation(animation.uniqueKey)"
        (mouseleave)="pauseAnimation(animation.uniqueKey)"
        [value]="animation.strategy">
        <ng-lottie
          class="subtitle-animation"
          containerClass="subtitle-animation-container"
          (animationCreated)="onAnimationCreated($event, animation.uniqueKey)"
          [options]="{
            path: animation.path,
            autoplay: false,
          }"></ng-lottie>
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
