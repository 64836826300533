import { Injectable } from '@angular/core';
import { IndexCacheService } from '../../index-cache.service';
import Dexie from 'dexie';
import { DbService } from '../../dexie/db.service';

export interface IProjectIndexConfigs {
  dbName: string;
  storeName: string;
  version: number;
}

export interface IProjectDexieSchema {
  id: string;
  position: number;
  arrayBuffer: ArrayBuffer;
}

export interface IProjectIndexData extends IProjectIndexConfigs {
  openDBPromise: Promise<Dexie>;
}

@Injectable({
  providedIn: 'root',
})
export class SharedProjectDBService {
  constructor(
    private indexDBService: IndexCacheService,
    private dexieDB: DbService
  ) {}

  /**
   * To avoid initalizing the same db if existed we have this shared service.
   * @param indexDBName
   * @returns
   */
  public initializeAsync(projectDBData: IProjectIndexConfigs) {
    const { dbName, storeName, version } = projectDBData;
    const projectSchema: { [key: string]: string } = {
      [storeName]: '++id, position, arrayBuffer',
    };
    const dbPromise = this.dexieDB.openDatabaseAsync(dbName, projectSchema);
    const projectData: IProjectIndexData = {
      dbName: dbName,
      storeName: storeName,
      version: version,
      openDBPromise: dbPromise,
    };
    return projectData;
  }

  public async removeProjectsFromIndexDBAsync(projectId: string) {
    try {
      const currentProjectIdDBName = this.getProjectDBName(projectId);
      await this.dexieDB.deleteDatabasesAsync(
        this.getStartOfDBName(),
        currentProjectIdDBName
      );
    } catch (error) {
      console.error(`Could not remove projects from indexdb, error:`, error);
      throw error;
    }
  }

  getProjectDBName(projectId: string) {
    return `${this.getStartOfDBName()}${projectId}`;
  }
  getStartOfDBName() {
    return 'project_';
  }
}
