import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { BehaviorSubject } from 'rxjs';
import { IStrategyAnimation } from 'src/app/models/project/edit/subtitles/subtitles-configs';
import { EditStyleApiService } from 'src/app/services/api/auth/projects/edit-style-api.service';
import { SUBTITLE_STRATEGY } from 'subtitles-helper/dist/types';
import { EditRoomStateService } from '../../../../edit-room-state.service';
import { IExportEditJob } from 'src/app/models/project/edit/edit-model';

@Component({
  selector: 'subtitle-animation-selector',
  templateUrl: './subtitle-animation-selector.component.html',
  styleUrl: './subtitle-animation-selector.component.scss',
})
export class SubtitleAnimationSelectorComponent implements OnInit {
  FIRST_FRAME_TO_SHOW = 90;

  animationItems: IStrategyAnimation[] = [
    {
      strategy: SUBTITLE_STRATEGY.WORD_HIGHLIGHT,
      path: 'assets/animations/edit-room/subtitle-example-animations/word-highlight_captions_thumb-line.json',
      uniqueKey: '1',
    },
    {
      strategy: SUBTITLE_STRATEGY.WORD_BY_WORD,
      path: 'assets/animations/edit-room/subtitle-example-animations/word-pop.json',
      uniqueKey: '2',
    },
    {
      strategy: SUBTITLE_STRATEGY.KARAOKE,
      path: 'assets/animations/edit-room/subtitle-example-animations/karaoke_captions_thumb-line.json',
      uniqueKey: '3',
    },
    {
      strategy: SUBTITLE_STRATEGY.FULL_UTTERANCE,
      path: 'assets/animations/edit-room/subtitle-example-animations/basic.json',
      uniqueKey: '4',
    },
  ];
  selectedAnimation$ = new BehaviorSubject<IStrategyAnimation>(null);
  SUBTITLE_STRATEGY = SUBTITLE_STRATEGY;

  edit: IExportEditJob;

  private animations: Map<string, AnimationItem> = new Map();
  @Output() animationSelected = new EventEmitter<SUBTITLE_STRATEGY>();

  constructor(private editRoomStateManager: EditRoomStateService) {}

  ngOnInit(): void {
    this.editRoomStateManager.currentEditJob$.subscribe((edit) => {
      if (!edit) return;
      this.edit = edit;
      this.setAnimationValue(edit.plugins.subtitles.strategy);
    });
  }

  public onAnimationCreated(animationItem: AnimationItem, key: string): void {
    this.animations.set(key, animationItem);
    this.gotToFirstFrameToShow(animationItem);
  }

  public gotToFirstFrameToShow(animationItem: AnimationItem): void {
    animationItem.goToAndStop(this.FIRST_FRAME_TO_SHOW, true);
  }

  public playAnimation(key: string): void {
    const animation = this.animations.get(key);
    if (animation) {
      animation.play();
    }
  }

  public pauseAnimation(key: string): void {
    const animation = this.animations.get(key);
    if (animation) {
      animation.pause();
      this.gotToFirstFrameToShow(animation);
    }
  }

  //TODO: to change implementation of this function-> to check if this.edit.plugins.subtitles.strategy equal to each animation and update
  public selectAnimation(event: SUBTITLE_STRATEGY): void {
    console.log('selected animation event:', event);
    this.setAnimationValue(event);
    this.animationSelected.emit(event); // Emit the selected animation strategy
  }

  private setAnimationValue(event: SUBTITLE_STRATEGY) {
    const selected = this.animationItems.find(
      (animation) => animation.strategy === event
    );
    if (selected) {
      this.selectedAnimation$.next(selected);
    }
  }
}
