import {HttpEvent, HttpEventType} from '@angular/common/http';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AnimationItem} from "lottie-web";
import {AssetsAuthApiService} from "../../services/api/auth/assets-auth-api.service";

@Component({
  selector: 'app-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent implements OnInit {

  @Input() accepts = '';
  @Input() text = null;
  @Input() tooltipText = null;
  @Input() hideUpload = false;
  @Input() minifiedImageUpload = false;
  @Input() image = null;

  @Input() public uploadAPI: Function = this.assetsService.upload;
  @Input() public extraData: {} = null;
  @Output() uploadFinished: EventEmitter<any> = new EventEmitter<any>()

  public myCustomMouseover = new EventEmitter<void>();

  constructor(public assetsService: AssetsAuthApiService) {

  }

  ngOnInit() {

  }

  lottie: AnimationItem;


  dropFileAnimationCreated(animationItem: AnimationItem): void {
    // console.log('Cutout animation', animationItem);
    this.lottie = animationItem;

    // this.updateAnimationData()
  }


  @ViewChild("fileDropRef", {static: false}) fileDropEl: ElementRef;
  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);

  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      console.log("Upload in progress.");
      return;
    }
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFiles(file) {
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file, file.name);

    file.inProgress = true;

    this.uploadAPI(formData, this.extraData ?? {}).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            // this.slimLoadingBarService.start();

            console.log("Upload Started", event)

            break;
          case HttpEventType.Response:
            console.log("Uploaded Successfully")

            this.uploadFinished.next(event)
            /// ATTENTION - Will enable only one file upload
            this.files = [];

            // this.slimLoadingBarService.complete();
            // this.message = "Uploaded Successfully";
            // this.showMessage = true;
            break;
          case 1: {
            if (Math.round(file.progress) !== Math.round(event['loaded'] / event['total'] * 100)) {
              file.progress = event['loaded'] / event['total'] * 100;
            }
            break;
          }
        }
      },
      error => {
        console.log(error);
        // this.message = "Something went wrong";
        // this.showMessage = true;
        // this.slimLoadingBarService.reset();
      });


    // setTimeout(() => {
    //   if (index === this.files.length) {
    //     return;
    //   } else {
    //     const progressInterval = setInterval(() => {
    //       if (this.files[index].progress === 100) {
    //         clearInterval(progressInterval);
    //         this.uploadFiles(index + 1);
    //       } else {
    //         this.files[index].progress += 5;
    //       }
    //     }, 200);
    //   }
    // }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    console.log('kind of files', files)

    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
      this.uploadFiles(item);
    }
    // this.fileDropEl.nativeElement.value = "";

  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

}
