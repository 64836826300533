import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureService } from '../services/feature-service.service';

interface FeatureFlagConfig {
  features: string[];
  featureFlagLogic?: 'AND' | 'OR';
  featureFlagMode?: 'ngIf' | 'disabled';
  negativeLogic?: boolean;
}

@Directive({
  selector: '[featureFlag]',
  exportAs: 'featureFlagDirective',
})
export class FeatureFlagDirective implements OnInit {
  @Input('featureFlag') featureFlagConfig: FeatureFlagConfig = {
    features: [],
    featureFlagLogic: 'AND',
    featureFlagMode: 'ngIf',
    negativeLogic: false,
  };
  flagged = false;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private featureService: FeatureService
  ) {}

  ngOnInit() {
    this.evaluateFeatureAccess();
    this.featureService.featureFlagChange.subscribe(() => {
      this.evaluateFeatureAccess();
    });
  }

  private evaluateFeatureAccess() {
    const {
      features,
      featureFlagLogic = 'AND',
      featureFlagMode = 'ngIf',
    } = this.featureFlagConfig;

    const featureObservables: Observable<boolean>[] = features.map((feature) =>
      this.featureService.hasFeatureAccess(feature)
    );

    combineLatest(featureObservables)
      .pipe(
        map((results: boolean[]) => {
          let hasAccess;
          if (featureFlagLogic === 'AND') {
            hasAccess = results.every((isEnabled) => isEnabled);
          } else {
            hasAccess = results.some((isEnabled) => isEnabled);
          }
          if (this.featureFlagConfig.negativeLogic) {
            hasAccess = !hasAccess;
          }
          return hasAccess;
        })
      )
      .subscribe((hasAccess) => {
        this.flagged = hasAccess;

        if (featureFlagMode === 'ngIf') {
          if (hasAccess) {
            this.renderer.removeAttribute(this.el.nativeElement, 'hidden');
          } else {
            this.renderer.setAttribute(this.el.nativeElement, 'hidden', 'true');
          }
        } else if (featureFlagMode === 'disabled') {
          if (hasAccess) {
            this.el.nativeElement.disabled = false;
          } else {
            this.el.nativeElement.disabled = true;
          }
        }

        if (hasAccess) {
          this.renderer.removeAttribute(this.el.nativeElement, 'flagged');
        } else {
          this.renderer.setAttribute(this.el.nativeElement, 'flagged', 'true');
        }
      });
  }
}
