<div *ngIf="realVideoUrl">

  <div class="row-centered">


    <div *ngFor="let thumbnailDataURL of thumbnailDataURLs" class="thumbnail"
         [style.background-image]="'url(' + thumbnailDataURL + ')'">
    </div>
  </div>
  <vg-player #vgPlayer (onPlayerReady)="onPlayerReady($event)">
    <video #media controls preload="auto" [vgDash]="baseCdnUrl + realVideoUrl" [vgMedia]="media"></video>
  </vg-player>

</div>
