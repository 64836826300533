import { MissingArgumentsError } from 'src/app/models/errors/general.errors';
import { getProjectSpecificSceneUrl } from './scene.urls';

const TAKES_URL = '/takes';

export function getRecordingSceneSpecificTakeUrlPUT(
  recordingId: string,
  sceneId: string,
  takeId: string
): string {
  if (!recordingId || !sceneId || !takeId) {
    throw new Error(
      `Could not get general takes url because one of the arguments is null is null`
    );
  }

  const recordingSceneTakesGeneralUrl = getRecordingSceneGeneralTakesUrl(
    recordingId,
    sceneId
  );
  return `${recordingSceneTakesGeneralUrl}/${takeId}`;
}

export function getRecordingSceneGeneralTakesUrl(
  projectId: string,
  sceneId: string
): string {
  if (!projectId || !sceneId) {
    throw new MissingArgumentsError(
      `Could not get general takes url because one of the arguments is null is null`
    );
  }

  const recordingSceneUrl = getProjectSpecificSceneUrl(projectId, sceneId);
  return `${recordingSceneUrl}${TAKES_URL}`;
}

export function getRecordingSceneAddTakePOST(
  projectId: string,
  sceneId: string
) {
  return getRecordingSceneGeneralTakesUrl(projectId, sceneId);
}

export function baseDetailsTakeUrl(
  projectId: string,
  sceneId: string,
  takeId: string
): string {
  if (!projectId || !sceneId || !takeId) {
    throw new MissingArgumentsError(
      `Could not get general takes url because one of the arguments is null is null`
    );
  }

  const recordingSceneTakesGeneralUrl = getRecordingSceneGeneralTakesUrl(
    projectId,
    sceneId
  );
  return `${recordingSceneTakesGeneralUrl}/${takeId}`;
}

export function getUpdateTakePropertiesPUT(
  projectId: string,
  sceneId: string,
  takeId: string
): string {
  const baseTakeDetailsUrl = baseDetailsTakeUrl(projectId, sceneId, takeId);
  return `${baseTakeDetailsUrl}`;
}

export function getTakeVideoLayerToDashUrlPOST(
  projectId: string,
  sceneId: string,
  takeId: string
): string {
  const baseTakeDetailsUrl = baseDetailsTakeUrl(projectId, sceneId, takeId);

  return `${baseTakeDetailsUrl}/dash`;
}
