import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { IProject } from 'src/app/models/project-model';
import { ProjectStoreService } from 'src/app/services/state-management/project/project-store.service';
import { EditRoomStateService } from '../../../edit-room-state.service';
import { SUBTITLE_STRATEGY } from 'subtitles-helper/dist/types';
import {
  ISubtitleSettingsInDTO,
  SubtitlesStylesEnum,
} from '../../../../../../../../models/project/edit/subtitles/styles/subtitles-styles';
import {
  SnackbarActionEnum,
  SnackBarService,
} from '../../../../../../../../services/utils/snack-bar.service';
import { FontsStoreService } from '../../../../../../../../services/state-management/configs/fonts-store.service';
import { IFont } from '../../../../../../../../models/configs/fonts.model';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ConfigurationService } from '../../../../../../../../services/configuration.service';
import { SubtitlesService } from '../../../../../../../../services/show/subtitles.service';
import { IExportEditJob } from '../../../../../../../../models/project/edit/edit-model';

interface IFontLocal {
  font: IFont;
  hasBold: boolean;
  hasItalic: boolean;
}

@Component({
  selector: 'app-subtitles-style',
  templateUrl: './subtitles-style.component.html',
  styleUrl: './subtitles-style.component.scss',
})
export class SubtitlesStyleComponent implements OnInit, OnDestroy {
  @Input('projectId') projectId: string;

  @Output() backClicked = new EventEmitter();
  project: IProject;
  subtitlesStylesEnum = SubtitlesStylesEnum;
  defaultSubTitleSettings: ISubtitleSettingsInDTO;
  highlightSubTitleSettings: ISubtitleSettingsInDTO;

  textDecorations: string[] = [];

  fonts: IFont[];
  fonts$ = new BehaviorSubject<IFont[]>(null);
  selectedFont: IFontLocal = null;
  isInitalized: boolean = false;
  fontSizes: string[] = [
    ...Array.from({ length: (20 - 8) / 2 + 1 }, (_, i) =>
      (i * 2 + 8).toString()
    ),
    ...Array.from({ length: (50 - 24) / 4 + 1 }, (_, i) =>
      (i * 4 + 24).toString()
    ),
  ];
  onDestroy$ = new Subject<boolean>();
  baseCdnUrl: string;
  edit: IExportEditJob;
  isAutoOutlineToggleOn: boolean = false;
  isAutoShadowToggleOn: boolean = false;
  isAutoBackgroundToggleOn: boolean = false;
  deafultShadowValue: string;
  lastShadowValue: string;
  lastOutlineValue: string;
  LAST_OUTLINE__VALUE_LOCALSTORAGE_KEY = 'lastOutlineValue';
  LAST_SHADOW__VALUE_LOCALSTORAGE_KEY = 'lastShadowValue';

  constructor(
    private projectStore: ProjectStoreService,
    private editRoomStateManager: EditRoomStateService,
    private subtitlesService: SubtitlesService,
    private snackBar: SnackBarService,
    private fontsStoreService: FontsStoreService,
    private config: ConfigurationService
  ) {
    this.baseCdnUrl = this.config.baseCdnUrl;
  }

  ngOnInit(): void {
    this.initalizeEdit();
    this.initalizeProject();
    this.subscribeToFonts();
  }

  public updateStyle(
    property: string,
    value: any,
    styleType: SubtitlesStylesEnum
  ) {
    if (property.toLowerCase().includes('fontname')) {
      this.updateCurrentFont(value, this.fonts);
    }

    switch (styleType) {
      case SubtitlesStylesEnum.BOTH:
        this.updateBothSubtitlesStyleProperty(property, value);
        break;
      case SubtitlesStylesEnum.NORMAL:
        this.updateDefaultSubtitlesStyleProperty(property, value);
        break;
      case SubtitlesStylesEnum.HIGHLIGHTED:
        if (property.includes('PrimaryColour')) {
          this.defaultSubTitleSettings.style.SecondaryColour = value;
          this.updateDefaultSubtitlesStyleProperty('SecondaryColour', value);
        }
        this.updateHighlightSubtitlesStyleProperty(property, value);
        break;
    }

    this.editRoomStateManager.changeSubtitle();
  }

  public updateTextDecoration(event: any) {
    const toggle = event.source;
    if (toggle.value === 'bold') {
      this.defaultSubTitleSettings.style.Bold = toggle.checked ? '1' : '0';
    } else if (toggle.value === 'italic') {
      this.defaultSubTitleSettings.style.Italic = toggle.checked ? '1' : '0';
    } else if (toggle.value === 'underlined') {
      this.defaultSubTitleSettings.style.Underline = toggle.checked ? '1' : '0';
    }
    this.updateBothSubtitlesStyleProperty(
      'Bold',
      this.defaultSubTitleSettings.style.Bold
    );
    this.updateBothSubtitlesStyleProperty(
      'Italic',
      this.defaultSubTitleSettings.style.Italic
    );
    this.updateBothSubtitlesStyleProperty(
      'Underline',
      this.defaultSubTitleSettings.style.Underline
    );

    this.editRoomStateManager.changeSubtitle();
  }

  public onAnimationSelected(animationKey: SUBTITLE_STRATEGY): void {
    this.subtitlesService.updateStrategy(
      this.projectId,
      this.edit.id,
      animationKey
    );
    this.edit.plugins.subtitles.strategy = animationKey;

    this.editRoomStateManager.changeSubtitle();
  }

  public onOutlineToggleChange(event: boolean): void {
    if (event) {
      this.updateBothSubtitlesStyleProperty('BorderStyle', '1');
      this.defaultSubTitleSettings.style.BorderStyle = '1';
      this.defaultSubTitleSettings.style.Outline =
        this.getLastValueFromLocalStorage(
          this.LAST_OUTLINE__VALUE_LOCALSTORAGE_KEY
        );
    } else {
      this.lastOutlineValue = this.defaultSubTitleSettings.style.Outline;
      this.saveLastValueToLocalStorage(
        this.LAST_OUTLINE__VALUE_LOCALSTORAGE_KEY,
        this.lastOutlineValue
      );

      this.defaultSubTitleSettings.style.Outline = '0';
      this.defaultSubTitleSettings.style.BorderStyle = '0';
    }
    this.updateDefaultSubtitlesStyleProperty(
      'Outline',
      this.defaultSubTitleSettings.style.Outline
    );

    this.editRoomStateManager.changeSubtitle();

    this.isAutoOutlineToggleOn = event;
    if (event) {
      this.isAutoBackgroundToggleOn = false;
    }
  }

  public onBackgroundToggleChange(event: boolean): void {
    if (event) {
      this.updateBothSubtitlesStyleProperty('BorderStyle', '3');
      this.defaultSubTitleSettings.style.BorderStyle = '3';
      this.defaultSubTitleSettings.style.Outline =
        this.getLastValueFromLocalStorage(
          this.LAST_OUTLINE__VALUE_LOCALSTORAGE_KEY
        );
      this.defaultSubTitleSettings.style.Shadow = '0';
      this.isAutoOutlineToggleOn = false;
      this.isAutoShadowToggleOn = false;
    } else {
      this.defaultSubTitleSettings.style.Outline = '0';
      this.defaultSubTitleSettings.style.BorderStyle = '0';
    }
    this.updateDefaultSubtitlesStyleProperty(
      'BorderStyle',
      this.defaultSubTitleSettings.style.BorderStyle
    );
    this.editRoomStateManager.changeSubtitle();

    this.isAutoBackgroundToggleOn = event;
  }

  public onShadowToggleChange(event: boolean): void {
    if (event) {
      this.defaultSubTitleSettings.style.Shadow =
        this.getLastValueFromLocalStorage(
          this.LAST_SHADOW__VALUE_LOCALSTORAGE_KEY
        );

      if (this.defaultSubTitleSettings.style.BorderStyle === '3') {
        this.defaultSubTitleSettings.style.Outline = '0';
      }
      this.isAutoBackgroundToggleOn = false;
    } else {
      this.lastShadowValue = this.defaultSubTitleSettings.style.Shadow;
      this.saveLastValueToLocalStorage(
        this.LAST_SHADOW__VALUE_LOCALSTORAGE_KEY,
        this.lastShadowValue
      );
      this.defaultSubTitleSettings.style.Shadow = '0';
    }
    this.updateDefaultSubtitlesStyleProperty(
      'Shadow',
      this.defaultSubTitleSettings.style.Shadow
    );
    this.editRoomStateManager.changeSubtitle();

    this.isAutoShadowToggleOn = event;
  }

  private initializeTextDecorations(): void {
    this.textDecorations = [];
    if (this.defaultSubTitleSettings.style.Bold === '1') {
      this.textDecorations.push('bold');
    }
    if (this.defaultSubTitleSettings.style.Italic === '1') {
      this.textDecorations.push('italic');
    }
    if (this.defaultSubTitleSettings.style.Underline === '1') {
      this.textDecorations.push('underlined');
    }
  }

  private initializeTogglesValuesFromLocalStorage(): void {
    this.lastShadowValue = this.getLastValueFromLocalStorage(
      this.LAST_SHADOW__VALUE_LOCALSTORAGE_KEY
    );
    this.lastOutlineValue = this.getLastValueFromLocalStorage(
      this.LAST_OUTLINE__VALUE_LOCALSTORAGE_KEY
    );
  }

  private initializedToggles() {
    this.isAutoOutlineToggleOn =
      this.defaultSubTitleSettings.style.BorderStyle === '1';
    this.isAutoBackgroundToggleOn =
      this.defaultSubTitleSettings.style.BorderStyle === '3';
    this.isAutoShadowToggleOn =
      Number(this.defaultSubTitleSettings.style.Shadow) > 0;

    if (this.isAutoShadowToggleOn) {
      this.deafultShadowValue = this.defaultSubTitleSettings.style.Shadow;
    }
  }

  private updateDefaultSubtitlesStyleProperty(key: string, value: any) {
    const defaultSubtitle = this.edit.plugins.subtitles.settings.find(
      (setting) => setting.type === SubtitlesStylesEnum.NORMAL
    );

    this.subtitlesService.updateSubtitlesStyleProperty(
      this.projectId,
      this.edit.id,
      defaultSubtitle.id,
      key,
      value
    );
  }

  private updateHighlightSubtitlesStyleProperty(key: string, value: any) {
    const highlightedSubtitle = this.edit.plugins.subtitles.settings.find(
      (subtitle) => subtitle.type === SubtitlesStylesEnum.HIGHLIGHTED
    );

    this.subtitlesService.updateSubtitlesStyleProperty(
      this.projectId,
      this.edit.id,
      highlightedSubtitle.id,
      key,
      value
    );
  }

  private updateBothSubtitlesStyleProperty(key: string, value: any) {
    for (const setting of this.edit.plugins.subtitles.settings) {
      setting.style[key] = value;
    }
    this.updateDefaultSubtitlesStyleProperty(key, value);
    this.updateHighlightSubtitlesStyleProperty(key, value);
  }

  private subscribeToFonts() {
    this.fonts$.pipe(takeUntil(this.onDestroy$)).subscribe((fonts) => {
      this.fonts = fonts;

      this.checkIfComponentReady();
      if (this.isInitalized) {
        const fontFromStyle = this.defaultSubTitleSettings.style.Fontname;
        this.updateCurrentFont(fontFromStyle, fonts);
      }
    });

    this.fontsStoreService.fonts$.subscribe({
      next: (fonts) => {
        this.fonts$.next(fonts);
        fonts?.map((font) => {
          console.log(`FONT IMAGE:`, this.baseCdnUrl + font.imageUrl);
        });
      },
      error: (error) => {
        console.error(`Error while trying to get fonts. Error: `, error);
        this.snackBar.openMessage(
          'Could not get fonts',
          SnackbarActionEnum.Close,
          5000
        );
      },
    });
  }

  private initalizeEdit() {
    this.editRoomStateManager.currentEditJob$.subscribe((edit) => {
      if (!edit) {
        return;
      }
      this.edit = edit;
      this.checkIfComponentReady();
    });
  }

  private initalizeProject() {
    this.projectStore.projectSource$.subscribe((project) => {
      this.project = project;
      this.defaultSubTitleSettings = this.edit.plugins.subtitles.settings[0];
      this.highlightSubTitleSettings = this.edit.plugins.subtitles.settings[1];
      this.saveLastValueToLocalStorage(
        this.LAST_OUTLINE__VALUE_LOCALSTORAGE_KEY,
        this.defaultSubTitleSettings.style.Outline
      );
      this.saveLastValueToLocalStorage(
        this.LAST_SHADOW__VALUE_LOCALSTORAGE_KEY,
        this.defaultSubTitleSettings.style.Shadow
      );
      this.initializedToggles();
      this.initializeTogglesValuesFromLocalStorage();
      this.initializeTextDecorations();
      this.checkIfComponentReady();
    });
  }

  private checkIfComponentReady() {
    if (this.project && this.edit && this.fonts) {
      this.isInitalized = true;
    } else this.isInitalized = false;
  }
  private updateCurrentFont(fontName: string, fonts: IFont[]) {
    const chosenFont = fonts.find((font) => font.name === fontName);
    if (!chosenFont) return;

    const fontWeights = chosenFont.configs.weights;
    const hasBold = fontWeights.bold || fontWeights.light || fontWeights.medium;
    const hasItalic = chosenFont.italicUrl;
    this.selectedFont = {
      font: chosenFont,
      hasBold: hasBold,
      hasItalic: !!hasItalic,
    };
    if (this.defaultSubTitleSettings.style.Bold === '1' && !hasBold) {
      this.updateStyle('Bold', '0', SubtitlesStylesEnum.BOTH);
    }
    if (this.defaultSubTitleSettings.style.Italic === '1' && !hasItalic) {
      this.updateStyle('Italic', '0', SubtitlesStylesEnum.BOTH);
    }
  }

  private saveLastValueToLocalStorage(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  private getLastValueFromLocalStorage(key: string): string {
    return localStorage.getItem(key);
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  protected readonly Number = Number;
}
