<ng-container *ngIf="videosOnTrack?.length > 0">
  <div class="take" *ngFor="let editTake of videosOnTrack">
    <div *ngIf="editTake?.take?.copy?.transcript?.sentences; let subtitles">
      <!--            {{editTake?.take?.copy?.transcript?.sentences?.length}}-->

      <app-subtitle-row
        *ngFor="let subtitle of subtitles"
        [subtitle]="subtitle"
        [projectId]="this.projectId"
        [sceneId]="editTake.sceneId"
        [take]="editTake?.take"></app-subtitle-row>
    </div>
  </div>
</ng-container>
