<div class="wrapper">
  <!--  <div class="title" *ngIf="product">-->
  <!--    Supercharge Your Videos With-->
  <!--    <br/><b>{{ product.name }}</b>-->
  <!--  </div>-->

  <div class="check-price" *ngIf="stripe && elementsOptions">
    <div class="check-top">
      <div class="period-title">Monthly Plan</div>
      <div class="price">${{ price.unit_amount / 100 }}/month</div>
    </div>

    <div class="check-body">
      <div class="check-row row-centered row-spacebetween">
        <div>Subtotal</div>
        <div>${{ price.unit_amount / 100 | number: '1.2-2' }}</div>
      </div>
      <div class="divider"></div>
      <div class="check-row row-centered row-spacebetween">
        <div>Billed Now</div>
        <div>$0.00</div>
      </div>
    </div>
  </div>

  <div class="column-centered">
    <div class="column-centered">
      <loading-indicator
        *ngIf="!stripe || !elementsOptions"></loading-indicator>

      <form
        [formGroup]="paymentForm"
        (ngSubmit)="paymentButtonClick()"
        *ngIf="stripe && elementsOptions">
        <div></div>
        <div>
          <div class="payment-details-title">Payment details</div>
          <!-- <ngx-stripe-elements [elementsOptions]="elementsOptions" [stripe]="stripe">
                      <ngx-stripe-express-checkout [options]="expressCheckoutElementOptions"
                                                   (confirm)="expressCheckoutConfirmed($event)"></ngx-stripe-express-checkout>
                  </ngx-stripe-elements>

                  <div class="credit-card-title">
                      Pay With Credit Card
                  </div> -->
          <mat-form-field class="name-form-field">
            <mat-label>Cardholder Name</mat-label>
            <input matInput type="text" id="name" formControlName="name"/>
          </mat-form-field>
          <ngx-stripe-card-group
            [stripe]="this.stripe"
            [formGroup]="paymentForm"
            [elementsOptions]="elementsOptions">
            <ngx-stripe-card-number
              [options]="cardOptions"></ngx-stripe-card-number>
            <div class="expiry-cvc-container">
              <ngx-stripe-card-expiry
                [options]="cardOptions"></ngx-stripe-card-expiry>
              <ngx-stripe-card-cvc
                [options]="cardOptions"></ngx-stripe-card-cvc>
            </div>
          </ngx-stripe-card-group>
        </div>
        <div class="no-payment-due">
          <mat-icon>check</mat-icon>
          <span class="text">No payments due now.</span>
        </div>
        <div class="column-centered">
          <button
            class="payment-button"
            type="submit"
            mat-raised-button
            color="primary"
            [disabled]="!paymentForm.valid || processing"
            [class.button-loading-spinner]="processing">
            <!--          {{ processing ? '' : 'Pay $' + price.unit_amount / 100 + '/mo' }}-->
            {{ processing ? '' : 'Start Free Trial' }}
          </button>
        </div>
      </form>
    </div>
    <!--    <div class="terms">-->
    <!--      You can cancel any time in the 14 day trial and you won't be charged.<br>-->
    <!--      At the end of the 14 day period, you will be charged {{price.unit_amount / 100}} USD per month.-->
    <!--      After the trial period, your subscription auto-renews while it is active.-->
    <!--      &lt;!&ndash;        By subscribing, you agree to our&ndash;&gt;-->
    <!--      &lt;!&ndash;        <a routerLink="/terms" target="_blank">Terms of Service</a> and&ndash;&gt;-->
    <!--      &lt;!&ndash;        <a routerLink="/privacy" target="_blank">Privacy Policy</a>.<br/>&ndash;&gt;-->

    <!--    </div>-->
  </div>
</div>
