import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ProjectStatusEnum} from '../../../../../../models/defines';

@Component({
    selector: 'project-title',
    templateUrl: './project-title.component.html',
    styleUrls: ['./project-title.component.scss'],
})
export class ProjectTitleComponent implements OnInit {
    @Input('projcetName') name = null;
    @Output() projectNameChanged = new EventEmitter<string>();
    @Input() allowNameChange = false;
    @Input() phase = ProjectStatusEnum.PLANNING;

    nameFormControl: FormControl<string>;

    constructor() {
    }

    update() {
        if (this.name !== this.nameFormControl.value) {
            this.name = this.nameFormControl.value;
            this.projectNameChanged.emit(this.name);
        }
    }

    cancel() {
        this.nameFormControl.setValue(this.name);
    }

    ngOnInit(): void {
        this.nameFormControl = new FormControl(this.name);
    }

    protected readonly ProjectStatus = ProjectStatusEnum;
}
