import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  POST_NEW_DOCUMENT_URL,
  POST_UPLOAD_FILES_URL,
} from 'src/app/modules/shared/public/urls/chunks-uploader.urls';
interface IPosition {
  position: number;
}
export enum ChunksUploadTypeEnum {
  RECORDING = 'recording',
}
export interface INewChunksDocumentParams {
  uploadType: ChunksUploadTypeEnum;
  additionalParams: IPosition;
}

export interface INewDocumentConfigs {
  id: string;
  relativeAppendFilePath: string;
}
@Injectable({
  providedIn: 'root',
})
export class ChunksUploaderApiService {
  constructor(private http: HttpClient) {}

  public getDocumentIdToUploadChunks$(
    additionalParams: INewChunksDocumentParams
  ) {
    return this.http.post<INewDocumentConfigs>(
      POST_NEW_DOCUMENT_URL,
      additionalParams
    );
  }

  public uploadChunks$(formData: FormData) {
    if (!formData) {
      console.error(`Could not upload chunks because form data is null!`);
      return;
    }

    return this.http.post<boolean>(POST_UPLOAD_FILES_URL, formData);
  }

  public generateFormDataName(id: string, position: number): string {
    // Check if any parameters are provided
    if (!id || !position) {
      console.error(
        `Could not generate form data name because no parameters were provided.`
      );
      return null;
    }

    // Construct the form data name
    const formDataName = `id:${id}!_!position:${position}`;

    return formDataName;
  }
  // public generateFormDataName(params: Record<string, string>): string {
  //   // Check if any parameters are provided
  //   if (Object.keys(params).length === 0) {
  //     console.error(
  //       `Could not generate form data name because no parameters were provided.`
  //     );
  //     return null;
  //   }

  //   // Construct the form data name
  //   const formDataName = Object.entries(params)
  //     .map(([key, value]) => {
  //       // No need to validate keys and values
  //       return `${key}:${value}`;
  //     })
  //     .join('!_!');

  //   return formDataName;
  // }
}
