import { MissingArgumentsError } from 'src/app/models/errors/general.errors';
import { getDetailsEditUrl } from './edit.urls';

const SUBTITLES_URL = '/subtitles';

export function geteEditSpecificStyleUrl(
  projectId: string,
  editId: string,
  settingId: string
) {
  if (!projectId || !editId || !settingId) {
    throw new MissingArgumentsError(
      `Could not get specific style url because one of the arguments is null is null`
    );
  }

  const generalStyleUrl = getStylesGeneralUrl(projectId, editId, settingId);
  return `${generalStyleUrl}`;
}

export function getStylesGeneralUrl(
  projectId: string,
  editId: string,
  settingId: string
): string {
  if (!editId) {
    throw new Error(`Could not get style url because edit id is null`);
  }

  const baseEditUrl = getDetailsEditUrl(projectId, editId);
  return `${baseEditUrl}${SUBTITLES_URL}/${settingId}`;
}
