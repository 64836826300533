import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-empty-subtitles',
  templateUrl: './empty-subtitles.component.html',
  styleUrl: './empty-subtitles.component.scss',
})
export class EmptySubtitlesComponent {
  @Output()
  generateSubtitlesClicked = new EventEmitter<void>();

  generateSubtitles() {
    this.generateSubtitlesClicked.emit();
  }
}
