import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  HostListener,
} from '@angular/core';
import { Output } from '@angular/core';
import { ISceneAndEditTakes } from 'src/app/models/defines';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { IVideoEditTake } from 'src/app/models/project/edit/edit-model';
import { MatDialog } from '@angular/material/dialog';
import { ProjectAuthApiService } from 'src/app/services/api/auth/project-auth-api.service';
import { SceneConverterService } from 'src/app/services/project/convertors/scene-converter.service';
import {
  IProject,
  ScenePropertiesToUpdateEnum,
} from 'src/app/models/project-model';
import { ProjectStoreService } from 'src/app/services/state-management/project/project-store.service';
import { ReplaceTakeDialogComponent } from 'src/app/components/dialogs/replace-take-dialog/replace-take-dialog.component';

@Component({
  selector: 'edit-room-take-list',
  templateUrl: './edit-room-take-list.component.html',
  styleUrls: ['./edit-room-take-list.component.scss'],
})
export class EditRoomTakeList implements OnInit, OnDestroy, OnChanges {
  project: IProject;
  scenesAndVideoScenes: ISceneAndEditTakes[] = [];

  panelOpenState: boolean = false;
  private onDestroy$ = new Subject<boolean>();

  // selectedTake: ITake;

  // @Input('cdk-drop-to')
  // cdkDropListConnectedTo: string;

  @Input('add-play-button')
  addPlayButton: boolean = true;

  @Output()
  playSceneEvent = new EventEmitter<IVideoEditTake>();

  @Output()
  changeTakeInTimeLine = new EventEmitter<IVideoEditTake>();

  @Output()
  stopSceneEvent = new EventEmitter<IVideoEditTake>();

  pauseOrPlay = new EventEmitter<boolean>();

  @Input()
  isPlayingSpecificScene: boolean;

  @Input()
  sceneChanged: IVideoEditTake;

  @Input()
  isRunning: boolean;
  isRunning$ = new BehaviorSubject<boolean>(false);

  // isPlaying: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private projectStoreService: ProjectStoreService,
    private projectAuthApiService: ProjectAuthApiService,
    private sceneConverter: SceneConverterService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isRunning']) {
      this.isRunning$.next(this.isRunning);
    }
  }

  playTakePreview(videoEditTake: IVideoEditTake) {
    if (videoEditTake) {
      videoEditTake.isPlaying = !videoEditTake?.isPlaying;
    }
    this.pauseOrPlayPressed();

    this.sceneChanged = videoEditTake;
    this.playSceneEvent.emit(videoEditTake);
  }

  playEditTake(editTake: IVideoEditTake) {
    this.pauseOrPlayPressed();
    if (editTake?.isPlaying) {
      editTake.isPlaying = !editTake?.isPlaying;
    }
    this.sceneChanged = editTake;
    this.playSceneEvent.emit(editTake);
  }

  replaceTakeInTimeLineAndTakeList(
    scene: IVideoEditTake,
    editVideoTake: ISceneAndEditTakes
  ) {
    this.dialog
      .open(ReplaceTakeDialogComponent, {
        width: '500px',
        height: '150px',
      })
      .afterClosed()
      .subscribe(async (res) => {
        if (!res) return;
        if (res === true) {
          const params = {
            projectId: this.project.id,
            sceneId: scene.sceneId,
            value: scene.take.id,
            key: ScenePropertiesToUpdateEnum.SELECTED_TAKE_ID,
          };
          this.projectAuthApiService
            .updateSceneProperty$(params)
            .subscribe(async (res) => {
              if (!res) return;
              const newLocalScene = await this.sceneConverter.inToLocalAsync(
                res,
                this.project.designGroup.design.basePath,
                false,
                this.project.id,
                this.project.indexDBData
              );
              this.projectStoreService.replaceOrAddProjectScenes(
                this.project.id,
                newLocalScene,
                0
              );
            });
          this.changeTakeInTimeLine.emit(scene);
        } else {
          return;
        }
      });
  }

  public pauseOrPlayPressed() {
    this.pauseOrPlay.emit(!this.isRunning);
  }

  ngOnInit(): void {
    this.projectStoreService.projectSource$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((project) => {
        if (!project) return;
        this.project = project;
        this.scenesAndVideoScenes = this.project?.scenes.map((scene) => {
          const sceneAndEditTakes: ISceneAndEditTakes = {
            scene: scene,
            videoScenes: [],
          };
          for (const take of scene.takes) {
            if (!take.videoEditTake) continue; /// Validating video edit takes to be true

            sceneAndEditTakes.videoScenes.push(take.videoEditTake);
          }
          return sceneAndEditTakes;
        });
      });
  }

  ngOnDestroy(): void {
    //throw new Error('Method not implemented.');
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  playOrPauseScene(scene: IVideoEditTake) {
    if (!scene) return;
    /// using the opposite value because it will be changed after emiting the event
    scene.isPlaying = !this.isPlayingSpecificScene;
    console.log('scene is playing', scene.isPlaying);
    console.log('is playingggg from scene list', this.isPlayingSpecificScene);

    this.playSceneEvent.emit(scene);
  }
}
