import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {map} from 'rxjs';
import {IScene} from 'src/app/models/project/scene-model';
import {TakeStatusEnum} from 'src/app/models/project/take/take-model';
import {MediaDevicesService} from 'src/app/services/recording/media-devices.service';

interface CustomMediaDeviceInfo extends MediaDeviceInfo {
    cleanedLabel: string;
}

@Component({
    selector: 'studio-buttons-toolbar',
    templateUrl: './studio-buttons-toolbar.component.html',
    styleUrls: ['./studio-buttons-toolbar.component.scss']
})
export class StudioButtonsToolbarComponent implements OnInit {
    TakeStatusEnum = TakeStatusEnum;
    @Output() recordButtonClicked = new EventEmitter();
    @Output() addTakeButtonClicked = new EventEmitter();
    @Output() stopButtonClicked = new EventEmitter();
    @Output() editSceneClicked = new EventEmitter();
    @Output() nextSceneClicked = new EventEmitter();

    @Input() chosenScene: IScene;
    @Input() isRecordingInProgress: boolean;
    @Input() isCoundownInProgress: boolean;

    @Input() disableRecording: boolean;
    @Input() isAudioMuted: boolean;

    @Output() muteToggled = new EventEmitter();


    constructor(public mediaDevicesService: MediaDevicesService) {
    }


    cameraListEmpty$ = this.mediaDevicesService.cameras$.pipe(
        map(cameras => !cameras || cameras.length === 0)
    );
    microphoneListEmpty$ = this.mediaDevicesService.microphones$.pipe(
        map(microphones => !microphones || microphones.length === 0)
    )

    ngOnInit() {

    }

    /**
     * Switches to the first available camera that is not the selected camera
     */
    switchCamera() {
        const availableCameras = this.mediaDevicesService.getCameras(true);
        if (availableCameras && availableCameras.length > 0) {
            const camera = availableCameras[0];
            this.mediaDevicesService.selectCamera(camera.deviceId);
        }
    }

    muteMic() {
        // implementation of muteMic method
    }
}


