import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddScenePositionEnum, IDesign } from '../../../models/design.model';
import { ArtDirectorService } from '../../../services/art-director.service';
import { ProjectAuthApiService } from 'src/app/services/api/auth/project-auth-api.service';
import { IScene } from 'src/app/models/project/scene-model';
import { SceneConverterService } from 'src/app/services/project/convertors/scene-converter.service';
import { ProjectStoreService } from 'src/app/services/state-management/project/project-store.service';
import {
  DynamicItemType,
  IDynamicIdentifier,
  IDynamicLottieChange,
  IStagePosition,
} from 'lottie-json-helper/lib/types';
import {
  DynamicAsset,
  IComposition,
  ICustomLayout,
  IStagePositionsExtended,
} from 'src/app/models/defines';
import { DynamicLottie } from 'lottie-json-helper/lib/dynamic-lottie';

export interface IAddSceneDialogData {
  projectId: string;
  scenes: IScene[];
  design: IDesign;
  relativeSceneId: string;
  relativePosition: AddScenePositionEnum;
}

export interface ICompositionAndStagePosition {
  composition: IComposition;
  stagePositions: IStagePosition[]; /// We don't use the extended one because we don't need the id, only to display the values (x,y,width,height)
}

@Component({
  selector: 'app-scene-bank',
  templateUrl: './scene-bank.component.html',
  styleUrls: ['./scene-bank.component.scss'],
})
export class SceneBankComponent implements OnInit {
  personalizedDynamics: IDynamicLottieChange[];
  design: IDesign;
  chosenComposition: IComposition;
  isLoadingNewScene: boolean;
  sceneCompositions: ICompositionAndStagePosition[] = [];

  constructor(
    private artDirector: ArtDirectorService,
    public dialogRef: MatDialogRef<SceneBankComponent>,
    private projectApiService: ProjectAuthApiService,
    private projectStoreService: ProjectStoreService,
    private sceneConvertorService: SceneConverterService,
    @Inject(MAT_DIALOG_DATA) public data: IAddSceneDialogData
  ) {
    // constructor(private artDirector: ArtDirectorService) {

    this.personalizedDynamics =
      this.artDirector.extractDynamicLottieByUserBranding();
    this.design = data?.design;
  }

  ngOnInit(): void {
    this.sceneCompositions = this.design.compositions.map((composition) =>
      this.processComposition(composition)
    );
  }

  chooseComposition(composition: IComposition): void {
    this.chosenComposition = composition;
  }

  addNewScene() {
    this.isLoadingNewScene = true;
    const projectId = this.data.projectId;
    const sceneId = this.data.relativeSceneId;
    const params = {
      composition: this.chosenComposition,
      relativePositin: this.data.relativePosition,
    };

    this.projectApiService.addScene$(projectId, sceneId, params).subscribe({
      next: async (res) => {
        if (!res) {
          console.log('Scene not added');
          return;
        }

        const scene: IScene = await this.sceneConvertorService.inToLocalAsync(
          res.scene,
          this.design.basePath,
          false,
          projectId,
          null
        );
        this.projectStoreService.replaceOrAddProjectScenes(
          projectId,
          scene,
          res.index
        );

        this.closeDialog();
        this.isLoadingNewScene = false;
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  // Function to process composition
  processComposition(
    composition: IComposition
  ): ICompositionAndStagePosition | undefined {
    const lottieAsset = this.getLottieAsset(composition);
    if (!lottieAsset || !lottieAsset.dynamics) return;

    const dynamicStagePositions = this.getDynamicStagePositions(lottieAsset);
    const stagePositions: IStagePosition[] = [];
    const compositionAndLayout: ICompositionAndStagePosition = {
      composition,
      stagePositions,
    };

    if (!dynamicStagePositions || dynamicStagePositions.length === 0) {
      // Returning the composition with an empty array of stage positions
      return compositionAndLayout;
    }

    // Finding all the stage positions for this lottie
    stagePositions.push(
      ...this.getStagePositions(dynamicStagePositions, lottieAsset)
    );

    return compositionAndLayout;
  }

  getLottieAsset(composition: IComposition): DynamicAsset | undefined {
    return this.artDirector.loadedAssets.get(composition.layouts[0]._id);
  }

  getDynamicStagePositions(lottieAsset: DynamicAsset): IDynamicIdentifier[] {
    return lottieAsset.dynamics.filter(
      (dynamic) => dynamic.type === 'stage_position'
    );
  }

  getStagePositions(
    dynamicLotties: IDynamicLottieChange[],
    lottieAsset: DynamicAsset
  ): IStagePosition[] {
    return dynamicLotties.map((dynamicLottie) =>
      DynamicLottie.getStagePositions(
        dynamicLottie,
        lottieAsset.height,
        lottieAsset.width
      )
    );
  }
}
