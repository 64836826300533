import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Button_USE_CASE,
  IPathAndDash,
  MAT_ICON,
} from '../../../../models/defines';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EditJobStatusEnum } from 'src/app/models/job/edit-job-schema';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { BehaviorSubject, finalize, Subject, takeUntil } from 'rxjs';
import {
  IButtonAndLinks,
  ILinkConfigs,
  LinkDisplay,
} from 'src/app/components/minor/button-to-display-links/button-to-display-links.component';
import { ProjectAuthApiService } from '../../../../services/api/auth/project-auth-api.service';
import { IProjectInDTO } from '../../../../models/project-model';
import { IEditInDTO } from 'src/app/models/project/edit/edit-model';
import { UserService } from 'src/app/services/api/auth/user.service';

interface IEditWithDecodedUrls extends IEditInDTO {
  exportedVideos: IPathAndDash;
}

interface IEditAndButtonConfigs {
  export: IEditWithDecodedUrls;
  buttonConfigs$: BehaviorSubject<IButtonAndLinks[]>;
}

@Component({
  selector: 'app-exports',
  templateUrl: './exports.component.html',
  styleUrls: ['./exports.component.scss'],
})
export class ExportsComponent implements OnInit, OnDestroy {
  loadingData = true;
  EditJobStatusEnum = EditJobStatusEnum;
  previousExports$ = new BehaviorSubject<IEditAndButtonConfigs[]>([]);
  recentExport$ = new BehaviorSubject<IEditAndButtonConfigs>({
    buttonConfigs$: null,
    export: null,
  });
  vgApis: { [key: string]: VgApiService } = {};
  streamId: string;
  baseCdnUrl: string;
  recentExportShareDownloadConfigs$ = new BehaviorSubject<IButtonAndLinks[]>(
    null
  );
  previousExportShareDownloadConfigs$ = new BehaviorSubject<IButtonAndLinks[]>(
    null
  );
  autoRefreshIntervalId: NodeJS.Timeout;
  autoRefreshIntervalTime: number = 1000 * 60;
  enableAutoRefreshData$ = new BehaviorSubject<boolean>(true);

  editJobs: IEditInDTO[] = [];

  onDestroy$ = new Subject<void>();
  project: IProjectInDTO;

  isIOS: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private config: ConfigurationService,
    private projectsApi: ProjectAuthApiService,
    private userService: UserService
  ) {
    this.baseCdnUrl = this.config.baseCdnUrl;
    this.isIOS = this.userService.isIOS;
  }

  ngOnDestroy(): void {
    if (this.autoRefreshIntervalId) {
      clearInterval(this.autoRefreshIntervalId);
    }
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.streamId = params['id'];
      if (!this.streamId) {
        console.warn(`No stream id!`);
        return;
      }
      this.getExportsData(this.streamId);
    });

    this.enableAutoRefreshData$.subscribe((toEnable) => {
      if (toEnable) {
        this.autoRefreshIntervalId = setInterval(() => {
          this.getExportsData(this.streamId);
        }, this.autoRefreshIntervalTime);
      } else {
        if (this.autoRefreshIntervalId) {
          clearInterval(this.autoRefreshIntervalId);
        }
      }
    });
  }

  async getExportsData(projectId: string) {
    try {
      this.loadingData = true;
      this.projectsApi
        .getProjectById$(projectId)
        .pipe(
          finalize(() => {
            this.loadingData = false;
          }),
          takeUntil(this.onDestroy$)
        )
        .subscribe((project: IProjectInDTO) => {
          if (!project) return;

          this.project = project;

          if (!project.edits) return;

          const isEqual = this.isEqual(project.edits, this.editJobs);
          if (isEqual) {
            return;
          }
          this.editJobs = project.edits;

          let sortedJobs = project.edits
            .filter(
              (editJob) =>
                (editJob.exports?.originalExported.uploadPath ||
                  editJob.exports.originalExported.dashPath) &&
                editJob.status !== EditJobStatusEnum.DUMMY
            )
            .sort((a, b) => {
              const timeA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
              const timeB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
              return timeB - timeA;
            });

          // Map sortedJobs to new array with updated properties
          const updatedJobs: IEditWithDecodedUrls[] = sortedJobs.map((job) => {
            const editWithDecodeUrls: IEditWithDecodedUrls = {
              id: job.id,
              aspectRatio: job.aspectRatio,
              createdAt: job.createdAt,
              exportQuality: job.exportQuality,
              plugins: job.plugins,
              exports: job.exports,
              status: job.status,
              toMaxine: job.toMaxine,
              videoEditTakes: job.videoEditTakes,
              exportedVideos: {
                dashPath: job.exports?.originalExported?.dashPath
                  ? decodeURIComponent(job.exports.originalExported.dashPath)
                  : job.exports?.originalExported?.dashPath,
                uploadPath: job.exports?.originalExported?.uploadPath
                  ? decodeURIComponent(job.exports.originalExported.uploadPath)
                  : job.exports?.originalExported?.uploadPath,
              },
            };
            return editWithDecodeUrls;
          });
          console.log(sortedJobs);
          const [firstItem, ...restItems] = updatedJobs;

          const buttonConfigs$ = new BehaviorSubject<IButtonAndLinks[]>(
            this.getDownloadShareConfigs(firstItem)
          );

          const newRecentExport: IEditAndButtonConfigs = {
            export: firstItem,
            buttonConfigs$: buttonConfigs$,
          };
          this.recentExport$.next(newRecentExport);

          const previousExports = restItems.map((editJob) => ({
            export: editJob,
            buttonConfigs$: new BehaviorSubject<IButtonAndLinks[]>(
              this.getDownloadShareConfigs(editJob)
            ),
          }));

          this.previousExports$.next(previousExports);
        });
    } catch (error) {
      console.error(`Error fetching data!`, error);
    }
  }

  goToDashboard() {
    // this.location.back();
    this.router.navigate(['dashboard']);
  }

  onPlayerReady(api: VgApiService, editJob: IEditInDTO) {
    api.subscriptions.playing.subscribe(() => {
      console.log('PLAYING', editJob.id);
      Object.keys(this.vgApis).forEach((key: string) => {
        if (key !== editJob.id) {
          console.log('PAUSING', key);
          this.vgApis[key].pause();
        }
      });
    });
    this.vgApis[editJob.id] = api;
  }

  gotoEdit() {
    this.router.navigate([
      'dashboard',
      { outlets: { panel: ['edit', this.streamId] } },
    ]);
  }

  autoRefreshClicked() {
    this.enableAutoRefreshData$.next(!this.enableAutoRefreshData$.value);
  }

  isEqual(arr1: IEditInDTO[], arr2: IEditInDTO[]): boolean {
    if (!arr1 || !arr2) return false;

    if (arr1.length !== arr2.length) return false;

    return JSON.stringify(arr1) == JSON.stringify(arr2);
  }

  private createLinkConfigs(
    regularUrl: string,
    cleanedUrl: string,
    sentenceRegular: string,
    sentenceCleaned: string,
    matIconRegular: MAT_ICON,
    matIconCleaned: MAT_ICON
  ): ILinkConfigs {
    const regularLinkSentence: LinkDisplay = {
      relativeUrl: regularUrl,
      sentence: sentenceRegular,
      matIcon: matIconRegular,
    };

    const cleanedLinkSentence: LinkDisplay = {
      relativeUrl: cleanedUrl,
      sentence: sentenceCleaned,
      matIcon: matIconCleaned,
    };

    return {
      linkData: [regularLinkSentence, cleanedLinkSentence],
      disable: !regularUrl && !cleanedLinkSentence,
    };
  }

  private createButtonAndLinks(
    linkConfigs: ILinkConfigs,
    matIcon: MAT_ICON,
    buttonUseCase: Button_USE_CASE
  ): IButtonAndLinks {
    return {
      config: linkConfigs,
      matIcon,
      buttonUseCase,
    };
  }

  private getDownloadShareConfigs(editJob: IEditInDTO): IButtonAndLinks[] {
    if (!editJob) {
      return;
    }
    if (!editJob.exports) {
      return;
    }
    const downloadExportLinkConfigs = this.createLinkConfigs(
      editJob.exports.originalExported?.uploadPath,
      editJob.exports.exportedWithAudioClean?.uploadPath,
      'Download',
      'Cleaned Audio Version',
      MAT_ICON.DOWNLOAD,
      MAT_ICON.GRAPHIC_EQ
    );

    const shareExportLinkConfigs = this.createLinkConfigs(
      editJob.exports.originalExported?.uploadPath,
      editJob.exports.exportedWithAudioClean?.uploadPath,
      'Copy Regular Version URL',
      'Copy Cleaned Audio Version URL',
      MAT_ICON.DOWNLOAD,
      MAT_ICON.GRAPHIC_EQ
    );

    const regularExportButtonAndLinks = this.createButtonAndLinks(
      downloadExportLinkConfigs,
      MAT_ICON.DOWNLOAD,
      Button_USE_CASE.DOWNLOAD
    );

    const shareExportButtonAndLinks = this.createButtonAndLinks(
      shareExportLinkConfigs,
      MAT_ICON.COPY,
      Button_USE_CASE.COPY
    );

    return [shareExportButtonAndLinks, regularExportButtonAndLinks];
  }
}
