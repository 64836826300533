import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ExportQualityEnum,
  IFakeDetection,
  ProjectStatusEnum,
  TranscriptStatusEnum,
} from 'src/app/models/defines';
import {
  EditAudioScene,
  EditJobStatusEnum,
  IEditAudioScene,
  IEditJob,
  VideoEditTake,
} from 'src/app/models/job/edit-job-schema';
import { PostProductionAuthApiService } from 'src/app/services/api/auth/post-production-auth-api.service';
import {
  ChangeVideoListType,
  EditRoomUtilityFunctions,
  IChangeVideos,
  ISeekFromVideo,
} from './services/edit-room-utility-functions';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import {
  IComposeAndPlayOrNot,
  IComposedEditTakeConfigs,
  IExtendedComposedConfigs,
  ISubtitleSettings,
} from 'src/app/models/job/editor-defines';
import { v4 as uuidv4 } from 'uuid';
import { ScenesStylesService } from 'src/app/services/styles/scenes-styles.service';
import { ConfirmComponent } from 'src/app/components/dialogs/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ISelectedSceneAndMarkerTime } from './tracks/tracks.component';
import { EditRoomTakeList } from './tabs/takes-list/edit-room-take-list.component';
import { TaskTypeEnum } from 'src/app/models/walkthrough';
import { IProject } from 'src/app/models/project-model';
import { ProjectStoreService } from 'src/app/services/state-management/project/project-store.service';
import { STUDIO_PRODUCTION_ID_PARAM } from '../../../host/services/shared/constants/client/project/studio.routes';
import { EditApiService } from 'src/app/services/api/auth/projects/edit-api.service';
import { EditManagerService } from 'src/app/services/show/project/edit-manager.service';
import {
  AspectRatioEnum,
  IAudioEditTake,
  ICommonLocalEditTake,
  IExportEditJob,
  IVideoEditTake,
} from 'src/app/models/project/edit/edit-model';
import {
  SnackbarActionEnum,
  SnackBarService,
} from 'src/app/services/utils/snack-bar.service';
import { EditConvertorService } from 'src/app/services/project/convertors/edit/edit-convertor.service';
import { ISceneAndEditTakes } from '../../../../../models/defines';
import {
  IComposedConfigsTracker,
  IEditState,
  PlayType,
} from './edit-room-types';
import { FunctionsHelperService } from 'src/app/services/functions-helper.service';
import { FinishRecordingDialogComponent } from 'src/app/components/dialogs/finish-record-demo-dialog/finish-recording-dialog.component';
import { ProjectAuthApiService } from 'src/app/services/api/auth/project-auth-api.service';
import { IBasicTake } from '../../../../../models/project/take/take-model';
import { EditRoomStateService } from './edit-room-state.service';
import { FontsStoreService } from '../../../../../services/state-management/configs/fonts-store.service';
import { IFont } from '../../../../../models/configs/fonts.model';
import { ProjectConverterService } from 'src/app/services/project/convertors/project-converter.service';
import { RecordingManagerService } from '../../../../../services/studio/recording-manager.service';
const TIME_FOR_POLLING = 5000; // Polling interval in milliseconds

@Component({
  selector: 'app-new-edit-room',
  templateUrl: './edit-room.component.html',
  styleUrls: ['./edit-room.component.scss'],
})
export class EditRoomComponent implements OnInit, OnDestroy {
  /// To save in the local storage .
  steps: IEditState[] = [];
  currentStepIndex: number = null;
  toSaveStateInLocalStorage: boolean = false;
  loadingData = false;
  //**EXPORT PART */
  loadingText: 'Starting export...' | 'Navigating to exports screen' =
    'Starting export...';
  exporting = false;
  previousExports: number = 0;
  chosenExportType: ExportQualityEnum = ExportQualityEnum.P1080;
  exportTypes = [
    {
      type: ExportQualityEnum.P1080,
      text: 'High Quality (1080P)',
      subtext: 'Quick (3-5 minutes)',
    },
    {
      type: ExportQualityEnum.P720,
      text: 'Medium Quality (720P)',
      subtext: 'Quick (1-3 minutes)',
    },
  ];
  //**End of export Part */
  baseWidth = 50;
  //** Init */
  streamId: string;
  localStorageKey: string;
  baseStorageKey: string = 'edit-room';
  project: IProject;
  project$ = new BehaviorSubject<IProject>(null);
  projectId: string;
  editJob: IEditJob;
  newEditJob: IExportEditJob;
  scenesAndVideos$ = new BehaviorSubject<ISceneAndEditTakes[]>(null);

  videosOnTrack: IVideoEditTake[] = [];
  videosOnTrack$ = new BehaviorSubject<IVideoEditTake[]>([]);
  audiosOnTrack: IAudioEditTake[] = [];
  audiosOntrack$ = new BehaviorSubject<IAudioEditTake[]>([]);
  newFrameOnRunningScene$ = new BehaviorSubject<number>(0);
  endTime$ = new BehaviorSubject<number>(null);
  arrayOfComposeConfigs$ = new BehaviorSubject<IComposeAndPlayOrNot[]>([]);
  arrayOfComposeConfigs: IComposeAndPlayOrNot[] = [];
  specificSceneComposeConfigs: IComposeAndPlayOrNot = {
    playOrNot: false,
    composeConfigs: null,
  };
  specificSceneComposeConfigs$ = new BehaviorSubject<IComposeAndPlayOrNot>(
    null
  );
  numberOfConfigsToLoad: number = 2;
  configsArrayTracker: IComposedEditTakeConfigs[] = [];
  composedConfigsTracker = new Map<string, IComposedConfigsTracker>();
  intervalNotifierTime = 1000;
  // timeline panel
  dragVideosTo$ = new BehaviorSubject<string>(null);
  currentlyPlayingTimeline$ = new BehaviorSubject<boolean>(false);
  runMarker$ = new BehaviorSubject<boolean>(false);
  /// Using non-behavior subject to search data in a better way (instead of using .value all the time to subject)
  runningEditTakes: IVideoEditTake[];
  /// Using also subject because when changing properties in existed playing scene, it won't trigger changes in child components(inputs)
  runningEditTakes$ = new BehaviorSubject<IVideoEditTake[]>([]);
  currentTimeRunningMillis: number = 0;
  currentTimeFromPlayer: number = 0;
  // also for scene list
  isPlaying: boolean = false;
  currentlyPlayType: PlayType = null;
  PlayType = PlayType;
  /// for lottie video composed
  playOrPause$ = new BehaviorSubject<IFakeDetection>({
    randomNumber: Math.random(),
    isPlaying: false,
  });
  /// to bind data that needs more implementation we bind with input output
  @ViewChild('sceneList', { static: false }) sceneList: EditRoomTakeList;
  ///TODO: implement audio
  runningAudioScenes: IEditAudioScene[];

  /// using view child to change the selected scene css (small html changes),
  runningAudioScenes$ = new BehaviorSubject<IEditAudioScene[]>([]);
  sceneChanged$ = new BehaviorSubject<IVideoEditTake>(null);
  taskType: TaskTypeEnum = TaskTypeEnum.EDITOR;
  fonts: IFont[] = [];
  baseCdnUrl: string;
  loadingSubtitlesData$ = new BehaviorSubject<boolean>(false);

  private pollingInterval: any;

  private onDestroy$: Subject<boolean> = new Subject();
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private postProductionApi: PostProductionAuthApiService,
    private stylesService: ScenesStylesService,
    private projectStoreService: ProjectStoreService,
    private editApiService: EditApiService,
    private editMangaerService: EditManagerService,
    private snackBarService: SnackBarService,
    private editConvertorService: EditConvertorService,
    private config: ConfigurationService,
    private functionsHelper: FunctionsHelperService,
    private projectApiService: ProjectAuthApiService,
    private editRoomStateManager: EditRoomStateService,
    private fontsStoreService: FontsStoreService,
    private changeDetectRef: ChangeDetectorRef,
    private projectConverter: ProjectConverterService,
    private recordingManagerService: RecordingManagerService
  ) {
    this.baseCdnUrl = this.config.baseCdnUrl;
    this.editRoomStateManager.subtitleChanged$.subscribe((aa) => {
      console.log(`IDDDDD 2`, this.newEditJob);
    });
  }

  @HostListener('document:keydown.control.z', ['$event'])
  onCtrlZPressed(): void {
    if (
      this.currentStepIndex <= 0 ||
      this.currentStepIndex > this.steps.length - 1
    ) {
      console.warn(
        `Could not CTRL + Z because it may be the first step already ${this.currentStepIndex}`
      );
      return;
    }
    this.toSaveStateInLocalStorage = false;
    this.activeEditState(this.steps[this.currentStepIndex - 1]);
    this.currentStepIndex--;
    this.toSaveStateInLocalStorage = true;
  }

  @HostListener('document:keydown.control.y', ['$event'])
  onCtrlYPressed(): void {
    /// validation that the current step we are in is not bigger or equal to the steps array
    if (this.currentStepIndex > this.steps.length - 2) {
      return;
    }
    this.toSaveStateInLocalStorage = false;
    this.activeEditState(this.steps[this.currentStepIndex + 1]);
    this.currentStepIndex++;
    this.toSaveStateInLocalStorage = true;
  }

  ngOnInit(): void {
    // mockAudioList.forEach((audio) => {
    //   this.addAudioSceneAsync(audio);
    // });

    this.projectId = this.route.snapshot.params[STUDIO_PRODUCTION_ID_PARAM];

    // this.project =
    //   await this.projectStoreService.setProjectSourceIfNotExistedAsync(
    //     this.projectId,
    //     null
    //   );

    if (this.projectId) {
      this.initSubtitlesAsync(this.projectId);
    }
    this.initEditJobAsync();

    // this.editRoomStateManager._subtitleStyleChanged$.subscribe(
    //   (newSubtitlesStyle) => {
    //     if (!newSubtitlesStyle) return;
    //
    //     for (const setting of this.newEditJob.plugins.subtitles.settings) {
    //       setting.style = newSubtitlesStyle;
    //     }
    //   }
    // );
    this.specificSceneComposeConfigs$.subscribe((specificSceneConfigs) => {
      if (!specificSceneConfigs) {
        const currentPlayingScene = this.sceneChanged$.value;
        if (currentPlayingScene) {
          currentPlayingScene.isPlaying = false;
          this.scenesAndVideos$.next(this.scenesAndVideos$.value);
        }
        this.sceneChanged$.next(null);
        return;
      }
      this.isPlaying = true;
      this.specificSceneComposeConfigs = specificSceneConfigs;
    });

    this.currentlyPlayingTimeline$.subscribe((playingTimeline) => {
      if (playingTimeline) {
        /// If a specific scene have been played before now the specific scene player will be null
        if (this.specificSceneComposeConfigs$.value) {
          this.specificSceneComposeConfigs$.next(null);
        }
      } else {
        this.runMarker$.next(false);
      }
    });

    this.arrayOfComposeConfigs$.subscribe((arrayOfComposeConfigs) => {
      this.arrayOfComposeConfigs = arrayOfComposeConfigs;
    });

    this.playOrPause$.subscribe((playOrPause) => {
      if (!playOrPause) {
        return;
      }
      this.runMarker$.next(playOrPause.isPlaying);
    });
    this.videosOnTrack$.subscribe((videosOnTrack) => {
      this.videosOnTrack = videosOnTrack;
      if (this.toSaveStateInLocalStorage) {
        this.saveEditStepAsync();
      }
    });

    this.audiosOntrack$.subscribe((audiosOnTrack) => {
      this.audiosOnTrack = audiosOnTrack;
      if (this.toSaveStateInLocalStorage) {
        this.saveEditStepAsync();
      }
    });

    this.runningEditTakes$.subscribe((playingEditTakes) => {
      this.runningEditTakes = playingEditTakes;
    });

    this.streamId = this.route.snapshot.paramMap.get('id');
    this.localStorageKey = `${this.baseStorageKey}${this.streamId}`;
    EditRoomUtilityFunctions.deleteOtherLocalStorageKeys(
      this.streamId,
      this.localStorageKey,
      this.baseStorageKey
    );
  }

  playSpecificScene(scene: IVideoEditTake) {
    if (!scene) {
      console.warn(`Could not play/pause a null scene`);
      return;
    }
    /// Checking whether the user clicked on pausing/playing on the same scene that is on the screen .
    const isSpecificSceneIsRunning = this.runningEditTakes$.value.find(
      (runningScenes) => runningScenes.id === scene.id
    );

    /// Not playing a specific scene at all or playing a different specific scene and wants to watch another one
    if (
      this.currentlyPlayType !== PlayType.SPECIFIC_SCENE ||
      !isSpecificSceneIsRunning
    ) {
      const previousScene = this.sceneChanged$.value;
      if (previousScene) {
        previousScene.isPlaying = false;
        /// To notify that the scene is not playing any more at all
        this.sceneChanged$.next(previousScene);
      }
      this.currentlyPlayType = PlayType.SPECIFIC_SCENE;
      this.composedConfigsTracker.clear();
      /// To reset player .
      this.currentlyPlayingTimeline$.next(false);
      this.isPlaying = false;
      scene.isPlaying = true;
      const videoFrom: ISeekFromVideo = {
        scene: scene,
        seekTo: 0,
      };
      this.arrayOfComposeConfigs$.next(null);
      this.specificSceneComposeConfigs$.next({
        composeConfigs: this.createSceneConfigsAndPlayIfCan([videoFrom]),
        playOrNot: true,
      });
      this.playOrPause$.next({ randomNumber: Math.random(), isPlaying: true });
    }

    /// Pausing a running specific scene
    else if (this.isPlaying) {
      /// pause current running specific scene
      scene.isPlaying = false;
      this.isPlaying = false;
      this.playOrPause$.next({ randomNumber: Math.random(), isPlaying: false });
    } else {
      /// Play the paused specific scene
      scene.isPlaying = true;
      this.isPlaying = true;
      this.playOrPause$.next({ randomNumber: Math.random(), isPlaying: true });
    }
    this.scenesAndVideos$.next(this.scenesAndVideos$.value);
    this.sceneChanged$.next(scene);
  }

  //* Video Lottie composed *//
  finishedPlayingScene(finishedComposedSceneId: string) {
    this.runMarker$.next(false);
    const nextComposedSceneAndSkipCounter = this.getNextComposedConfigs(
      finishedComposedSceneId
    );
    if (!nextComposedSceneAndSkipCounter) {
      this.runningEditTakes$.next([]);
      this.isPlaying = false;
      this.timelinePlayOrPause(false);

      console.warn(`Finished playing stream .`);
      return;
    }
    const configsToSkip = nextComposedSceneAndSkipCounter.nextConfigsCounter;
    const nextConfigToCreate = this.getNextConfigToCreate(
      finishedComposedSceneId,
      configsToSkip
    );
    if (nextConfigToCreate) {
      const isExistedAlready = this.arrayOfComposeConfigs.some(
        (existedConfigs) =>
          existedConfigs.composeConfigs.id ===
          nextConfigToCreate[1].composedConfigs.id
      );
      if (!isExistedAlready) {
        const sceneAndSeek: ISeekFromVideo = {
          scene: nextConfigToCreate[1].videoEditTakes[0],
          seekTo: 0,
        };
        this.arrayOfComposeConfigs.push({
          composeConfigs: this.createSceneConfigsAndPlayIfCan([sceneAndSeek]),
          playOrNot: false,
        });
      }
    }
    nextComposedSceneAndSkipCounter.nextCompose.playOrNot = true;
    this.arrayOfComposeConfigs$.next(this.arrayOfComposeConfigs);
    this.composedConfigsTracker.delete(finishedComposedSceneId);
  }

  startedPlayingScene(startedComposedSceneId: string) {
    const configs = this.composedConfigsTracker.get(startedComposedSceneId);
    if (!configs) {
      console.warn(`Something went wrong, could not start configs .`);
      return;
    }
    this.runningEditTakes$.next(configs.videoEditTakes);
    this.runningAudioScenes$.next(configs.audioScenes);
    if (!this.isPlaying) {
      // this.arrayOfComposeConfigs[0].stopWhenLoaded = false;
      // this.isPlaying = false;

      this.timelinePlayOrPause(false);

      return;
    }
    //this.isPlaying = true;
    this.runMarker$.next(true);
  }

  newTimeFromPlayer(newTime: number) {
    const currentVideo = this.runningEditTakes[0];
    if (!currentVideo) {
      return;
    }
    const videoIndex = this.videosOnTrack.findIndex(
      (video) => currentVideo.id === video.id
    );
    if (videoIndex === -1) {
      console.error(`Could not update time from player.`);
      return;
    }
    this.currentTimeFromPlayer = newTime;

    let lastVideosDurations = 0;
    if (videoIndex > 0) {
      lastVideosDurations = this.getPreviousDurations(videoIndex);
    }

    this.currentTimeRunningMillis =
      lastVideosDurations + this.currentTimeFromPlayer - currentVideo.trimStart;
  }

  //** This function used for both zoom in and out and to change order of videos(drop new one or change order) */
  updateVideoList(videoListChanged: IChangeVideos) {
    const trimStart = 0,
      trimEnd = 0,
      style = {};
    const updatedVideos = videoListChanged.newEditVideos?.map((video) => {
      /// Meaning we need to new edit video because its a new dragged one
      if (!video.take) {
        const bareVideo = new VideoEditTake(
          video.take,
          style,
          video.sceneId,
          video.name,
          video.trims
        );
        return this.stylesService.adjustSceneWidth<IVideoEditTake>(
          bareVideo,
          this.baseWidth
        );
      }
      return video;
    });
    this.videosOnTrack$.next(updatedVideos);

    if (
      videoListChanged.changeType === ChangeVideoListType.ORDER &&
      this.runningEditTakes.length > 0 &&
      this.currentlyPlayType === PlayType.TIMELINE
    ) {
      const currentRunningVideo = this.runningEditTakes[0];
      const currentVideoOnTrackIndex = videoListChanged.newEditVideos.findIndex(
        (videoScene) => videoScene.id === currentRunningVideo.id
      );

      /// Removing the preivous configs because it might be changed because we added a scene to the list so we need to add it to the configs
      if (this.arrayOfComposeConfigs) {
        const currentRunningComposeId =
          this.arrayOfComposeConfigs[0]?.composeConfigs?.id;
        this.composedConfigsTracker.forEach((value, key) => {
          if (key !== currentRunningComposeId) {
            this.composedConfigsTracker.delete(key);
          }
        });
      }

      this.settingNewConfigsRenderAndMap(currentVideoOnTrackIndex);
    }

    /// adding 1 millisecond to trigger the marker position in the timeline panel to set
    /// as the new zoom
    this.newMousePoisitionAsync(this.currentTimeRunningMillis + 1);
  }

  updateTakeList(takeToChange: IVideoEditTake) {
    const currentVideos = this.videosOnTrack;
    // const newVideo = videoListChanged.newEditVideos[0];
    const videoToReplaceIndex = currentVideos.findIndex(
      (video) => video.sceneId === takeToChange.sceneId
    );

    if (videoToReplaceIndex !== -1) {
      // Update the specific video with the new video details
      const newVideoTake = new VideoEditTake(
        takeToChange.take,
        takeToChange.style,
        takeToChange.sceneId,
        takeToChange.name,
        takeToChange.trims
      );
      currentVideos[videoToReplaceIndex] = newVideoTake;
      this.videosOnTrack$.next([...currentVideos]);
    }
    /// adding 1 millisecond to trigger the marker position in the timeline panel to set
    /// as the new zoom
    this.newMousePoisitionAsync(this.currentTimeRunningMillis + 1);
  }

  cutScene(sceneAndMarkerTime: ISelectedSceneAndMarkerTime) {
    if (
      !sceneAndMarkerTime ||
      !sceneAndMarkerTime.selectedScene ||
      typeof sceneAndMarkerTime.markerTime !== 'number'
    ) {
      console.error(
        `Could not cut scene, one or more of the properties is null`
      );
      return;
    }

    const markerTime = sceneAndMarkerTime.markerTime;
    const cutVideoIndex = this.videosOnTrack.findIndex(
      (video) => video.id === sceneAndMarkerTime.selectedScene.id
    );

    /// Should never happen because the selected scene came from the video track
    if (cutVideoIndex === -1) {
      console.warn(`Could not find the selected scene on the video track.`);
      return;
    }
    this.timelinePlayOrPause(false);
    this.isPlaying = false;
    const previousDuration = this.getPreviousDurations(cutVideoIndex);
    const selectedScene = sceneAndMarkerTime.selectedScene;

    if (
      previousDuration >= markerTime ||
      markerTime >= previousDuration + selectedScene.durationOnTrack
    ) {
      console.warn(
        `Could not cut the video. either the marker on the first or last frame of the video or the marker is not on the selected scene`
      );
      return;
    }
    /// Cutting the selected scene to 2 different scenes
    /// Left side
    // const newEndTime =
    //   (markerTime - previousDuration + selectedScene.trimStart * 1000) / 1000;
    // const trimEnd = selectedScene.trimEnd;
    // selectedScene.trimEnd = selectedScene.duration - newEndTime;
    /// Right side
    if (EditRoomUtilityFunctions.isEditVideoScene(selectedScene)) {
      selectedScene.updateTrims();

      this.stylesService.adjustSceneWidth<IVideoEditTake>(
        selectedScene,
        this.baseWidth
      );

      /// Trimming very small part of the beginning so it will look a bit different
      // const newScene: IVideoEditTake = new VideoEditTake(
      //   selectedScene.take,
      //   {},
      //   selectedScene.scene,
      //   selectedScene.
      // );
      //
      // this.stylesService.adjustSceneWidth<IVideoEditTake>(
      //   newScene,
      //   this.baseWidth
      // );
      // this.videosOnTrack.splice(cutVideoIndex + 1, 0, newScene);
      /// Not saving state here becaues it is being saved in the new mouse position .
      this.toSaveStateInLocalStorage = false;
      this.videosOnTrack$.next(this.videosOnTrack);
    }

    this.isPlaying = false;
    this.timelinePlayOrPause(false);
    this.newMousePoisitionAsync(markerTime);
  }

  baseWidthChanged(newBaseWidth: number) {
    if (isNaN(newBaseWidth)) {
      console.error(`Could not apply new base width because he is null.`);
      return;
    }
    this.baseWidth = newBaseWidth;
  }

  //* Timeline panel *//

  updateEditTakeFields(editTake: IVideoEditTake) {
    if (!editTake) {
      console.warn('tried to update a null scene (probably form is wrong');
      return;
    }
    ///TODO: finish later audios
    if (EditRoomUtilityFunctions.isEditAudioScene(editTake)) {
      editTake.durationOnTrack =
        EditRoomUtilityFunctions.getAudioDurationOnTrack(editTake);
    } else if (EditRoomUtilityFunctions.isEditVideoScene(editTake)) {
      const currentEditedSceneIndex = this.videosOnTrack.findIndex(
        (scene) => scene.id === editTake.id
      );
      if (currentEditedSceneIndex === -1) {
        console.warn(
          `Could not edit scene video, id was not found : ${editTake.id}`
        );
        return;
      }
      const currentEditScene = this.videosOnTrack[currentEditedSceneIndex];
      /// Updating the duration on track
      editTake.updateTrims();

      Object.assign(currentEditScene, editTake);
      this.stylesService.adjustSceneWidth(currentEditScene, this.baseWidth);
      this.videosOnTrack$.next(this.videosOnTrack);

      if (this.runningEditTakes.length === 0) {
        return;
      }

      const currentRunningSceneIndex = this.videosOnTrack.findIndex(
        (video) => video.id === this.runningEditTakes[0]?.id
      );
      if (currentEditedSceneIndex === -1) {
        console.error(
          `Something went wrong in trimming, cold not find running scene in track`
        );
        return;
      }
      const indexDiff = currentEditedSceneIndex - currentRunningSceneIndex;

      for (const config of this.composedConfigsTracker.values()) {
        const configVideo = config.videoEditTakes.find(
          (video) => video.id === editTake.id
        );
        if (!configVideo) {
          continue;
        }

        config.composedConfigs.endTime = configVideo.trimStart;

        config.composedConfigs.startTime =
          configVideo.trimStart - (configVideo.trims.videoTrims.start ?? 0);

        if (indexDiff >= 0 && indexDiff <= this.numberOfConfigsToLoad) {
          /// Checking if the edited scene is one of the scenes that are already rendered.
          this.timelinePlayOrPause(false);
          this.isPlaying = false;
          const tempCopy = JSON.parse(
            JSON.stringify(this.arrayOfComposeConfigs$.value)
          );
          this.arrayOfComposeConfigs$.next(null);

          this.arrayOfComposeConfigs$.next(tempCopy);
          // this.newMousePoisitionAsync(this.currentTimeRunningMillis);
        }
      }
    }
  }

  dragVideoToInTimelinePanel(divId: string) {
    /// might use cdn detect changes here
    this.dragVideosTo$.next(divId);
  }

  deleteEditTake(deleteScene: ICommonLocalEditTake) {
    if (!deleteScene) {
      console.warn(`No delete scene was provided to remove`);
      return;
    }
    if (EditRoomUtilityFunctions.isEditAudioScene(deleteScene)) {
      if (this.runningAudioScenes?.length === 0) {
        console.warn(`No running audio scene to delete`);
        return;
      }
      const indexToDelete = this.audiosOnTrack.findIndex(
        (scene) => scene.id === deleteScene.id
      );
      if (indexToDelete !== -1) {
        this.audiosOnTrack?.splice(indexToDelete, 1);
      }

      const runningSceneIndex = this.runningAudioScenes?.findIndex(
        (scene) => scene.sceneId === deleteScene.id
      );
      if (runningSceneIndex !== -1) {
        this.runningAudioScenes?.splice(runningSceneIndex, 1);
        this.runningAudioScenes$.next(this.runningAudioScenes);
      }
    }
    /// Making sure that this is a valid scene
    if (EditRoomUtilityFunctions.isEditVideoScene(deleteScene)) {
      const indexToDelete = this.videosOnTrack.findIndex(
        (scene) => scene.id === deleteScene.id
      );
      /// Removing the preivous configs because it might be changed because we added a scene to the list so we need to add it to the configs
      if (this.arrayOfComposeConfigs && this.arrayOfComposeConfigs.length > 0) {
        const currentRunningComposeId =
          this.arrayOfComposeConfigs[0]?.composeConfigs.id;
        this.composedConfigsTracker.forEach((value, key) => {
          if (currentRunningComposeId && key !== currentRunningComposeId) {
            this.composedConfigsTracker.delete(key);
          }
        });
        this.settingNewConfigsRenderAndMap(indexToDelete);
      }
      // using a slice here because splice on the same array (videosOnTrack) is not triggering the new value for the input for timeline panel
      // once there is a slice, it gets a new ref and triggers the new value for the input .
      const tempVideos = this.videosOnTrack.slice();
      tempVideos.splice(indexToDelete, 1);
      this.videosOnTrack$.next(tempVideos);
      const runningSceneIndex = this.runningEditTakes.findIndex(
        (scene) => scene.id === deleteScene.id
      );
      if (runningSceneIndex !== -1) {
        this.runningEditTakes.splice(runningSceneIndex, 1);
        this.runningEditTakes$.next(this.runningEditTakes);
      }
      this.newMousePoisitionAsync(this.currentTimeRunningMillis, false);
      return;
    }
  }

  timelinePlayOrPause(forcedValue?: boolean) {
    // Deselect scenes on list
    console.log(`Timeline play/pause`, this.videosOnTrack$.value);
    this.sceneList?.playEditTake(null);
    //  this.sceneChanged$.next(null)

    let toPlayOrPause = forcedValue;
    if (typeof toPlayOrPause !== 'boolean') {
      toPlayOrPause = !this.currentlyPlayingTimeline$.value;
    }
    this.currentlyPlayingTimeline$.next(toPlayOrPause);
    this.isPlaying = toPlayOrPause;
    /// playing timeline if wasn't before
    if (toPlayOrPause) {
      /// Meaning to play the whole track from 0
      if (this.currentlyPlayType !== PlayType.TIMELINE) {
        this.resetAndPlayFromBeginning();
      }
      /// Playing from a specific frame
      else {
        this.playOrPause$.next({
          randomNumber: Math.random(),
          isPlaying: true,
        });
      }
    }
    ///Pausing
    else {
      this.playOrPause$.next({ randomNumber: Math.random(), isPlaying: false });
    }
  }

  /**
   *
   * @param mouseposition in milliseconds
   * @param saveState saving on mouse clicks and re position, not when doing undo .
   * @returns
   */
  async newMousePoisitionAsync(
    mouseposition: number,
    saveState: boolean = true
  ) {
    /// relevant scenes meaning from all tracks to this specific time.
    const relevantVideoEditTakes =
      await EditRoomUtilityFunctions.findRelevantScenesToTargetTimeAsync(
        mouseposition,
        this.videosOnTrack,
        this.audiosOnTrack
      );
    if (!relevantVideoEditTakes) {
      console.warn(
        `Could not play scene by frame because relevant scenes is null.`
      );
      /// TODO: maybe display an error?
      return;
    }

    console.log(
      `new relevant scenes! ${relevantVideoEditTakes}, video scenes: ${relevantVideoEditTakes.videoEditTakes}, audio scenes: ${relevantVideoEditTakes.audioScenes}`
    );
    const runningVideos = this.runningEditTakes;
    this.currentlyPlayType = PlayType.TIMELINE;
    this.currentlyPlayingTimeline$.next(true);

    this.currentTimeRunningMillis = mouseposition;
    if (saveState) {
      /// saving current state .
      this.saveEditStepAsync();
    }
    /// It means the user clicked on a frame that is already running so we don't need to re-render the scene.
    if (
      runningVideos &&
      relevantVideoEditTakes.videoEditTakes.every((newVideo) =>
        runningVideos.some(
          (runningVideo) => runningVideo.id === newVideo.scene.id
        )
      )
    ) {
      /// SEEK to mouseposition
      this.newFrameOnRunningScene$.next(
        relevantVideoEditTakes.videoEditTakes[0]?.seekTo / 1000
      );
      //  this.isPlaying = false;
      this.timelinePlayOrPause(false);
      return;
    } else {
      const videoIndex = this.videosOnTrack.findIndex(
        (sceneOnTrack) =>
          sceneOnTrack.id === relevantVideoEditTakes.videoEditTakes[0].scene.id
      );

      if (videoIndex === -1) {
        /// Should never happen .
        console.error(`Something went wrong in mouse clicked`);
        return;
      }
      this.arrayOfComposeConfigs$.next([]);
      this.composedConfigsTracker.clear();
      this.isPlaying = false;

      this.arrayOfComposeConfigs.push({
        composeConfigs: this.createSceneConfigsAndPlayIfCan(
          relevantVideoEditTakes.videoEditTakes
        ),
        playOrNot: true,
      });

      this.arrayOfComposeConfigs$.next(this.arrayOfComposeConfigs);
      //this.settingNewConfigsRenderAndMap(videoIndex);

      const scenesToPlayAfter = this.videosOnTrack.slice(videoIndex + 1);
      const scenesToConfig = scenesToPlayAfter.slice(
        0,
        this.numberOfConfigsToLoad
      );

      const restOfScenesToSetInMap = scenesToPlayAfter.slice(
        this.numberOfConfigsToLoad
      );

      scenesToConfig.forEach((sceneConfig) => {
        const sceneAndSeek: ISeekFromVideo = {
          scene: sceneConfig,
          seekTo: 0,
        };
        this.arrayOfComposeConfigs.push({
          composeConfigs: this.createSceneConfigsAndPlayIfCan([sceneAndSeek]),
          playOrNot: false,
        });
      });
      restOfScenesToSetInMap.forEach((sceneToPlay) => {
        const sceneAndSeek: ISeekFromVideo = {
          scene: sceneToPlay,
          seekTo: 0,
        };
        this.createSceneConfigsAndPlayIfCan([sceneAndSeek]);
      });
      this.arrayOfComposeConfigs$.next(this.arrayOfComposeConfigs);
    }
  }

  //** Export */
  save() {
    this.dialog
      .open(ConfirmComponent, {
        minHeight: 'fit-content',
        data: {
          title: 'Save your edit job',
          message: `This will enable you to continue later,\r\n it will also overide any previously save edits jobs for this project`,
        },
      })
      .afterClosed()
      .subscribe(async (response) => {
        if (!response?.confirmed) {
          return;
        }
        try {
          await this.editMangaerService.saveEditStateAsync(
            this.project,
            this.newEditJob.id,
            this.videosOnTrack
          );

          this.snackBarService.openMessage(`Save succsefully ✌️`, null, 3000);
        } catch (error) {
          console.error(`Error ${error}`);
          this.snackBarService.openMessage(
            `An error occurred while saving. Please contact us`,
            SnackbarActionEnum.Close,
            5000
          );
        }
      });
  }

  async showExportDialogAsync() {
    // const exportJob = this.getExportJob();
    // const requestEdit = true;
    this.projectApiService.isLoadingExportData$.next(true);
    const dialogSubscription = this.dialog.open(
      FinishRecordingDialogComponent,
      {
        width: '550px',
        minHeight: '400px',
        maxHeight: '90vh',
        disableClose: true,
      }
    );

    dialogSubscription.afterClosed().subscribe((closedData) => {
      this.router.navigate(['dashboard', { outlets: { panel: ['library'] } }]);
    });
    try {
      await this.editMangaerService.exportEditAsync(
        this.project,
        this.videosOnTrack,
        this.newEditJob
      );
    } catch (error) {
      console.error(`Could not export.`);
      this.snackBarService.openMessage(
        `An error occurred. Please contact us`,
        SnackbarActionEnum.Close,
        5000
      );
    }

    // .open(ExportDialogComponent, {
    //   minHeight: 'fit-content',
    //   data: {
    //     title: "Let's start an export",
    //     message: `This process usually takes 5-10 minutes, you'll be notified when it's done.`,
    //     exportJob: exportJob,
    //     requestEdit: requestEdit,
    //   },
    // })
    // .afterClosed()
    // .subscribe(async (response) => {
    //   if (!response) return;
    //
    //   this.exporting = true;
    //   this.postProductionApi.postEditJob(exportJob, requestEdit).subscribe({
    //     next: () => {
    //       this.loadingText = 'Navigating to exports screen';
    //
    //       setTimeout(() => {
    //         this.gotoExports();
    //         this.exporting = false;
    //       }, 3000);
    //     },
    //     error: () => {
    //       this.exporting = false;
    //
    //       this.snackBar.open(
    //         '🫣 Looks like something happened, try again in a few moments!',
    //         'Got it!',
    //         {
    //           horizontalPosition: 'center',
    //           verticalPosition: 'bottom',
    //           duration: 7 * 1000,
    //         }
    //       );
    //
    //       setTimeout(() => {
    //         this.loadingText = 'Starting export...';
    //       }, 3000);
    //     },
    //     complete: () => {},
    //   });
    // });
  }

  getExportJob() {
    // this.videoScenesOnTrack.map((scene) => {
    //   this.cleanCdnUrl(scene.take);
    // });
    // this.audioScenesOnTrack.map((scene) => {
    //   scene.audioPath = scene.audioPath.replace(/https?:\/\/[^\/]+/i, '');
    // });

    const exportJob: IEditJob = {
      streamId: '', // editVideoScenes: this.videosOnTrack,
      // editAudioScenes: [],
      // streamId: this.editJob.streamId,
      // exportQuality: this.chosenExportType,
      // hostEmail: this.editJob.hostEmail,
      // id: this.editJob.id,
    };
    return exportJob;
  }

  gotoExports() {
    this.router.navigate([
      'dashboard',
      { outlets: { panel: ['exports', this.projectId] } },
    ]);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  //** END of timline */

  /**
   * Creates a bare audio scene and adding it into audios on track
   * @param audioScene Edit audio scene
   */
  private async addAudioSceneAsync(audioScene: IAudioEditTake) {
    // const bareAudioScene =

    // audioScene.fileName = 'audio1';
    // audioScene.audioPath =
    //   '/files/background-music/2050%20-%20Better%20Than%20This.mp3';

    try {
      console.log(`Adding audio scenes! ${audioScene}`);
      const audioDuration =
        await EditRoomUtilityFunctions.getAudioDurationAsync(
          this.baseCdnUrl + audioScene.audioPath
        );
      const bareAudioScene = new EditAudioScene(
        audioScene.audioPath,
        audioScene.fileName,
        audioDuration
      );
      this.audiosOnTrack.push(
        this.stylesService.adjustSceneWidth<IAudioEditTake>(
          bareAudioScene,
          this.baseWidth
        )
      );
    } catch (error) {
      console.error(
        `Could not add audio to audios track for audio ID ${audioScene.id}, error: ${error}`
      );
    }
  }

  /// Theoreticly many video scenes can be played even though right now it's only 1 .
  private createSceneConfigsAndPlayIfCan(videoScenes: ISeekFromVideo[]) {
    if (!videoScenes || videoScenes.length === 0) {
      return;
    }
    const currentVideoScene = videoScenes[0];
    ///TODO: change this? think about this ! when it will be relevant to multiple videos at different times

    const endTime = videoScenes.reduce((maxItem, currentItem) => {
      return currentItem.scene.take.endTime > maxItem.scene.take.endTime
        ? currentItem
        : maxItem;
    }, currentVideoScene)?.scene.take.endTime;

    let relativeStartTime = 0;
    const relativeEndTime =
      endTime -
      (currentVideoScene.scene.trimEnd ?? 0) -
      currentVideoScene.scene.take.startTime;

    if (
      !isNaN(currentVideoScene.seekTo) &&
      typeof currentVideoScene.seekTo === 'number'
    ) {
      if (currentVideoScene.seekTo > relativeStartTime) {
        relativeStartTime = currentVideoScene.seekTo + relativeStartTime;
      }
    }

    const lottieVideoConfigs =
      EditRoomUtilityFunctions.createVideoLottieComposeConfigs(
        videoScenes.map((item) => item.scene)
      );

    const subtitlesConfigs = this.getSubtitlesConfigs(
      currentVideoScene.scene.take
    );
    /// Setting new configs
    const takeConfigs: IExtendedComposedConfigs = {
      id: uuidv4(),
      startTime: relativeStartTime / 1000, //It's not actually 0 in the db but we treat it as 0 in the other components
      endTime: relativeEndTime / 1000,
      videoLottieConfigs: lottieVideoConfigs,
      subtitlesConfigs: subtitlesConfigs,
    };

    const videos = videoScenes.map((item) => item.scene);
    const composedConfigsTracker: IComposedConfigsTracker = {
      composedConfigs: takeConfigs,
      videoEditTakes: videos,
      audioScenes: null,
    };
    this.composedConfigsTracker.set(takeConfigs.id, composedConfigsTracker);

    return takeConfigs;
  }

  private getPreviousDurations(videoIndex: number) {
    const relevantScenes = this.videosOnTrack.slice(0, videoIndex);
    let lastVideosDurations = 0;
    lastVideosDurations = relevantScenes.reduce(
      (acc, video) => acc + video.durationOnTrack,
      0
    );
    return lastVideosDurations;
  }

  private settingNewConfigsRenderAndMap(videoOnTrackIndex: number) {
    if (typeof videoOnTrackIndex !== 'number') {
      console.error(
        `Could not set new configs and render because index is not a number.`
      );
      return;
    }
    this.arrayOfComposeConfigs.splice(
      0,
      this.arrayOfComposeConfigs.length > 1 ? 1 : 0
    );

    const scenesToPlayAfter = this.videosOnTrack.slice(videoOnTrackIndex + 1);
    const scenesToConfig = scenesToPlayAfter.slice(
      0,
      this.numberOfConfigsToLoad
    );

    const restOfScenesToSetInMap = scenesToPlayAfter.slice(
      this.numberOfConfigsToLoad
    );
    scenesToConfig.forEach((sceneConfig) => {
      const sceneAndSeek: ISeekFromVideo = {
        scene: sceneConfig,
        seekTo: 0,
      };
      this.arrayOfComposeConfigs.push({
        composeConfigs: this.createSceneConfigsAndPlayIfCan([sceneAndSeek]),
        playOrNot: false,
      });
    });
    restOfScenesToSetInMap.forEach((sceneToPlay) => {
      const sceneAndSeek: ISeekFromVideo = {
        scene: sceneToPlay,
        seekTo: 0,
      };
      this.createSceneConfigsAndPlayIfCan([sceneAndSeek]);
    });
    this.arrayOfComposeConfigs$.next(this.arrayOfComposeConfigs);
  }

  //**End of Export */

  private resetAndPlayFromBeginning() {
    this.currentlyPlayType = PlayType.TIMELINE;
    this.currentlyPlayingTimeline$.next(true);
    this.composedConfigsTracker.clear();
    this.isPlaying = false;
    const previousScenePlayed = this.sceneChanged$.value;
    if (previousScenePlayed) {
      previousScenePlayed.isPlaying = false;
      this.sceneChanged$.next(previousScenePlayed);
      this.sceneChanged$.next(null);
    }
    this.newMousePoisitionAsync(1);
    // for (const videoToPlay of this.videosOnTrack) {
    //   this.createSceneConfigsAndPlayIfCan([{ scene: videoToPlay, seekTo: 0 }]);
    // }
  }

  private async initEditJobAsync() {
    const editStateString = localStorage.getItem(this.localStorageKey);

    let editStates: IEditState[] = null;
    if (editStateString) {
      editStates = JSON.parse(editStateString);
    }
    this.project =
      await this.projectStoreService.setProjectSourceIfNotExistedAsync(
        this.projectId,
        false,
        null
      );
    this.project$.next(this.project);

    //update statuses.general of project to 'editing'
    try {
      this.projectApiService
        .updateProjectStatus$(
          this.project.id,
          this.project.statuses,
          ProjectStatusEnum.EDIT_ROOM
        )
        ?.subscribe((response) => {});
    } catch (error: any) {
      console.error(
        `An error occurred while trying to update project status. error: ${error.message}`
      );
      this.snackBarService.openMessage(
        'WOOPS, An Error Occurred',
        SnackbarActionEnum.Dismiss,
        10000
      );
      return;
    }
    ///Checking if we have edit jobs on the project -> if not, request from server to create one :)
    if (!this.project.edits || this.project.edits.length === 0) {
      const currentAspectRatio =
        this.project.designGroup.design.aspectRatio ?? AspectRatioEnum._16x9;

      const exportEdit = this.editMangaerService.buildEditJobOutDTO(
        this.project,
        currentAspectRatio
      );

      await new Promise<void>((resolve) => {
        this.editApiService
          .postEdit$(exportEdit, true)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((newEdit) => {
            if (!newEdit) {
              this.snackBarService.openMessage(
                `An error occurred`,
                SnackbarActionEnum.Close,
                5000
              );
              return;
            }
            const localEdit = this.editConvertorService.inToLocal(
              newEdit,
              this.project.scenes,
              this.project.designGroup.design.basePath,
              this.project.id
            );
            this.projectStoreService.replaceOrAddProjectEdits(
              this.projectId,
              localEdit
            );
            return resolve();
          });
      });
    }
    const edits = this.project.edits;
    const currentEditJob: IExportEditJob = edits[edits.length - 1];

    this.newEditJob = currentEditJob;
    this.editRoomStateManager.setCurrentEditJob(this.newEditJob);
    this.previousExports =
      edits.filter((job) => job?.status === EditJobStatusEnum.COMPLETED)
        .length ?? 0;
    /// Edit state -> our changes from local storage.
    try {
      this.fonts = await this.fontsStoreService.getFontsAsync();
    } catch (error) {
      this.fonts = [];
    }
    if (editStates && editStates.length > 0) {
      this.currentStepIndex = editStates.length - 1;
      const lastEditState = editStates[this.currentStepIndex];
      if (
        lastEditState.scenesAndVideos &&
        lastEditState.scenesAndVideos.length > 0
      ) {
        this.steps = editStates;
        this.activeEditState(lastEditState);
        this.toSaveStateInLocalStorage = true;
      }
      /// 100 mouse position to start the edit room on some frame of the video so it won't be black
      else {
        /// Assigning `true` to save in local storage only occurs after the process of editing a job, as within the process of editing a job.
        /// it assign new mouse position and saves the state.
        this.processEditJob(this.newEditJob.videoEditTakes, 100, false);
        this.toSaveStateInLocalStorage = true;
      }
    } else {
      this.processEditJob(this.newEditJob.videoEditTakes, 100, false);
      this.toSaveStateInLocalStorage = true;
    }
  }

  private getNextComposedConfigs(currentId: string):
    | {
        nextCompose: IComposeAndPlayOrNot;
        nextConfigsCounter: number;
      }
    | undefined {
    if (!currentId) {
      console.error(`no id was provided to get composed scene`);
      return undefined;
    }
    if (
      !this.arrayOfComposeConfigs ||
      this.arrayOfComposeConfigs.length === 0
    ) {
      console.warn(
        `Something went wrong getting next composed configs, the array is empty before splice .`
      );
      return undefined;
    }

    const currentIndex = this.arrayOfComposeConfigs.findIndex(
      (composedConfigs) => composedConfigs.composeConfigs.id === currentId
    );
    if (currentIndex === -1) {
      console.warn(`Current ID not found.`);
      return undefined;
    }
    const nextConfigsCounter =
      this.arrayOfComposeConfigs.slice(currentIndex).length;

    const nextComposeAndConfigCounter = {
      nextCompose: null,
      nextConfigsCounter: nextConfigsCounter,
    };

    if (currentIndex < this.arrayOfComposeConfigs.length - 1) {
      const nextConfigs = this.arrayOfComposeConfigs[currentIndex + 1];
      if (!nextConfigs) {
        return undefined;
      }
      nextComposeAndConfigCounter.nextCompose = nextConfigs;
    } else if (this.arrayOfComposeConfigs.length > 0) {
      // If the next object doesn't exist at the given index, check if the array is not empty
      // and get the first object from the start of the array
      nextComposeAndConfigCounter.nextCompose = this.arrayOfComposeConfigs[0];
    }

    this.arrayOfComposeConfigs.splice(currentIndex, 1);

    return nextComposeAndConfigCounter;
  }

  private activeEditState(editState: IEditState) {
    if (!editState) {
      console.log(this.currentStepIndex);
      console.log(this.steps);
      console.warn(`Could not active last edit state because it's null !`);
      return;
    }
    /// For now, not displaying any audios .
    // this.audiosOntrack$.next(editState.audios ?? []);

    /// so it will look like it's on the start line .
    const updateMousePosition =
      editState.currentRunningTime === 0
        ? editState.currentRunningTime + 10
        : editState.currentRunningTime;
    this.processEditJob(editState.videos ?? [], updateMousePosition, false);
    this.scenesAndVideos$.next(editState.scenesAndVideos ?? []);
  }

  private getNextConfigToCreate(
    currentId: string,
    configsToSkip: number
  ): [string, IComposedConfigsTracker] | undefined {
    if (!currentId || typeof configsToSkip !== 'number') {
      console.error(
        `no id was provided to get composed scene or no configs was required`
      );
      return undefined;
    }
    const iterator = this.composedConfigsTracker.entries();
    let nextItem = iterator.next();

    while (!nextItem.done) {
      const [id, value] = nextItem.value;

      if (id === currentId) {
        for (let i = 0; i < configsToSkip; i++) {
          // Found the current ID, now get the next few configs because they are already loaded
          nextItem = iterator.next();
        }
        return nextItem.value;
      }

      nextItem = iterator.next();
    }

    return undefined; // If the current ID is not found or it's the last item
  }

  private saveEditStepAsync() {
    return new Promise<void>((resolve) => {
      const editState: IEditState = {
        scenesAndVideos: this.scenesAndVideos$.value,
        videos: this.videosOnTrack,
        audios: this.audiosOnTrack,
        currentRunningTime: this.currentTimeRunningMillis,
      };
      this.functionsHelper.deepClone(editState);

      const updatedEditState: IEditState =
        this.functionsHelper.deepClone(editState);

      this.steps = this.steps.slice(0, this.currentStepIndex + 1);

      this.steps.push(updatedEditState);
      if (this.steps.length > 50) {
        this.steps.shift();
      }
      this.currentStepIndex = this.steps.length - 1;

      // localStorage.setItem(this.localStorageKey, JSON.stringify(this.steps));
      return resolve();
    });
  }

  private processEditJob(
    videoScenes: IVideoEditTake[],
    mousePosition: number,
    saveState: boolean
  ) {
    if (!videoScenes) {
      console.error(`Could not process edit job because it's null.`);
      return;
    }
    this.audiosOntrack$.next([]);

    this.videosOnTrack$.next(videoScenes);

    this.newMousePoisitionAsync(mousePosition, saveState);
  }

  private aspectRatioToResolution = (
    aspectRatio: AspectRatioEnum
  ): { xRes: number; yRes: number } => {
    switch (aspectRatio) {
      case '16/9':
        return { xRes: 1920, yRes: 1080 }; // Example resolution for 16:9 aspect ratio
      case '9/16':
        return { xRes: 1080, yRes: 1920 }; // Example resolution for 9:16 aspect ratio
      case '1/1':
        return { xRes: 1080, yRes: 1080 }; // Example resolution for 1:1 aspect ratio
      default:
        throw new Error('Unsupported aspect ratio');
    }
  };

  private getSubtitlesConfigs(take: IBasicTake) {
    const transcript = take.copy?.transcript;
    if (!transcript?.sentences?.length) {
      return null;
    }
    console.log(`IDDDDD`, take);
    const styles = this.newEditJob.plugins.subtitles.settings.map(
      (subtitle) => subtitle.style
    );
    const currentAspectRatio =
      this.project.designGroup.design.aspectRatio ?? AspectRatioEnum._16x9;
    const { xRes, yRes } = this.aspectRatioToResolution(currentAspectRatio);
    const fontsUrls: string[] = [];
    if (this.fonts && this.fonts.length > 0) {
      for (const style of styles) {
        const fontToSend = this.fonts.find(
          (fontObj) => fontObj.name === style.Fontname
        );
        if (!fontToSend.italicUrl || style.Italic === '0') {
          fontsUrls.push(this.baseCdnUrl + fontToSend.regularUrl);
          continue;
        }
        fontsUrls.push(this.baseCdnUrl + fontToSend.italicUrl);
      }
    }
    const subtitlesConfigs: ISubtitleSettings = {
      take: take,
      styles: styles,
      // strategyToPlay: this.newEditJob.plugins.subtitles,
      configs: this.newEditJob.plugins.subtitles,
      yRes: yRes,
      xRes: xRes,
      fonts: fontsUrls,
    };

    return subtitlesConfigs;
  }

  private async initSubtitlesAsync(projectId: string) {
    try {
      let project: IProject;
      try {
        project =
          await this.projectStoreService.setProjectSourceIfNotExistedAsync(
            projectId,
            false,
            null
          );
      } catch (error: any) {
        return;
      }

      if (project.statuses.transcript === TranscriptStatusEnum.NONE) {
        this.startPollingProjectSubtitlesStatus(project);
      } else if (project.statuses.transcript === TranscriptStatusEnum.DONE) {
        this.loadingSubtitlesData$.next(false);
      }
    } catch (e) {
      console.error('Error occurred while trying to get project:', e);
    } finally {
      this.loadingSubtitlesData$.next(false);
    }
  }

  private startPollingProjectSubtitlesStatus(project: IProject) {
    // Check if polling is already running
    if (this.pollingInterval) {
      console.warn('Polling is already running. Skipping new interval setup.');
      return;
    }
    this.pollingInterval = setInterval(() => {
      this.projectApiService.getProjectById$(this.project.id).subscribe({
        next: async (project) => {
          if (!project) return;

          let transcriptStatus = project.statuses.transcript;
          switch (transcriptStatus) {
            case TranscriptStatusEnum.GENERATING_SUBTITLES:
              this.loadingSubtitlesData$.next(true);
              break;

            case TranscriptStatusEnum.DONE:
              this.loadingSubtitlesData$.next(false);

              this.project =
                await this.projectStoreService.setCurrentProjectSourceAsync(
                  project.id,
                  false
                );

              this.projectStoreService.projectSource$.subscribe(
                async (project) => {
                  this.project = project;

                  if (!project) {
                    this.snackBarService.openMessage(
                      `An error occurred`,
                      SnackbarActionEnum.Close,
                      5000
                    );
                    return;
                  }
                  // const localProject =
                  //   await this.projectConverter.inToLocalAsync(
                  //     project,
                  //     true,
                  //     null
                  //   );

                  // localProject.scenes.forEach((scene) => {
                  //   scene.takes.forEach(async (take) => {
                  //     await this.recordingManagerService.replaceOrAddTakeAsync(
                  //       localProject,
                  //       scene,
                  //       take,
                  //       null,
                  //       this.project.designGroup.design.basePath,
                  //       false
                  //     );
                  //   });
                  // });
                }
              );
              this.initEditJobAsync();

              this.changeDetectRef.detectChanges();
              clearInterval(this.pollingInterval);
              this.pollingInterval = null;
              break;
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
    }, TIME_FOR_POLLING);
  }
}
