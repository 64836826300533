<!--  TODO: make selection on change work.(it didn't work in edit room...) -->
<!-- (selectionChange)="selectTake($event.options?.length > 0 ? $event.options[0].value : null) " -->
<div class="top-bar row-spacebetween">
  <div class="row-centered">
    <h4 class="title">Scenes</h4>
  </div>
</div>
<ng-container *ngIf="this.scenesAndVideoScenes">
  <ng-container
    class="takes-list"
    *ngFor="let editVideoTake of this.scenesAndVideoScenes">
    <mat-expansion-panel
      [hideToggle]="editVideoTake.videoScenes.length <= 1"
      [disabled]="editVideoTake.videoScenes.length <= 1"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      *ngIf="editVideoTake.scene.chosenTake.duration">
      <mat-expansion-panel-header>
        <mat-panel-title class="take-header-container">
          <div class="scene-name-container">
            <h5 mat-subheader class="scene-name">
              <b>{{ editVideoTake.scene.name }}</b>
            </h5>
            <!--  TODO: display the number of the take that selectd -->

            <!-- <div class="selected-take">
                      <span>Take {{ sceneVideo.scene.chosenTake.number + 1 }}</span>
                      <mat-icon>play_arrow</mat-icon>
                    </div> -->
            <!--  TODO: get here the takes list -->
          </div>

          <div class="take-row" (click)="$event.stopPropagation()">
            <button
              matTooltip="Preview before selecting"
              mat-mini-fab
              class="cool-button gray play-pause small-icon-button"
              color="secondary"
              (click)="
                playTakePreview(editVideoTake.scene.chosenTake.videoEditTake)
              ">
              <mat-icon>{{
                editVideoTake.scene.chosenTake.videoEditTake.isPlaying
                  ? 'pause'
                  : 'play_arrow'
              }}</mat-icon>
            </button>

            <div class="take-name">
              Take
              {{ editVideoTake.scene.chosenTake.videoEditTake.take.number + 1 }}
            </div>
            <div
              *ngIf="editVideoTake.videoScenes.length > 1"
              class="take-amount">
              {{ editVideoTake.videoScenes.length - 1 }}
              More
              {{
                editVideoTake.videoScenes.length - 1 === 1 ? 'Take' : 'Takes'
              }}
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container>
        <mat-list role="listitem" class="list-item">
          <!--        [value]="videoScene"-->
          <!-- <div matListItemIcon>
                                <div class="poster-wrapper">
                                  <div
                                    *ngIf="
                                      videoScene.take.uploadedThumbnails &&
                                      videoScene.take.uploadedThumbnails.length > 0 &&
                                      videoScene.take.uploadedThumbnails[0]
                                    "
                                    [ngStyle]="{
                                      'background-image':
                                        'url(' + videoScene.take.uploadedThumbnails[0] + ')'
                                    }"></div>
                                </div>
                              </div> -->
          <!--          (click)="selectTake(videoScene.take, videoScene)-->

          <ng-container *ngFor="let editTake of editVideoTake.videoScenes">
            <div
              *ngIf="editTake !== editVideoTake.scene.chosenTake.videoEditTake"
              class="take-row"
              disableRipple>
              <button
                matTooltip="Preview before selecting"
                mat-mini-fab
                class="cool-button gray play-pause small-icon-button"
                color="secondary"
                (click)="playEditTake(editTake)">
                <mat-icon>{{
                  editTake.isPlaying ? 'pause' : 'play_arrow'
                }}</mat-icon>
              </button>
              <div class="take-name">{{ editTake.take.title }}</div>
              <button
                class="take-select-button cool-button gray"
                mat-stroked-button
                (click)="
                  replaceTakeInTimeLineAndTakeList(editTake, editVideoTake)
                ">
                Select
              </button>
            </div>
          </ng-container>

          <!-- <button
                      [class.active]="true"
                      class="play-btn cool-button gray"
                      color="secondary"
                      mat-flat-button>
                      <mat-icon>play_arrow</mat-icon>
                    </button> -->
          <!-- <div matListItemIcon class="to-end">
                      <div class="scene-duration">
                        {{ videoScene.durationOnTrack | timeFormat }}
                      </div>
                    </div> -->
        </mat-list>
      </ng-container>
    </mat-expansion-panel>
  </ng-container>
</ng-container>
