import { Injectable } from '@angular/core';
import { ProjectStoreService } from './project-store.service';
import { TakeStatusEnum } from 'src/app/models/project/take/take-model';
import { IScene } from '../../../models/project/scene-model';
import { BehaviorSubject, take } from 'rxjs';

export interface ISceneRecordingStatus {
  scene: IScene;
  leftToRecord: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RecordingStatusManagerService {
  private _scenesLeftToRecord: IScene[] = [];
  private _scenesReadyToExport: IScene[] = [];
  private _allSceneStatuses: ISceneRecordingStatus[] = [];
  private _sceneStatusUpdtes$ = new BehaviorSubject<ISceneRecordingStatus[]>(
    []
  );
  public sceneStatusUpdtes$ = this._sceneStatusUpdtes$.asObservable();

  constructor(private projectStoreService: ProjectStoreService) {
    this.projectStoreService.projectSource$.subscribe((project) => {
      if (!project) return;
      this._scenesLeftToRecord = [];
      this._scenesReadyToExport = [];
      this._allSceneStatuses = [];

      project.scenes?.forEach((scene: IScene) => {
        const sceneStatus = {
          scene: scene,
          leftToRecord: this.isSceneLeftToRecord(scene),
        };
        if (sceneStatus.leftToRecord) {
          this._scenesLeftToRecord.push(scene);
        } else {
          this._scenesReadyToExport.push(scene);
        }
        this._allSceneStatuses.push(sceneStatus);
      });
      this._sceneStatusUpdtes$.next(this._allSceneStatuses);
      this.sceneStatusUpdtes$.pipe(take(1)).subscribe((x) => {
        console.log('this._allSceneStatuses', x);
      });
    });
  }

  /**
   * Determines if a scene is left to record - meaning the recording is required in order to export the scene.
   * @param {Object} scene - The scene object to evaluate.
   */
  isSceneLeftToRecord(scene: IScene) {
    // If it's hidden, consider the scene as done
    if (scene.isHidden) {
      return false;
    }
    // If there's no layout/ our none layout, consider the scene as done

    if (scene.stagePositions.length <= 1) {
      return false;
    }
    // If there are no takes, the scene is not ready
    if (scene.takes.length === 0) {
      return true;
    }
    const firstTake = scene.chosenTake;
    /// If there's a take and it's recorded, consider the scene as done
    if (firstTake.status === TakeStatusEnum.RECORDED) {
      return false;
    }
    // In any other case, the scene is not ready
    return true;
  }

  /**
   * Array of scenes ids that left to record
   */
  get scenesLeftToRecord(): IScene[] {
    return this._scenesLeftToRecord;
  }

  get scenesReadyToExport(): IScene[] {
    return this._scenesReadyToExport;
  }

  get allSceneStatuses(): { scene: IScene; leftToRecord: boolean }[] {
    return this._allSceneStatuses;
  }
}
