import { BehaviorSubject } from 'rxjs';
import { ProjectAuthApiService } from 'src/app/services/api/auth/project-auth-api.service';
import { ActivatedRoute } from '@angular/router';
import { IVideoEditTake } from '../../../../../../../models/project/edit/edit-model';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
} from '@angular/core';
import { ProjectStoreService } from '../../../../../../../services/state-management/project/project-store.service';
import { TranscriptStatusEnum } from '../../../../../../../models/defines';
import {
  fadeInLeftOnEnterAnimation,
  fadeInRightOnEnterAnimation,
  fadeOutLeftOnLeaveAnimation,
  fadeOutRightOnLeaveAnimation,
} from 'angular-animations';
import { EditManagerService } from '../../../../../../../services/show/project/edit-manager.service';
import { IProject } from '../../../../../../../models/project-model';
import { Input, SimpleChanges } from '@angular/core';
import { EditRoomStateService } from '../../edit-room-state.service';
import { TranscriptManagerService } from 'src/app/services/show/project/transcript-manager.service';
const TIME_FOR_POLLING = 5000; // Polling interval in milliseconds

@Component({
  selector: 'app-subtitles-tab',
  templateUrl: './subtitles-tab.component.html',
  styleUrls: ['./subtitles-tab.component.scss'],
  animations: [
    fadeInRightOnEnterAnimation({ delay: 500 }),
    fadeInLeftOnEnterAnimation({ delay: 500 }),
    fadeOutRightOnLeaveAnimation({ delay: 300 }),
    fadeOutLeftOnLeaveAnimation({ duration: 300 }),
  ],
})
export class SubtitlesTabComponent implements OnChanges, OnInit, AfterViewInit {
  @Input({ required: true, alias: 'videosOnTrack' })
  videosOnTrack: IVideoEditTake[];

  showStyle = false;
  haveSubtitles = true;
  project: IProject;
  loadingData$ = new BehaviorSubject<boolean>(false);

  // generateTranscriptDone$ = new BehaviorSubject<boolean>(false);
  transcriptStatusEnum = TranscriptStatusEnum;
  //TODO: CHANGE ANY
  private pollingInterval: any;

  constructor(
    private editManager: EditManagerService,
    private projectStoreService: ProjectStoreService,
    private changeDetectRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private projectApiService: ProjectAuthApiService,
    private editRoomStateManager: EditRoomStateService,
    public transcriptManager: TranscriptManagerService

  ) {
    this.projectStoreService.projectSource$.subscribe((project) => {
      this.project = project;
    });
  }
  ngOnInit(): void {
    this.editRoomStateManager.currentEditJob$.subscribe((editJob) => {});
  }

  ngAfterViewInit(): void {
    const projectId = this.route.snapshot.params['id'];

    if (projectId) {
      this.changeDetectRef.detectChanges();
    }
  }

  generateSubtitlesAsync() {
    this.loadingData$.next(true);
    this.editManager.generateSubtitlesAsync(this.project);
  }
  // subtitles: any[] = [];
  //
  ngOnChanges(changes: SimpleChanges): void {
    // check if videos on track have changed, if it did, keep a flatten copy of the subtitles
    // if (changes['videosOnTrack'].currentValue !== changes['videosOnTrack'].previousValue) {
    //     this.videosOnTrack = changes['videosOnTrack'].currentValue;
    //     this.subtitles = this.fluttenSubtitles(this.videosOnTrack);
    // }
  }

  //
  // fluttenSubtitles(videos: IVideoEditTake[]) {
  //     return videos.map(v => v.subtitles).flat();
  // }
}
