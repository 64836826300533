import { FormControl } from '@angular/forms';
import { ITake, ITakeInDTO, ITakeOutDTO } from './take/take-model';
import { IComposition } from '../defines';
import { IStagePositionsExtended } from 'src/app/models/defines';
import { VideoEditTake } from '../job/edit-job-schema';

export interface ISceneFormControl {
  title: FormControl<string>;
  script: FormControl<string>;
}

export interface ISceneCopy {
  script?: string;
  name?: string;
  imagePrompt?: string;
  directorTip?: string;
}

export enum SceneStatusEnum {
  GENERATING = 'generating',
  DONE = 'done',
}

export interface ICommonSceneData {
  id: string;
  name: string;
  copy: ISceneCopy;
  isHidden: boolean;
  composition: IComposition;
}

export interface IBasicScene extends ICommonSceneData {
  id: string;
  selectedTakeId: string;
  status: SceneStatusEnum;
  stagePositions: IStagePositionsExtended[];
}

/// Creating ISceneConfigs to make an object without functions in the scene convertor
export interface ISceneConfigs extends ISceneInDTO {
  takes: ITake[];
  chosenTake: ITake;
  formControls: ISceneFormControl;
}
export interface IScene extends ISceneConfigs {
  clone(projectId: string): IScene;
}
export class Scene implements IScene {
  takes: ITake[];
  chosenTake: ITake;
  formControls: ISceneFormControl;
  videoEditTakes: VideoEditTake[];
  id: string;
  selectedTakeId: string;
  status: SceneStatusEnum;
  stagePositions: IStagePositionsExtended[];
  name: string;
  copy: ISceneCopy;
  isHidden: boolean;
  composition: IComposition;

  constructor(sceneConfigs: ISceneConfigs) {
    Object.assign(this, sceneConfigs);
  }

  clone(projectId: string) {
    const serializedScene = JSON.stringify(this.toJSON());
    const clonedObject = JSON.parse(serializedScene) as Scene;
    clonedObject.takes = this.takes.map((take) =>
      take.clone(projectId, this.id)
    );
    clonedObject.chosenTake = this.chosenTake.clone(projectId, this.id);

    clonedObject.videoEditTakes = this.videoEditTakes;
    clonedObject.formControls = this.formControls;
    return clonedObject;
  }

  private toJSON() {
    const { formControls, videoEditTakes, chosenTake, takes, ...rest } = this;
    return rest;
  }
}
export interface ISceneOutDTO extends IBasicScene {
  takes: ITakeOutDTO[];
}

export interface ISceneInDTO extends IBasicScene {
  takes: ITakeInDTO[];
}
