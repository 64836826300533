import { Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../../services/guards/authentication.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { WalkthroughPanelService } from 'src/app/services/walkthrough-panel.service';
import { AnalyticsNotifierService } from '../../services/utils/analytics-notifier.service';
import { MatDialog } from '@angular/material/dialog';
import { PathHelperService } from 'src/app/services/utils/path-helper.service';
import { NavListenersService } from '../../services/nav-listeners.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FeedbackDialogComponent } from '../dialogs/feedback-dialog/feedback-dialog.component';
import { FeedbackEnum } from 'src/app/models/defines';
import { UserService } from 'src/app/services/api/auth/user.service';
import { PaymentDialogComponent } from '../dialogs/plans-and-payments/payment-dialog/payment-dialog.component';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @Output('openStateChange') openStateChange$ = new EventEmitter<boolean>();
  toOpenWalkthrough: boolean = false;
  public _isOpen = new BehaviorSubject<boolean>(true);
  LOCAL_STORAGE_KEY = 'sideNavIsOpen';
  isShuffllUser: boolean;
  isMobileUser: boolean;

  constructor(
    public navListener: NavListenersService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public gtmService: AnalyticsNotifierService,
    public authentication: AuthenticationService,
    public config: ConfigurationService,
    public walkthroughPanelService: WalkthroughPanelService,
    private dialog: MatDialog,
    public pathHelperService: PathHelperService,
    private breakpointObserver: BreakpointObserver,
    private userService: UserService
  ) {
    this.isMobileUser = this.userService.isMobileUser();

    const savedState = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    const isHandset = this.breakpointObserver.isMatched(Breakpoints.Handset);

    // Set initial state based on device type, override if local storage has a value
    const shouldStartOpen = savedState === 'true' && !isHandset;
    // console.log(shouldStartOpen);
    this._isOpen = new BehaviorSubject<boolean>(shouldStartOpen);
  }

  get isOpen(): boolean {
    return this._isOpen.getValue();
  }

  set isOpen(value: boolean) {
    if (value !== this._isOpen.getValue()) {
      this._isOpen.next(value);
      this.openStateChange$.emit(value);
      localStorage.setItem(this.LOCAL_STORAGE_KEY, String(value));
    }
  }

  ngOnInit(): void {
    this.walkthroughPanelService.walkthroughHidden$.subscribe({
      next: (response) => {
        this.toOpenWalkthrough = response;
      },
    });

    this.isShuffllUser = this.isShuffll();

    this.navListener.isSideNavOpen.subscribe((value) => {
      if (value !== null && value !== undefined) {
        this._isOpen.next(value);
      }
    });
  }

  toggleIsOpen() {
    this.isOpen = !this.isOpen;
  }

  isShuffll() {
    return this.authentication
      .getUser()
      ?.email?.toLowerCase()
      .includes('shuffll');
  }

  goToAdmin() {
    this.router.navigate([
      'dashboard',
      {
        outlets: {
          panel: ['backoffice', { outlets: { 'admin-panel': ['users'] } }],
        },
      },
    ]);
  }

  openHelp() {
    window.open('https://shuffll.com/faq/', '_blank');
  }

  public openPaymentsDialog() {
    this.dialog.open(PaymentDialogComponent, {
      disableClose: false,
      panelClass: 'payments-dialog',
      maxHeight: '90vh',
    });
  }

  public openWalkthrough(): void {
    this.toOpenWalkthrough = true;
    this.walkthroughPanelService.openWalkthrough(this.toOpenWalkthrough);
  }

  openFeedbackPopUp() {
    this.dialog.open(FeedbackDialogComponent, {
      width: '500px',
      height: '450px',
      data: {
        feedbackFormId: FeedbackEnum.GENERAL_FEEDBACK,
      },
    });
  }
}
