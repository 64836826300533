<div class="empty-subtitles">
  <div class="section">
    <h5 class="section-title primary bold">Automatic or Manual subtitles</h5>
    <div class="section-content">
      <div class="row-centered buttons-area">
        <button
          class="auto-manual-button"
          mat-flat-button
          (click)="generateSubtitles()">
          <mat-icon>assistant</mat-icon>
          Automatic Subtitles
        </button>
        <button
          class="auto-manual-button"
          [disabled]="true"
          Save
          mat-flat-button>
          <mat-icon>keyboard</mat-icon>
          Manual Subtitles
        </button>
      </div>
    </div>
  </div>
</div>
