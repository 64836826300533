import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigurationService} from "../../configuration.service";
import {ICustomLayout} from "../../../models/defines";
import {delayWhen, retryWhen, scan} from 'rxjs/operators';
import {Observable, timer} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomLayoutsAuthApiService {

  path = '/custom-layouts';


  constructor(private http: HttpClient, private config: ConfigurationService) {
  }

  getAllCustomLayouts(maxRetries = 5, delayMs = 1000): Observable<{ customLayouts: ICustomLayout[] }> {
    return this.http.get<{ customLayouts: ICustomLayout[] }>(`${this.config.apiIP + this.path}/all`)
      .pipe(
        retryWhen(errors =>
          errors.pipe(
            scan((retryCount, error) => {
              if (retryCount >= maxRetries) {
                throw error;
              } else {
                console.log(`Attempt ${retryCount + 1}: retrying...`);
                return retryCount + 1;
              }
            }, 0),
            delayWhen(retryCount => timer(retryCount * delayMs))
          )
        )
      );
  }
}
