import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { COUNTDOWN_VIDEO_RELATIVE_PATH } from 'src/app/constants/shared/assets.constants';
import { IScene } from '../../../../../../../models/project/scene-model';
import { ITake } from '../../../../../../../models/project/take/take-model';
import { RecordingManagerService } from '../../../../../../../services/studio/recording-manager.service';
import {
  ICustomLayout,
  IStagePositionsExtended,
} from '../../../../../../../models/defines';

@Component({
  selector: 'studio-live-scene',
  templateUrl: './live-scene.component.html',
  styleUrls: ['./live-scene.component.scss'],
})
export class LiveSceneComponent implements OnInit {
  COUNTDOWN_VIDEO_RELATIVE_PATH = COUNTDOWN_VIDEO_RELATIVE_PATH;

  stagePositions: IStagePositionsExtended[];
  @Input('scene') scene: IScene;
  @Input('take') take: ITake;
  @Input('designBasePath') designBasePath: string;
  @Input('startCountdown') startCountdown = new BehaviorSubject<boolean>(false);
  @Output('countdownEnded') countdownEnded = new EventEmitter();

  animationCreated = new BehaviorSubject<boolean>(false);

  constructor(public recordingManager: RecordingManagerService) {
    // this.recordingManager.getCustomLayout(this.scene.customLayout).then((customLayout: ICustomLayout) => {
    //   console.log('customLayout', customLayout);
    //   this.currentCustomLayout = customLayout
    // });
  }

  ngOnInit(): void {
    console.log('this.scene', this.scene);
    this.stagePositions = this.scene.stagePositions;
  }

  public async countdownEndedAsync() {
    this.startCountdown.next(false);
    this.countdownEnded.next(true);
  }
}
