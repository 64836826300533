import { IEditAudioScene } from 'src/app/models/job/edit-job-schema';
import { IVideoLottieCompose } from 'src/app/models/job/editor-defines';
import {
  IAudioEditTake,
  ICommonLocalEditTake,
  IVideoEditTake,
} from 'src/app/models/project/edit/edit-model';
import { config } from 'rxjs';

export enum ChangeVideoListType {
  ZOOM = 'zoom',
  ORDER = 'order',
}

export interface ISeekFromVideo {
  scene: IVideoEditTake;
  seekTo: number;
}

export interface IStartFromAudio {
  scene: IEditAudioScene;
  startFrom: number;
}

export interface IVideoAudioScenes {
  videoEditTakes: ISeekFromVideo[];
  audioScenes: IStartFromAudio[];
}

export interface IChangeVideos {
  newEditVideos: IVideoEditTake[];
  changeType: ChangeVideoListType;
}

export class EditRoomUtilityFunctions {
  static createVideoLottieComposeConfigs(
    editTakes: IVideoEditTake[]
  ): IVideoLottieCompose[] {
    if (!editTakes) {
      return null;
    }
    const videoLottieComposeConfigs: IVideoLottieCompose[] = editTakes.flatMap(
      (editTake) => {
        return editTake.take.lottieComposedConfigs.composeConfigs
          .videoLottieConfigs;
      }
    );

    return videoLottieComposeConfigs;
  }

  static getAudioDurationAsync(src: string): Promise<number> {
    return new Promise((resolve, reject) => {
      let audio = new Audio();
      audio.src = decodeURIComponent(src);
      audio.addEventListener('loadedmetadata', () => {
        resolve(audio.duration);
      });
      audio.addEventListener('error', (e) => {
        reject('Error loading audio file: ' + src);
      });
    });
  }

  /// Right now it works only with single track(1 video 1 audio) and not multiple
  static async findRelevantScenesToTargetTimeAsync(
    targetTime: number,
    videoScenes: IVideoEditTake[] = [],
    audioScenes: IEditAudioScene[] = []
  ): Promise<IVideoAudioScenes | null> {
    const foundVideoScenes: ISeekFromVideo[] = [];
    const foundAudioScenes: IStartFromAudio[] = [];

    if (isNaN(targetTime) || typeof targetTime !== 'number') {
      console.warn(
        `Could not find scenes in the target time because one of the arguemts is null`
      );
      return null;
    }

    const findVideoScenesPromise = new Promise<void>(
      (videoResolve, videoReject) => {
        let cumulativeVideoDuration = 0;
        for (const scene of videoScenes) {
          if (
            isNaN(scene.durationOnTrack) ||
            typeof scene.durationOnTrack !== 'number'
          ) {
            console.warn(
              `Could not search video scene, duration on track is not a number ${scene.id}`
            );
            continue;
          }

          cumulativeVideoDuration += scene.durationOnTrack;

          if (targetTime <= cumulativeVideoDuration) {
            const newSceneDuration = cumulativeVideoDuration - targetTime;
            const perc = 1 - newSceneDuration / scene.durationOnTrack;
            // const startFrom = scene.take.endTime - newSceneDuration;
            const startFrom = scene.durationOnTrack * perc;
            foundVideoScenes.push({ scene: scene, seekTo: startFrom });
            break;
          }
        }
        return videoResolve();
      }
    );

    const findAudioScenesPromise = new Promise<void>(
      (audioResolve, audioReject) => {
        let cumulativeAudioDuration = 0;
        for (const scene of audioScenes) {
          if (isNaN(scene.durationOnTrack)) {
            console.warn(
              `Could not search video scene, duration on track is null`
            );
            continue;
          }

          cumulativeAudioDuration += scene.durationOnTrack;

          if (targetTime <= cumulativeAudioDuration) {
            const startFrom =
              scene.startTime + cumulativeAudioDuration - targetTime;
            foundAudioScenes.push({ scene: scene, startFrom: startFrom });
            break;
          }
        }
        return audioResolve();
      }
    );

    await Promise.all([findVideoScenesPromise, findAudioScenesPromise]);
    const videoAudioScenes: IVideoAudioScenes = {
      videoEditTakes: foundVideoScenes,
      audioScenes: foundAudioScenes,
    };
    return videoAudioScenes;
  }

  static getVideoDurationOnTrack(scene: IVideoEditTake) {
    const { trimEndOfScene, trimStartOfScene } = this.getTrims(scene);

    return (
      scene.take.endTime -
      (scene.trimEnd * 1000 ?? 0) -
      trimEndOfScene -
      (scene.take.startTime + (scene.trimStart * 1000 ?? 0) + trimStartOfScene)
    );
  }

  static getAudioDurationOnTrack(scene: IEditAudioScene) {
    return (
      (scene.duration -
        (scene.trimEnd * 1000 ?? 0) -
        (scene.trimStart * 1000 ?? 0)) *
      1000
    );
  }

  static convertMillisecondsToMarkerPosition(
    milliseconds: number,
    baseWidth: number
  ) {
    return Math.floor((milliseconds * baseWidth) / 1000);
  }

  static convertMarkerPositionToMilliseconds(
    markerPosition: number,
    baseWidth: number
  ) {
    return (markerPosition / baseWidth) * 1000;
  }

  static deleteOtherLocalStorageKeys(
    currentStreamId: string,
    currentKey: string,
    keyStartsWith: string
  ): void {
    // Iterate through all keys in local storage
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      // Check if the key matches the pattern 'edit-room-X (or different pattern, depence on keyStartsWith)' and has a different stream ID
      if (key && key.startsWith(keyStartsWith) && key !== currentKey) {
        const streamIdFromKey = this.extractStreamId(key);

        if (streamIdFromKey !== currentStreamId) {
          // Delete the key
          localStorage.removeItem(key);
        }
      }
    }
  }

  // Type guard function for IEditVideoScene
  static isEditVideoScene(
    scene: ICommonLocalEditTake
  ): scene is IVideoEditTake {
    return 'take' in scene;
  }

  static isEditAudioScene(
    scene: ICommonLocalEditTake
  ): scene is IAudioEditTake {
    return 'audioPath' in scene;
  }

  static getTrims(configVideo: IVideoEditTake) {
    let trimEndOfScene = 0;
    let trimStartOfScene = 0;
    if (configVideo.take.videoLayers.length === 0) {
      for (const lottieLayer of configVideo.take.lottieLayers) {
        trimEndOfScene += lottieLayer.trimEnd;
        trimStartOfScene += lottieLayer.trimStart;
      }
    } else {
      for (const videoLayer of configVideo.take.videoLayers) {
        trimEndOfScene += videoLayer.trimEnd;
        trimStartOfScene += 0;
      }
    }

    return { trimEndOfScene, trimStartOfScene };
  }

  private static extractStreamId(key: string): string {
    // Extract the stream ID from the key
    const parts = key.split('-');
    return parts[parts.length - 1];
  }
}
