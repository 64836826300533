import { Injectable } from '@angular/core';
import { TranscriptApiService } from '../../api/auth/projects/transcript-api.service';
import { MissingArgumentsError } from '../../../models/errors/general.errors';
import { TakeConverterService } from '../../project/convertors/take/take-converter.service';
import { ITake } from '../../../models/project/take/take-model';
import { IProject } from '../../../models/project-model';
import { ProjectConverterService } from '../../project/convertors/project-converter.service';
import { TranscriptStatusEnum } from 'src/app/models/defines';
import { BehaviorSubject } from 'rxjs';
import { AnalyticsNotifierService } from '../../utils/analytics-notifier.service';

@Injectable({
  providedIn: 'root',
})
export class TranscriptManagerService {
  constructor(
    private transcriptApi: TranscriptApiService,
    private projectConverter: ProjectConverterService,
    private analyticsService: AnalyticsNotifierService
  ) {}
  public isGenerateSubtitlesFailed$ = new BehaviorSubject<boolean>(false);

  public generateSubtitlesAsync(project: IProject) {
    if (!project || !project.id) {
      this.isGenerateSubtitlesFailed$.next(true);
      throw new MissingArgumentsError(
        `Could not generate transcript because one of the arguments is null`
      );
    }

    this.analyticsService.notifyEvent('Generate Subtitles', {});
    const projectId = project.id;

    return new Promise<IProject>((generateResolve, generateReject) => {
      this.transcriptApi.generateTranscript$(projectId).subscribe({
        next: async (project) => {
          if (!project) generateReject(`No takes were provided by server`);
          if (project.statuses?.transcript) {
            project.statuses.transcript = TranscriptStatusEnum.DONE;
          }

          const localProject = await this.projectConverter.inToLocalAsync(
            project,
            true,
            null
          );

          this.isGenerateSubtitlesFailed$.next(false);

          return generateResolve(localProject);
        },
        error: async (error) => {
          this.isGenerateSubtitlesFailed$.next(true);
          console.error(`Could not generate subtitles. error: `, error);
        },
      });
    });
  }
}
