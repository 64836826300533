import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { PlanEnum, User } from 'src/app/models/user';
import { ProfileService } from '../show/profile.service';
import { FeatureService } from '../feature-service.service';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentsGuard implements CanActivate {
  constructor(
    private profileService: ProfileService,
    private featureService: FeatureService
  ) {}

  user: User;
  toActivate: boolean;
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    this.user = this.profileService.user$.value;

    this.featureService
      .hasFeatureAccess('AB_mustpay_free')
      .pipe(take(2))
      .subscribe({
        //In case the user in 'mustpay' group hasAccess = true
        next: (hasAccess) => {
          if (this.user.plan === PlanEnum.FREE && hasAccess === true) {
            console.log(
              'Could not navigate to studio because user plan is ' +
                `${this.user.plan}`
            );
            this.toActivate = false;
          } else if (hasAccess === true) {
            console.log(
              'Navigate to studio with user plan ' + `${this.user.plan}`
            );
            this.toActivate = true;
          } else {
            console.log(
              'Navigate to studio with user plan ' + `${this.user.plan}`
            );
            this.toActivate = true;
          }
        },
        error: (error) => {
          console.warn(`NO FEATURE FLAG DATA`);
        },
      });
    return this.toActivate;
  }
}
