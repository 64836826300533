import { Component } from '@angular/core';
import { PlansApiService } from '../../../../services/api/plans-api.service';
import { catchError, of } from 'rxjs';
import { PlansService } from '../../../../services/plans.service';
import { MatDialogRef, MatDialogState } from '@angular/material/dialog';

@Component({
  selector: 'payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrl: './payment-dialog.component.scss',
})
export class PaymentDialogComponent {
  constructor(
    public plansService: PlansService,
    public dialogRef: MatDialogRef<PaymentDialogComponent>
  ) {
    this.plansService.paymentNotifier$.subscribe((paymentStatus) => {
      // We should get a truthful result when payment done.
      // Todo: get a payment token and test it or add a guard to test it.
      if (paymentStatus && dialogRef) {
        console.log('Closing the payment dialog');
        /// Check if the dialog is open
        if (dialogRef.getState() === MatDialogState.OPEN) {
          // Close the payment dialog.
          dialogRef.close(true);
        } else {
          console.warn('The dialog is not open, WTF?!');
        }
      }
    });
    plansService.getUnifiedPlan();
  }
}
