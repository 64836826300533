import {Component, OnInit} from '@angular/core';
import {FormGroup, UntypedFormBuilder} from '@angular/forms';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router,} from '@angular/router';
import {AuthenticationService} from './services/guards/authentication.service';
import {NavigationHelperService} from './services/utils/navigation-helper.service';
import {ProfileService} from './services/show/profile.service';
import {ROLE} from './models/defines';
import {ConsoleToggleService} from './services/utils/console-toggle.service';
import {ConfigurationService} from './services/configuration.service';
import {AnalyticsNotifierService} from './services/utils/analytics-notifier.service';
import {PathHelperService} from './services/utils/path-helper.service';
import {Subscription} from 'rxjs';
import Intercom from '@intercom/messenger-js-sdk';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)',
    },
})
export class AppComponent implements OnInit {
    title = 'shuffll';
    options: FormGroup;
    loading = true;
    userRole: ROLE = null;
    ROLE = ROLE;

    sessionTimer: any = null;
    sessionTimeInMinutes: number = 0;
    private subscriptions: Subscription = new Subscription();

    constructor(
        fb: UntypedFormBuilder,
        public auth: AuthenticationService,
        private navigationService: NavigationHelperService,
        public router: Router,
        public profileService: ProfileService,
        private consoleToggleService: ConsoleToggleService,
        private configService: ConfigurationService,
        public analyticsNotifier: AnalyticsNotifierService,
        public pathHelperService: PathHelperService
    ) {

        Intercom({
            app_id: configService.intercomKey,
        });


        // this.indexCacheService.openDatabaseAsync('myIndexDb', 1);
        this.profileService.role$.asObservable().subscribe((recievedRole) => {
            this.userRole = recievedRole;
        });

        this.options = fb.group({
            bottom: 0,
            fixed: false,
            top: 0,
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.loading = true;

                // console.log("Navigation start")
            }
            if (
                event instanceof NavigationEnd ||
                event instanceof NavigationError ||
                event instanceof NavigationCancel
            ) {
                this.loading = false;
                // console.log("Navigation End")
            }
        });

        // Google / mixpanel analytics and invite ID
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (this.configService.trackUserEvents) {
                    // const inviteId = route.snapshot.paramMap.get('invite');
                    // if (inviteId) {
                    //   console.log('invite is ' + inviteId);
                    //   this.auth.setInviteId(inviteId);
                    // }

                    // Will return the full path after the / - like: /host/isaac
                    const pagePath = event.urlAfterRedirects;

                    this.analyticsNotifier.notifyPage(pagePath);
                }

                // this.mixpanelService.track('page_view', {
                //   pageName: pageSplitPath,
                //   pageUrl: pagePath
                // });
            }
        });

        // Subscribe to know what should be the state of the menu

        // hostListener.isSideNavOpen.asObservable().subscribe(newSideNavState => this.sideNavOpen = false);
    }

    ngOnInit(): void {
        this.consoleToggleService.disableConsoleInProduction();

        this.analyticsNotifier.initalize();
    }

    jumpToHost() {
        this.navigationService.jumpToHost();
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        let key = event.which || event.keyCode;
        // console.log(key)

    }

    ngOnDestroy(): void {
        clearInterval(this.sessionTimer);
        this.subscriptions.unsubscribe();
    }
}
