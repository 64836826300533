import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  HostBinding,
} from '@angular/core';
import { AspectRatioEnum } from '../models/project/edit/edit-model';

@Directive({
  selector: '[aspectRatioBox]',
})
export class AspectRatioBoxDirective implements OnChanges {
  @Input() aspectRatio: AspectRatioEnum;
  @HostBinding('class.aspect-ratio-box') aspectRatioBoxClass = true;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnChanges() {
    this.applyAspectRatio();
  }

  private applyAspectRatio() {
    const aspectRatio = this.aspectRatio ?? '16/9';

    this.renderer.setStyle(this.el.nativeElement, 'aspect-ratio', aspectRatio);

    // Remove all ratio classes first
    this.renderer.removeClass(this.el.nativeElement, 'ratio-9x16');
    this.renderer.removeClass(this.el.nativeElement, 'ratio-16x9');
    this.renderer.removeClass(this.el.nativeElement, 'ratio-1x1');

    // Add the appropriate class
    if (!this.aspectRatio || this.aspectRatio === '9/16') {
      this.renderer.addClass(this.el.nativeElement, 'ratio-9x16');
    } else if (this.aspectRatio === '16/9') {
      this.renderer.addClass(this.el.nativeElement, 'ratio-16x9');
    } else if (this.aspectRatio === '1/1') {
      this.renderer.addClass(this.el.nativeElement, 'ratio-1x1');
    }
  }
}
