import {Component, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {VgApiService} from '@videogular/ngx-videogular/core';
import {ConfigurationService} from '../../../../../../../services/configuration.service';
import {take} from 'rxjs';

@Component({
    selector: 'video-thumbnails',
    templateUrl: './video-thumbnails.component.html',
    styleUrls: ['./video-thumbnails.component.scss']
})
export class VideoThumbnailsComponent implements OnDestroy, OnInit {

    @Input('videoUrl') videoUrl: string;
    realVideoUrl: string;
    @Input('duration') duration: number;
    baseCdnUrl: string;
    thumbnailDataURLs: string[] = [];

    api: VgApiService;

    constructor(
        private config: ConfigurationService,
        private renderer: Renderer2
    ) {
        this.baseCdnUrl = this.config.baseCdnUrl;
    }

    ngOnInit(): void {
        if (this.videoUrl?.includes('dcdd')) {
            // this.realVideoUrl = this.videoUrl?.replace('dcdd', 'dcff');
            this.realVideoUrl = '/files/platform/tmp-dash-test/output.mpd';
            ;
            console.log(this.realVideoUrl);
        }

    }

    onPlayerReady(api: VgApiService): void {
        this.api = api;
        this.api.getDefaultMedia().subscriptions.loadedMetadata.subscribe(() => {
            this.generateThumbnailsInterval(2, 115, 65);

        })
    }

    captureThumbnailAtTime(seconds: number, width: number, height: number): Promise<string> {
        console.log(`Capturing thumbnail at ${seconds} seconds`);
        return new Promise<string>((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const videoElement = this.api.getDefaultMedia().elem;

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');

            // Seek to the desired time in seconds
            videoElement.currentTime = seconds;

            // Wait for the video to seek
            videoElement.onseeked = () => {
                // Draw video frame onto the canvas
                ctx.drawImage(videoElement, 0, 0, width, height);

                // Resolve with the captured thumbnail as data URL
                const thumbnailDataURL = canvas.toDataURL('image/jpeg');
                console.log(`Thumbnail captured: ${thumbnailDataURL}`);
                // Reset onseeked event handler to avoid memory leaks
                videoElement.onseeked = null;
                resolve(thumbnailDataURL);

            };

            // Handle seek error
            videoElement.onerror = (event) => {
                reject(`Error seeking video: ${event}`);
            };
        });
    }

    async generateThumbnailsInterval(intervalSeconds: number, width: number, height: number): Promise<void> {
        const videoElement = this.api.getDefaultMedia().elem;
        const capturedThumbnails: Promise<string>[] = [];

        console.log(`Generating thumbnails at intervals of ${intervalSeconds} seconds, width: ${width}, height: ${height}, duration: ${this.duration}`)
        console.log(`video url: ${this.baseCdnUrl}${this.realVideoUrl}`)

        // Capture thumbnails at intervals until the end of the video
        for (let currentTime = 0; currentTime < this.duration; currentTime += intervalSeconds) {
            try {
                const thumbnailDataURL = await this.captureThumbnailAtTime(currentTime, width, height);
                capturedThumbnails.push(Promise.resolve(thumbnailDataURL));
            } catch (error) {
                console.error(`Error capturing thumbnail at ${currentTime} seconds:`, error);
                // Handle error as needed
            }
        }

        try {
            // Resolve all promises and store the data URLs
            this.thumbnailDataURLs = await Promise.all(capturedThumbnails);
            console.log('Thumbnails generated:', this.thumbnailDataURLs);
        } catch (error) {
            console.error('Error generating thumbnails:', error);
            // Handle error as needed
        }
    }


    ngOnDestroy(): void {
        // Clean up any subscriptions or handlers here if needed
    }
}
