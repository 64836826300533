<div [ngClass]="{ hiddenScene: scene.isHidden }">
  <app-silhouette
    *ngIf="scene.stagePositions.length > 1"
    [stagePositions]="scene.stagePositions"></app-silhouette>
  <dynamic-lottie
    [config]="{
    layout: scene.composition.layouts[0],
                  dynamicLottieChanges: scene.chosenTake.copy.dynamicLottieChanges,
                  basePath: designBasePath
                }"
    [lottieOptions]="{ loop: 1 }"></dynamic-lottie>
</div>
