import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  ISceneRecordingStatus
} from "../../../../../../services/state-management/project/recording-status-manager.service";

@Component({
  selector: 'scene-carousel-dots',
  templateUrl: './scene-carousel-dots.component.html',
  styleUrls: ['./scene-carousel-dots.component.scss']
})
export class SceneCarouselDotsComponent implements OnInit {

  @Input('currentIndex') currentIndex: number;
  @Input('sceneStatusUpdates') sceneStatusUpdates: ISceneRecordingStatus[];

  @Output('carouselDotClicked') carouselDotClicked = new EventEmitter<number>();


  constructor(private changeDetectorRef: ChangeDetectorRef) {

  }

  ngOnInit(): void {

  }


}
