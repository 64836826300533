import { Injectable } from '@angular/core';
import { ExportQualityEnum } from 'src/app/models/defines';
import { IProject } from 'src/app/models/project-model';
import {
  AspectRatioEnum,
  IBasicSceneTakePair,
  IEditInDTO,
  IExportEditJob,
  IExportEditOutDTO,
  IVideoEditTake,
} from 'src/app/models/project/edit/edit-model';
import { MissingArgumentsError } from '../../../models/errors/general.errors';
import { EditApiService } from '../../api/auth/projects/edit-api.service';
import { EditConvertorService } from '../../project/convertors/edit/edit-convertor.service';
import { ProjectStoreService } from '../../state-management/project/project-store.service';
import { ProjectAuthApiService } from '../../api/auth/project-auth-api.service';
import { TranscriptManagerService } from './transcript-manager.service';

@Injectable({
  providedIn: 'root',
})
export class EditManagerService {
  constructor(
    private editApiService: EditApiService,
    private editConvertor: EditConvertorService,
    private projectStoreService: ProjectStoreService,
    private projectApiService: ProjectAuthApiService,
    private transcriptManager: TranscriptManagerService
  ) {}

  async generateSubtitlesAsync(project: IProject) {
    const localProject =
      await this.transcriptManager.generateSubtitlesAsync(project);
    this.projectStoreService.setProjectSource(localProject);
  }

  public buildEditJobOutDTO(project: IProject, aspectRatio: AspectRatioEnum) {
    const selectedShots: IBasicSceneTakePair[] = this.getSelectedShots(project);
    const editOutDTO: IExportEditOutDTO = {
      selectedShots: selectedShots,
      projectId: project.id,
      aspectRatio: aspectRatio,
      exportQuality: ExportQualityEnum.P1080,
      toMaxine: false,
      plugins: null,
    };
    return editOutDTO;
  }

  public exportEditAsync(
    project: IProject,
    videoTakes: IVideoEditTake[],
    localEdit: IExportEditJob
  ) {
    if (!project || !localEdit || !videoTakes) {
      throw new MissingArgumentsError(
        `Could not export because one of the arguments is null.`
      );
    }

    const edit = this.buildRealEditJobOutDTO(project, videoTakes, localEdit);
    return new Promise<IExportEditJob>((resolve, reject) => {
      this.editApiService.postEdit$(edit, false).subscribe({
        next: (inEdit) => {
          const localEdit = this.editConvertor.inToLocal(
            inEdit,
            project.scenes,
            project.designGroup.design.basePath,
            project.id
          );
          this.projectStoreService.replaceOrAddProjectEdits(
            project.id,
            localEdit
          );
          this.projectApiService.isLoadingExportData$.next(false);

          return resolve(localEdit);
        },
        error: (error) => {
          console.error(`Could not export! ${error}`);
          return reject(`Could not export.`);
        },
      });
    });
  }

  public saveEditStateAsync(
    project: IProject,
    editId: string,
    videoTakes: IVideoEditTake[]
  ) {
    if (!project || !editId || !videoTakes) {
      throw new MissingArgumentsError(
        `Could not save edit state because one of the arguments is null.`
      );
    }

    const selectedShots = this.getRealSelectedShots(videoTakes);
    return new Promise<IEditInDTO>((resolve, reject) => {
      this.editApiService
        .saveEdit$(project.id, editId, selectedShots)
        .subscribe({
          next: async (inEdit) => {
            const localEdit = this.editConvertor.inToLocal(
              inEdit,
              project.scenes,
              project.designGroup.design.basePath,
              project.id
            );
            this.projectStoreService.replaceOrAddProjectEdits(
              project.id,
              localEdit
            );
            return resolve(inEdit);
          },
          error: (error: Error) => {
            return reject(error);
          },
        });
    });
  }

  private getRealSelectedShots(videoEditTakes: IVideoEditTake[]) {
    if (!videoEditTakes) {
      throw new MissingArgumentsError(
        `Could not get data of edit takes because the argument is null`
      );
    }
    const selectedShots: IBasicSceneTakePair[] = [];
    for (const videoTake of videoEditTakes) {
      const trims = videoTake.trims;

      const selectedShot: IBasicSceneTakePair = {
        sceneId: videoTake.sceneId,
        takeId: videoTake.take.id,
        trims: trims,
      };
      selectedShots.push(selectedShot);
    }
    return selectedShots;
  }

  private getSelectedShots(project: IProject) {
    const selectedShots: IBasicSceneTakePair[] = [];
    for (const scene of project.scenes) {
      if (
        scene.isHidden ||
        !scene.selectedTakeId ||
        !scene.takes ||
        scene.takes.length === 0
      ) {
        continue;
      }
      const take = scene.takes.find((take) => take.id === scene.selectedTakeId);
      if (!take) {
        continue;
      }
      if (!take.duration) {
        continue;
      }
      selectedShots.push({
        sceneId: scene.id,
        takeId: scene.selectedTakeId,
        trims: {
          videoTrims: {
            start: 0,
            end: 0,
          },
          lottieTrims: {
            start: 0,
            end: 0,
          },
        },
      });
    }
    return selectedShots;
  }

  public buildRealEditJobOutDTO(
    project: IProject,
    videoTakes: IVideoEditTake[],
    localEdit: IExportEditJob
  ) {
    const selectedShots = this.getRealSelectedShots(videoTakes);
    const editOutDTO = this.editConvertor.localToOut(
      localEdit,
      project.id,
      selectedShots
    );

    return editOutDTO;
  }
}
