import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {AnimationItem} from 'lottie-web';
import {MatStep, MatStepper} from '@angular/material/stepper';
import {
    LoadingWithSwagComponent
} from '../../../../../../components/minor/loading-with-swag/loading-with-swag.component';
import {MatSelectionListChange} from '@angular/material/list';
import {CreativeAuthApiService} from '../../../../../../services/api/auth/creative-auth-api.service';
import {Router} from '@angular/router';
import {CopywriterService} from '../../../../../../services/show/copywriter.service';
import {fadeInOnEnterAnimation} from 'angular-animations';
import {
    GoalType,
    ITagAndSuggestions,
    IVideoTag,
} from 'src/app/models/defines';
import {catchError, finalize, retry} from 'rxjs/operators';
import {BehaviorSubject, of, Subscription, throwError} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {WalkthroughPanelService} from 'src/app/services/walkthrough-panel.service';
import {TaskTypeEnum} from 'src/app/models/walkthrough';
import {AnalyticsNotifierService} from '../../../../../../services/utils/analytics-notifier.service';
import {
    INewProjectRequest,
    ProjectAuthApiService,
} from '../../../../../../services/api/auth/project-auth-api.service';
import {IProjectInDTO} from 'src/app/models/project-model';
import {ProfileService} from 'src/app/services/show/profile.service';
import {ConfigurationService} from 'src/app/services/configuration.service';
import {IDesignGroup} from 'src/app/models/design.model';

interface Goal {
    type: GoalType;
    title: string;
    description: string;
    icon: string;
    animation?: AnimationItem;
}

@Component({
    selector: 'project-wizard',
    templateUrl: './project-wizard.component.html',
    styleUrls: ['./project-wizard.component.scss'],
    animations: [fadeInOnEnterAnimation({duration: 500, delay: 100})],
})
export class ProjectWizardComponent implements OnInit, OnDestroy {
    suggestions: string[] = null;
    isLoading = true;
    @ViewChild('loadingWithSwagFormat', {static: true})
    loadingWithSwagFormat: LoadingWithSwagComponent;
    @ViewChild('loadingWithSwagFinal', {static: true})
    loadingWithSwagFinal: LoadingWithSwagComponent;
    taskType: TaskTypeEnum = TaskTypeEnum.PRODUCTION;
    createNewProjectSubscription: Subscription;
    scriptIntro: string;
    production: IProjectInDTO;
    baseCdnUrl: string;

    formatLoadingSteps = [
        'Analyzing Storylines 📝',
        'Generating Storyline Recommendations 🌟',
    ];
    finalLoadingSteps = [
        'Generating AI Script 🤖',
        'Customizing Your Storyline 🚀',
        'Taking You To The Project 🎞️',
    ];

    ///'Animating Scenes ðŸŽ¬',

    selectedDesignGroup: IDesignGroup;
    allPromptSuggestions: ITagAndSuggestions[];

    @ViewChild('formatSuggestionLoading') loadingStep: MatStep;
    @ViewChild('finalLoading') finalLoading: MatStep;

    @ViewChild('stepper') stepper: MatStepper;
    userPrompt: string;

    subjectFormGroup = this._formBuilder.group({
        firstCtrl: ['', Validators.required],
    });
    goalsFormGroup = this._formBuilder.group({
        secondCtrl: ['', Validators.required],
    });
    isLinear = false;
    showSuggestions = false;

    type = 'hi';
    videoTags: IVideoTag[] = [];

    selectedVideoTag: IVideoTag = null;
    suggestedDesigns$ = new BehaviorSubject<IDesignGroup[]>(null);
    privateDesigns$ = new BehaviorSubject<IDesignGroup[]>(null);

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private creativeApi: CreativeAuthApiService,
        private _snackBar: MatSnackBar,
        private router: Router,
        private copyWriter: CopywriterService,
        private walkthroughPanelService: WalkthroughPanelService,
        private gtmService: AnalyticsNotifierService,
        public projectAuthApiService: ProjectAuthApiService,
        public profileService: ProfileService,
        private config: ConfigurationService
    ) {
        this.baseCdnUrl = this.config.baseCdnUrl;
    }

    changeVideoTag = (selectedTag: IVideoTag) => {
        this.selectedVideoTag = selectedTag;
        this.suggestions = this.allPromptSuggestions.find((x) => {
            return x.tag.id === selectedTag.id;
        })?.suggestions;
    };

    ngOnDestroy(): void {
        this.createNewProjectSubscription?.unsubscribe();
    }

    formatSelected(format) {
        console.log(format);
        this.selectedDesignGroup = format;
        this.nextStep();
    }

    getsuggestedDesigns() {
        this.creativeApi.getFormatSuggestion().subscribe((res) => {
            this.suggestedDesigns$.next(res.public);
            this.privateDesigns$.next(res.private);
        });
    }

    generateNewSuggestionDesigns(event: boolean) {
        if (event === true) {
            this.getsuggestedDesigns();
        }
    }

    async generateFormatSuggestions() {
        // TODO: UPDATE THE PROMPT IN ANOTHER PLACE
        this.copyWriter.userPrompt.next(this.userPrompt);

        const delay = 2400;
        console.log(this.loadingWithSwagFormat);
        const loadingPromise = this.createLoadingPromise(
            this.loadingWithSwagFormat,
            delay
        );

        // let formatsSuggestionPromise = this.formatManager.formatSuggestions.subscribe()

        // let formatsSuggestionPromise = this.formatApiService.getMockFormat()
        //   .toPromise();

        /// Todo: wait for format suggestions too
        var data = await Promise.all([loadingPromise]);

        this.nextStep();
    }

    createLoadingPromise(loader, delayBetweenSteps) {
        return new Promise((resolve, reject) => {
            let interval = setInterval(() => {
                if (loader) {
                    if (!loader.jumpStep()) {
                        clearInterval(interval);
                        resolve(true);
                    }
                }
            }, delayBetweenSteps);
        });
    }

    async finalizeFormatAndCopy() {
        const delayForPromise = 2800;

        const loadingPromise = this.createLoadingPromise(
            this.loadingWithSwagFinal,
            delayForPromise
        );

        // const basicData: IBasicProjectData = {
        //     format: this.selectedFormat,
        //     branding: this.profileService.user.branding,
        //     aspectRatio:
        //         this.selectedFormat.design.aspectRatio ?? AspectRatioEnum._1x1,
        //     toMaxine: false,
        // };
        const designGroupId = this.selectedDesignGroup._id;
        let projectCreationRequest: INewProjectRequest = {
            prompt: this.userPrompt,
            videoTagId: this.selectedVideoTag.id,
            designGroupId: designGroupId,
        };
        // this.formatManager.assignFormatById(designGroupId);

        this.createNewProjectSubscription = this.projectAuthApiService
            .createNewProject$(projectCreationRequest)
            .pipe(
                retry({
                    count: this.creativeApi.MAX_RETRY_ATTEMPTS,
                    delay: (error, retryCount) =>
                        this.creativeApi.shouldRetry(error, retryCount),
                }),
                catchError((error) => {
                    // Handle errors or propagate them further
                    console.error('Error:', error);
                    return throwError(() => error);
                }),
                finalize(async () => {
                    var data = await Promise.all([loadingPromise]);
                })
            )
            .subscribe({
                next: async (newProject) => {
                    console.log('Created Project', newProject.id);
                    if (!newProject) return;
                    // await this.artDirector.applyUserBrandingToScenesByProjectAsync(
                    //     newProject
                    // );
                    // for (const scene of newProject.scenes) {
                    //   if (!scene.takes || scene.takes.length === 0) continue;

                    //   for (const take of scene.takes) {
                    //     const a = scene.composition.layouts[0].dynamicClientLayerId;

                    //   }
                    // }
                    this.jumpToProject(newProject.id);
                },
                error: (error) => {
                    // This means that the default values will be applied to the scenes
                    console.error(`Could not generate topics, error: ${error}`);
                },
            });

        // const mockProjectCreationResponse = {
        //   success: true,
        //   projectId: '66281f1de8e840acc0788055',
        // };
        // this.jumpToProject(mockProjectCreationResponse.projectId);
    }

    jumpToProject(projectId: string) {
        if (projectId) {
            this.router.navigate([
                'dashboard',
                {outlets: {panel: ['project', projectId]}},
            ]);
        }
    }

    getPrompts() {
        /// Get the suggestions and video tags
        this.creativeApi
            .getPromptsSuggestions()
            .pipe(
                retry(2), // 3 attempts
                catchError((error) => {
                    console.error('Error occurred:', error);
                    return of(null);
                }),
                finalize(() => {
                })
            )
            .subscribe((prompts) => {
                console.log('Prompts', prompts);
                if (prompts?.length > 0) {
                    this.allPromptSuggestions = prompts;

                    /// Take the video tags from the prompts objects
                    this.videoTags = prompts.map((x) => {
                        return x.tag;
                    });
                    console.log('Tags', this.videoTags);

                    /// Remove the loading
                    this.isLoading = false;
                } else {
                    /// Something went wrong - This should really not happen
                    /// show a message to the user.
                    this.openErrorSnackbar();
                }
            });
    }

    openErrorSnackbar() {
        const snackBar = this._snackBar.open(`🤯 Something went wrong!`, 'Reload', {
            duration: 7000,
        });
        snackBar.onAction().subscribe(() => {
            location.reload();
        });
    }

    ngOnInit(): void {
        this.getPrompts();
        this.getsuggestedDesigns();
    }

    animationCreated(animationItem: AnimationItem, goal: Goal) {
        goal.animation = animationItem;
    }

    playAnimation(goal: Goal) {
        if (goal?.animation) {
            goal.animation.play();
        }
    }

    stopAnimation(goal: Goal) {
        if (goal?.animation) {
            goal.animation.stop();
        }
    }

    stepChanged(event) {
        console.log(event);
        if (event?.selectedStep === this.loadingStep) {
            this.generateFormatSuggestions();
        } else if (event?.selectedStep === this.finalLoading) {
            console.log('final step');
            // this.generateIntroOneLiner();
            this.finalizeFormatAndCopy();
            this.gtmService.notifyEvent('Finished Wizard', {
                videoTag: this.selectedVideoTag?.title ?? '',
                prompt: this.userPrompt ?? '',
                designGgroup: this.selectedDesignGroup?.name ?? '',
                designGroupId: this.selectedDesignGroup?._id,
            });
        }
    }

    backStep() {
        if (this.stepper.selectedIndex > 1) {
            this.stepper.selectedIndex = this.stepper.selectedIndex - 2;
        }
    }

    nextStep(isLastStep?: boolean) {
        if (
            isLastStep &&
            !this.walkthroughPanelService.checkIfTaskCompleted(this.taskType)
        ) {
            this.walkthroughPanelService.specialTaskCompleted$.next(this.taskType);
        }
        this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
    }
}
