<h1
  mat-dialog-title
  [innerHTML]="data.title.htmlContent"
  [style.text-align]="data.title.align"></h1>
<div mat-dialog-content [style.text-align]="data.body.align">
  <p [innerHTML]="data.body.htmlContent"></p>
</div>
<mat-dialog-actions align="center">
  <button [ngClass.lt-sm]="'mobile'"
          *ngFor="let button of data.buttons"
          mat-raised-button
          [color]="button.css.color"
          [ngStyle]="button.css.style"
          (click)="onButtonClick(button)">
    {{ button.text }}
  </button>
</mat-dialog-actions>
