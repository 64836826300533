import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ArtDirectorService } from '../../../../../../services/art-director.service';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { CreativeStatusEnum } from 'src/app/models/defines';
import { ConfigurationService } from '../../../../../../services/configuration.service';
import {
  BehaviorSubject,
  Observable,
  Subject,
  takeUntil,
  throttleTime,
} from 'rxjs';

import {
  animate,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

import { ActivatedRoute, Router } from '@angular/router';
import {
  IProject,
  ScenePropertiesToUpdateEnum,
} from 'src/app/models/project-model';
import { ProjectAuthApiService } from 'src/app/services/api/auth/project-auth-api.service';
import { AnimationItem } from 'lottie-web';
import { IScene } from 'src/app/models/project/scene-model';
import { SceneSetupDialogComponent } from '../../../../../../components/dialogs/scene-setup-dialog/scene-setup-dialog.component';
import {
  AddScenePositionEnum,
  IDesign,
} from '../../../../../../models/design.model';
import TWriter from 't-writer.js';
import {
  DeleteSceneConfirmComponent,
  DeleteSceneOperationEnum,
  IDeleteSceneDialogOutput,
} from '../../../../../../components/dialogs/delete-scene-confirm/delete-scene-confirm.component';
import { TakeApiService } from 'src/app/services/api/auth/projects/take-api.service';
import {
  ITakeCopy,
  ITakeOutDTO,
  ITakeUpdate,
  IUpdateTakePropertie,
} from 'src/app/models/project/take/take-model';
import { TakeConverterService } from '../../../../../../services/project/convertors/take/take-converter.service';
import { AspectRatioEnum } from 'src/app/models/project/edit/edit-model';
import {
  IAddSceneDialogData,
  SceneBankComponent,
} from '../../../../../../components/dialogs/scene-bank/scene-bank.component';
import { ProjectStoreService } from 'src/app/services/state-management/project/project-store.service';
import { AnalyticsNotifierService } from 'src/app/services/utils/analytics-notifier.service';

export enum StoryBoardLayouts {
  GRID = 'layout-grid',
  LIST = 'layout-list',
  SCRIPT = 'layout-script',
}

@Component({
  selector: 'storyboard',
  templateUrl: './storyboard.component.html',
  styleUrls: ['./storyboard.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'void',
        style({
          transform: 'translateY(100%) translateX(-50%)',
        })
      ),
      state(
        'in',
        style({
          transform: 'translateY(0) translateX(-50%)',
        })
      ),
      transition('void => in', animate('300ms ease-out')),
      transition('in => void', animate('300ms ease-in')),
    ]),
    trigger('widthAnimation', [
      transition('* => *', [
        query('.mock-line', [
          style({ width: '0' }),
          stagger(200, [animate('500ms', style({ width: '*' }))]),
        ]),
      ]),
    ]),
  ],
})
export class StoryboardComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  public storyBoardLayouts = StoryBoardLayouts;
  storyboardLayout = StoryBoardLayouts.LIST;
  projectCreativeDone: boolean = false;
  projectCreativeGeneratingImages: boolean = false;
  startAnimation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  projectId: string;
  project: IProject;
  @Input() bitsFormGroup!: FormGroup;
  @Output() sceneTitleChanged = new EventEmitter<{
    name: string;
    sceneId: string;
  }>();
  @Output() structureFinish = new EventEmitter<boolean>();

  displayFirstScene = false;
  displayFirstSceneLottie = false;
  displaySegmentTitles = false;
  lockAll = true;
  assetsLoaded = false;
  lottieAnimationItems = new Map<string, AnimationItem>();
  allLottiesDynamicValuesAreReplaced$ = new Observable<boolean>();
  numberOfSkeletonScenes = [];
  maxScenes = 3;
  CreativeStatus = CreativeStatusEnum;
  currentAspectRatio: AspectRatioEnum;
  scenes: IScene[] = [];
  AddScenePositionEnum = AddScenePositionEnum;
  editScriptLSKey = 'editScript';
  deleteOrHideSceneLSKey = 'deleteOrHideScene';
  editSceneLSKey = 'editScene';
  currentScene: IScene | null = null;
  private typewriterInitialized = false;
  private onDestroy$: Subject<boolean> = new Subject();

  constructor(
    public artDirector: ArtDirectorService,
    public projectAuthApiService: ProjectAuthApiService,
    public config: ConfigurationService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private takeApiService: TakeApiService,
    private takeConvertor: TakeConverterService,
    private projectStoreService: ProjectStoreService,
    private analyticsNotfier: AnalyticsNotifierService
  ) {
    this.projectId = this.route.snapshot.params['id'];

    // this.allLottiesDynamicValuesAreReplaced$ =
    //     this.artDirector.allLottiesDynamicValuesAreReplaced$;
  }

  private _typewriterElement: ElementRef;

  get typewriterElement(): ElementRef {
    return this._typewriterElement;
  }

  @ViewChild('typewriterElement') set typewriterElement(element: ElementRef) {
    if (element && !this.typewriterInitialized) {
      this._typewriterElement = element;

      this.typewriterRendered(); // Call your method or handle the element
      this.typewriterInitialized = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngAfterViewInit(): void {
    this.typewriterRendered();
  }

  ngOnInit(): void {
    this.initProjectAndSkeletonAsync();
  }

  setCurrentScene(scene: IScene): void {
    this.currentScene = scene;
  }

  askToAddScene(position: AddScenePositionEnum): void {
    if (!this.currentScene) return;

    console.log(
      `Adding scene ${position} the current scene with ID: ${this.currentScene.id}`
    );

    const dialogData: IAddSceneDialogData = {
      projectId: this.project.id,
      design: this.project.designGroup.design,
      relativeSceneId: this.currentScene.id,
      relativePosition: position,
      scenes: this.project.scenes,
    };
    this.dialog
      .open(SceneBankComponent, {
        data: {
          ...dialogData,
        },
        disableClose: true,

        minWidth: '300px',
        minHeight: '350px',
        width: '500px',
        maxWidth: '90vw',
      })
      .afterClosed()
      .subscribe(async (res) => {
        if (!res) return;
      });
    // Implement the logic to add the scene before or after the current scene
  }

  openSceneSetup(scene: IScene, design: IDesign) {
    this.dialog
      .open(SceneSetupDialogComponent, {
        data: { scene: scene, design: design, project: this.project },
        minWidth: '300px',
        minHeight: '350px',
        width: '600px',
      })
      .afterClosed()
      .subscribe(async (res) => {
        if (!res) return;
        const take = scene.chosenTake;
        await this.updateTakeSetupAsync(scene, take.id, take.copy);
      });
  }

  askToDelete(scene: IScene) {
    this.dialog
      .open(DeleteSceneConfirmComponent, {
        data: { scene: scene, projectId: this.project.id },
        minWidth: '300px',
        width: '600px',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((response: IDeleteSceneDialogOutput) => {
        if (response?.operation === DeleteSceneOperationEnum.HIDE) {
          scene.isHidden = true;
          /// Todo: update scene to hidden
          // this.updateHiddenScene(scene);
        } else if (response?.operation === DeleteSceneOperationEnum.DELETE) {
          console.log('requested to delete scene');
          /// Todo: delete scene and update the projcet
          this.deleteScene(scene.id);
          const didNotify = localStorage.getItem(this.deleteOrHideSceneLSKey);
          if (didNotify === 'true') {
            return;
          }

          localStorage.setItem(this.deleteOrHideSceneLSKey, 'true');
          this.analyticsNotfier.notifyEvent(`Project Planning Scene Deleted`, {
            projectId: this.project.id,
            sceneId: scene.id,
          });
        }
      });
  }

  public hideScene(scene: IScene) {
    const params = {
      projectId: this.project.id,
      sceneId: scene.id,
      value: !scene.isHidden,
      key: ScenePropertiesToUpdateEnum.IS_HIDDEN,
    };
    this.projectAuthApiService
      .updateSceneProperty$(params)
      .pipe(
        throttleTime(500) // Allow at most 1 request per 500ms
      )
      .subscribe((updatedScene) => {
        if (!updatedScene) return;
        this.projectStoreService.updateSceneProperty(
          params.projectId,
          params.sceneId,
          params.key,
          params.value
        );
      });
  }

  public updateSceneTitle(scene: IScene) {
    const params = {
      projectId: this.project.id,
      sceneId: scene.id,
      value: scene.formControls.title.value,
      key: ScenePropertiesToUpdateEnum.SCENE_TITLE,
    };
    this.projectAuthApiService
      .updateSceneProperty$(params)
      .pipe(
        throttleTime(500) // Allow at most 1 request per 500ms
      )
      .subscribe((updatedScene) => {
        if (!updatedScene) return;
        this.projectStoreService.updateSceneProperty(
          params.projectId,
          params.sceneId,
          params.key,
          params.value
        );
      });
  }

  public updateSceneScript(scene: IScene) {
    const updatedScript = scene.formControls.script.value;
    // Todo: This shows the update locally, we need to take care of any update errors with the api

    scene.copy.script = updatedScript;
    //TODO: Think how to solve this because once we will add more properties like formControls that cannot be parsed as JSON it will cause problems
    const fakeScene: IScene = JSON.parse(JSON.stringify(scene, replacerFunc()));

    // const fakeScene: IScene = JSON.parse(JSON.stringify(safeScene));
    fakeScene.copy.script = updatedScript;
    const params = {
      projectId: this.project.id,
      sceneId: scene.id,
      value: fakeScene.copy,
      key: ScenePropertiesToUpdateEnum.COPY,
    };
    this.projectAuthApiService
      .updateSceneProperty$(params)
      .pipe(
        throttleTime(500) // Allow at most 1 request per 500ms
      )
      .subscribe((updatedScene) => {
        if (!updatedScene) return;
        this.projectStoreService.updateSceneProperty(
          params.projectId,
          params.sceneId,
          params.key,
          params.value
        );

        const didNotify = localStorage.getItem(this.editScriptLSKey);
        if (didNotify === 'true') {
          return;
        }

        localStorage.setItem(this.editScriptLSKey, 'true');
        this.analyticsNotfier.notifyEvent(
          `Project Planning Scene Script Updated`,
          {
            projectId: this.project.id,
            sceneId: scene.id,
            newScript: scene.copy.script,
          }
        );
      });
  }

  generateRandomWidths(count: number): any[] {
    const minWidth = 30;
    const maxWidth = 100;
    const widths = [];
    for (let i = 0; i < count; i++) {
      const randomWidth =
        Math.floor(Math.random() * (maxWidth - minWidth + 1)) + minWidth;
      widths.push({ width: `${randomWidth}%` });
    }
    return widths;
  }

  public recordATake() {
    this.router.navigate(['dashboard', { outlets: { panel: ['take'] } }]);
  }

  public typewriterRendered() {
    if (!this.typewriterElement) {
      console.log(`TYPE WRITER IS THE PROBLEM`);
    }
    if (
      !this.projectCreativeGeneratingImages &&
      !this.projectCreativeDone &&
      this.typewriterElement
    ) {
      const target = this.typewriterElement.nativeElement;
      const writer = new TWriter(target, {
        loop: false,
        deleteSpeed: 80,
        typeColor: 'white',
        cursorColor: 'white',
        typeSpeed: 'random',
        typeSpeedMin: 2,
        typeSpeedMax: 4,
      });

      const words = this.project.scenes[0].copy?.script.split(' ');
      let chain = writer;
      // chain.type(this.copy.value.scriptIntro)
      for (let word of words) {
        const randomRest = Math.floor(Math.random() * 30 + 20);
        chain = chain.type(word + ' ').rest(randomRest);
      }
      chain.start();
    } else {
      console.log('nopeeee');
    }
  }

  // public async openSceneSetupDialog(scene: IScene) {
  //     this.dialog.open(TakeCustomizationDialogComponent, {
  //         minWidth: '300px',
  //         minHeight: '350px',
  //         width: '600px',
  //         panelClass: 'padding-modal',
  //         data: {scene: scene},
  //     });
  // }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  private updateStatusCondition(): void {
    // Evaluate the condition based on the current state of 'project'
    this.projectCreativeDone =
      this.project.creative?.status === CreativeStatusEnum.DONE;

    this.projectCreativeGeneratingImages =
      this.project.creative?.status === CreativeStatusEnum.GENERATING_IMAGES;
  }

  private updateTakeSetupAsync(scene: IScene, takeId: string, copy: ITakeCopy) {
    return new Promise<boolean>((resolve, reject) => {
      const proeprtiesToUpdate: ITakeUpdate<'copy'>[] = [
        {
          key: 'copy',
          value: copy,
        },
      ];
      this.takeApiService
        .updateTake$(this.project.id, scene.id, takeId, proeprtiesToUpdate)
        .subscribe({
          next: async (inTake) => {
            if (!inTake) {
              console.error(
                `Something strange happened while trying to update take.`
              );
              return resolve(false);
            }
            const layout = scene.composition.layouts[0];
            const localTake = await this.takeConvertor.inToLocalAsync(
              inTake,
              null,
              layout,
              this.project.designGroup.design.basePath,
              scene.composition.name,
              false,
              this.project.id,
              scene.id,
              scene.name,
              this.project.indexDBData,
              null
            );
            this.projectStoreService.replaceOrAddProjectTakes(
              this.projectId,
              scene.id,
              localTake
            );
            const didNotify = localStorage.getItem(this.editSceneLSKey);
            if (didNotify === 'true') {
              return;
            }

            localStorage.setItem(this.editSceneLSKey, 'true');
            this.analyticsNotfier.notifyEvent(`Project Planning Scene Edited`, {
              projectId: this.project.id,
              sceneId: scene.id,
            });
          },
        });
    });
  }

  private deleteScene(sceneId: string) {
    console.log('scenes before delete:', this.scenes);
    const sceneIndex = this.scenes.findIndex((scene) => sceneId === scene.id);
    this.scenes.splice(sceneIndex, 1);
    console.log('scenes after delete:', this.scenes);
  }

  private async initProjectAndSkeletonAsync() {
    await this.projectStoreService.setProjectSourceIfNotExistedAsync(
      this.projectId,
      false,
      null
    );

    this.projectStoreService.projectSource$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((updatedProject) => {
        if (!updatedProject) return;

        this.project = updatedProject;
        this.currentAspectRatio =
          this.project.designGroup.design.aspectRatio ?? AspectRatioEnum._16x9;

        // this.scenes.forEach((scene) => {
        //   let selectedTake = scene.takes.find(
        //     (take) => take.id === scene.selectedTakeId
        //   );
        //   const sceneToChangeImage =
        //     selectedTake.copy.dynamicLottieChanges.find(
        //       (dynamicLottieChange) =>
        //         dynamicLottieChange.type === DynamicItemType.IMAGE
        //     );
        //   if (sceneToChangeImage) {
        //     const sceneToCange = updatedProject.scenes.find(
        //       (scene) => scene.id === sceneToChangeImage.id
        //     );

        //     const compositionToChange =
        //       selectedTake.copy.dynamicLottieChanges.find(
        //         (dynamicLottieChanges) =>
        //           dynamicLottieChanges.type === sceneToCange.composition.type
        //       );
        //     if (sceneToCange && compositionToChange) {
        //       selectedTake = sceneToCange.takes[0];
        //     }
        //   }
        // });

        this.scenes = this.project.scenes;

        this.updateStatusCondition();
        if (this.numberOfSkeletonScenes.length >= 1) {
          this.structureFinish.emit();
        }
        let intervalId = setInterval(() => {
          if (this.numberOfSkeletonScenes.length >= this.maxScenes) {
            clearInterval(intervalId);
          } else {
            this.numberOfSkeletonScenes.push(
              this.numberOfSkeletonScenes.length + 1
            );
          }
        }, 4000);
      });
  }
}

const replacerFunc = () => {
  const visited = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (visited.has(value)) {
        return;
      }
      visited.add(value);
    }
    return value;
  };
};
