import { Component, Input, OnInit } from '@angular/core';
import WaveSurfer from 'wavesurfer.js';

@Component({
  selector: 'app-waveform',
  templateUrl: './waveform.component.html',
  styleUrls: ['./waveform.component.scss']
})
export class WaveformComponent implements OnInit {
  @Input() audioUrl: string;
  @Input() height: number = 100;

  waveSurfer: WaveSurfer;

  ngOnInit() {
    this.waveSurfer = WaveSurfer.create({
      container: '#waveform',
      waveColor: '#9a70e5',
      progressColor: 'black',
      height: this.height,
      barGap: 1,
      barHeight: .6,
      barMinHeight: 0,
      barRadius: 2,
      barWidth: 1,
    });

    this.waveSurfer.load(this.audioUrl);
  }
}
