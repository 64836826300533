import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RecordingProgressService {
  private isRecordingInProgressSubject = new BehaviorSubject<boolean>(false);
  public isRecordingInProgress$: Observable<boolean> =
    this.isRecordingInProgressSubject.asObservable();

  constructor() {}

  setRecordingInProgress(isRecording: boolean): void {
    this.isRecordingInProgressSubject.next(isRecording);
  }

  getRecordingInProgressValue(): boolean {
    return this.isRecordingInProgressSubject.value;
  }
}
