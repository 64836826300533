import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-generate-script',
  templateUrl: './generate-script.component.html',
  styleUrls: ['./generate-script.component.scss'],
})
export class GenerateScriptComponent implements OnInit {
  placeholders: any[];


  constructor() {
    // Determine the random count of placeholders (3 or 4)
    const randomCount = Math.floor(Math.random() * 2) + 3;

    // Initialize placeholders with random width percentages
    this.placeholders = Array.from({length: randomCount}, (_, index) => {
      if (index === randomCount - 1) {
        // Last placeholder: width between 30% and 90%
        return {width: `${30 + Math.random() * 60}%`};
      } else {
        // Other placeholders: width between 90% and 100%
        return {width: `${90 + Math.random() * 10}%`};
      }
    });
  }


  ngOnInit(): void {

  }
}
