<div style="height: 10px">
  <mat-progress-bar mode="indeterminate" *ngIf="loadingData"></mat-progress-bar>
</div>

<div class="dashboard-page" autosize #page>
  <full-screen-loading
    [text]="loadingText"
    *ngIf="exporting"></full-screen-loading>

  <div class="main-wrapper">
    <div class="top-part">
      <div>
        <div class="top-section"></div>
        <div class="title-section">
          <h2 class="title">Edit Room</h2>
          <div>
            <button
              mat-stroked-button
              (click)="save()"
              style="margin-right: 10px">
              <mat-icon>save</mat-icon>
              Save
            </button>

            <button
              mat-raised-button
              color="primary"
              [matMenuTriggerFor]="exportMenu"
              [matBadge]="previousExports > 0 ? previousExports : null"
              matBadgePosition="after"
              matBadgeColor="accent"
              [matTooltip]="
                previousExports > 0
                  ? previousExports +
                    ' video' +
                    (previousExports > 1 ? 's' : '') +
                    ' are now ready'
                  : 'Click here to export your video'
              ">
              <mat-icon>ios_share</mat-icon>
              Export
            </button>

            <mat-menu class="exports-menu" #exportMenu="matMenu">
              <div class="exports-wrapper">
                <div (click)="$event.stopPropagation()">
                  <div
                    class="previous-exports-wrapper"
                    *ngIf="previousExports > 0">
                    <div>
                      {{ previousExports }} videos are now ready. Visit the
                      <a (click)="this.gotoExports()">Exports section</a> to
                      view them
                    </div>
                  </div>

                  <label id="export-type-label"><b>Export Quality</b></label>
                  <mat-radio-group
                    aria-labelledby="export-type-label"
                    class="export-type-radio-group"
                    color="primary"
                    [(ngModel)]="chosenExportType">
                    <mat-radio-button
                      class="example-radio-button"
                      *ngFor="let exportType of exportTypes"
                      [value]="exportType.type">
                      <h4>{{ exportType.text }}</h4>
                      <h5>{{ exportType.subtext }}</h5>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>

                <button
                  (click)="showExportDialogAsync()"
                  mat-raised-button=""
                  color="primary"
                  class="export-button">
                  Export
                </button>
              </div>
            </mat-menu>
          </div>
        </div>
      </div>

      <div class="scenes-and-preview">
        <div class="left-tabs">
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="small-icon small-margin-right"
                  >slideshow
                </mat-icon>
                Scenes
              </ng-template>
              <div class="inner-tab scenes-viewer padding-modal">
                <!------ SCENE LIST ------->
                <edit-room-take-list
                  #sceneList
                  [sceneChanged]="this.sceneChanged$ | async"
                  [isPlayingSpecificScene]="
                    currentlyPlayType === PlayType.SPECIFIC_SCENE &&
                    this.isPlaying
                  "
                  [add-play-button]="true"
                  (playSceneEvent)="playSpecificScene($event)"
                  (changeTakeInTimeLine)="updateTakeList($event)">
                </edit-room-take-list>
              </div>
            </mat-tab>
            <!------ SUBTITLES LIST ------->
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="small-icon small-margin-right"
                  >subtitles
                </mat-icon>
                Subtitles
              </ng-template>
              <div
                class="inner-tab"
                style="text-align: center"
                *ngIf="newEditJob">
                <app-subtitles-tab [videosOnTrack]="videosOnTrack$ | async">
                </app-subtitles-tab>
              </div>
            </mat-tab>

            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="small-icon small-margin-right"
                  >music_note
                </mat-icon>
                Background Music
              </ng-template>
              <div class="inner-tab" style="text-align: center">
                <div class="inner-tab scenes-viewer padding-modal">
                  <!------ BG-MUSIC LIST ------->
                  <app-audio-list
                    *ngIf="audiosOntrack$ | async as audios"
                    [cdk-drop-to]="dragVideosTo$ | async"
                    [audios]="audios"
                    [add-play-button]="true"></app-audio-list>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <!-- Lottie And Video Displayer -->
        <div class="preview-wrapper">
          <div
            class="aspect-ratio-box"
            aspectRatioBox
            [aspectRatio]="project?.designGroup?.design?.aspectRatio">
            <div
              class="fake-video-wrap"
              [style.aspect-ratio]="
                project?.designGroup?.design?.aspectRatio ?? '16/9'
              "></div>

            <!--       A Player for timeline takes   -->
            <ng-contrainer
              *ngIf="this.arrayOfComposeConfigs$ | async as composedConfigs">
              <div
                class="video-wrap"
                *ngFor="let composedConfig of composedConfigs"
                [class.hideme]="!composedConfig.playOrNot"
                [style.aspect-ratio]="
                  project.designGroup.design.aspectRatio ?? '16/9'
                ">
                <!-- [lotties-layers]="this.playingLotties$ | async" -->
                <!-- [video-sources]="this.playingVideos$ | async" -->
                <!-- [hidden]="!composedConfig.playOrNot" -->
                <app-lottie-video-composed
                  [compose-configs]="composedConfig.composeConfigs"
                  [playNowOrWait]="composedConfig.playOrNot"
                  [start-time-on-running-edit-take]="
                    this.newFrameOnRunningScene$ | async
                  "
                  [freezeAtFirstFrame]="false"
                  [displayVgControls]="false"
                  [notifyTime]="true"
                  [intervalNotifierTime]="this.intervalNotifierTime"
                  [special-end-time]="this.endTime$ | async"
                  (finished-playing-edit-take)="finishedPlayingScene($event)"
                  [playOrPause]="this.playOrPause$ | async"
                  (started-playing-edit-take)="startedPlayingScene($event)"
                  (currentVideoTime)="newTimeFromPlayer($event)"
                  (currentLottieTime)="newTimeFromPlayer($event)">
                  [subtitles-helper]=" subtitlesHelper ">
                </app-lottie-video-composed>
              </div>
            </ng-contrainer>

            <!--   A Player for Specific Scene Preview  -->
            <div
              class="video-wrap"
              *ngIf="
                this.specificSceneComposeConfigs$ | async as composedConfig
              ">
              <app-lottie-video-composed
                [compose-configs]="composedConfig.composeConfigs"
                [playNowOrWait]="composedConfig.playOrNot"
                [start-time-on-running-edit-take]="
                  this.newFrameOnRunningScene$ | async
                "
                [freezeAtFirstFrame]="false"
                [displayVgControls]="true"
                [notifyTime]="false"
                [special-end-time]="this.endTime$ | async"
                (finished-playing-edit-take)="finishedPlayingScene($event)"
                [playOrPause]="this.playOrPause$ | async"
                (started-playing-edit-take)="startedPlayingScene($event)"
                (currentVideoTime)="newTimeFromPlayer($event)"
                (currentLottieTime)="newTimeFromPlayer($event)">
                <mat-slider>
                  <input
                    matSliderThumb
                    [max]="composedConfig.composeConfigs.endTime"
                    [min]="composedConfig.composeConfigs.startTime"
                /></mat-slider>
              </app-lottie-video-composed>
              <!-- (input)="updateNewFrameOnRunningScene($event)" -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-tracks
      class="timeline-panel"
      [videoEditTakes]="videosOnTrack$ | async"
      [audioEditTakes]="this.audiosOntrack$ | async"
      [isRunning]="this.currentlyPlayingTimeline$ | async"
      [runMarker]="this.runMarker$ | async"
      (dragVideoTo)="dragVideoToInTimelinePanel($event)"
      (updatedVideosOnTrack)="updateVideoList($event)"
      (updateEditTakeFields)="updateEditTakeFields($event)"
      (deleteEditTakeEvent)="deleteEditTake($event)"
      (pauseOrPlay)="timelinePlayOrPause()"
      (newMouseposition)="newMousePoisitionAsync($event)"
      [timeToDisplay]="currentTimeRunningMillis"
      (cutEditTakeEventEmitter)="cutScene($event)"
      (baseWidthChanged)="baseWidthChanged($event)">
    </app-tracks>
  </div>
</div>
