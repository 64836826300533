import {
  ITake,
  ITakeInDTO,
  ITakeWithLottieComposed,
} from './../../../../../../../../../models/project/take/take-model';
import { Component, Input, OnInit } from '@angular/core';
import { ITranscriptSentence } from '../../../../../../../../../models/project/take/transcript-model';
import { subtitleAlignment } from '../../../../../../../../../utils/sequence-alignment';
import { SubtitlesService } from 'src/app/services/show/subtitles.service';
import { EditRoomStateService } from '../../../../edit-room-state.service';

@Component({
  selector: 'app-subtitle-row',
  templateUrl: './subtitle-row.component.html',
  styleUrl: './subtitle-row.component.scss',
})
export class SubtitleRowComponent {
  @Input('subtitle') subtitle: ITranscriptSentence;
  @Input({ required: true, alias: 'take' }) take: ITakeWithLottieComposed;
  @Input({ required: true, alias: 'projectId' }) projectId: string;
  @Input({ required: true, alias: 'sceneId' }) sceneId: string;

  constructor(
    private subtitlesService: SubtitlesService,
    private editRoomStateManager: EditRoomStateService
  ) {}

  public onSubtitleChange(event: Event, subtitle: ITranscriptSentence) {
    console.log(event, subtitle);
    const newSentence = (event.target as HTMLInputElement).value;

    /// Update the words and sentence in the DB
    const alignedWords = subtitleAlignment(subtitle.words, newSentence);
    subtitle.words = alignedWords;
    subtitle.text = newSentence;
    this.subtitlesService.updateSubtitleInDB(
      this.projectId,
      this.sceneId,
      this.take
    );

    this.editRoomStateManager.changeSubtitle();
  }

  hideSubtitle(subtitle: ITranscriptSentence) {
    subtitle.isHidden = !subtitle.isHidden;
    this.subtitlesService.updateSubtitleInDB(
      this.projectId,
      this.sceneId,
      this.take
    );
    this.editRoomStateManager.changeSubtitle();
  }

  onkeydown(event: KeyboardEvent, subtitle: ITranscriptSentence) {
    if (event.key === 'Enter') {
      const target = event.target as HTMLInputElement;
      target.blur();
      event.preventDefault();

      // this.onSubtitleChange(event, subtitle);
    }

    event.stopPropagation();
  }
}
