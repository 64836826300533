import { ISubtitleStyle } from 'subtitles-helper/dist/types';

export enum SubtitlesStylesEnum {
  BOTH = 'Both',
  NORMAL = 'Normal',
  HIGHLIGHTED = 'Highlight',
}

export interface IBasicSubtitleSettings {
  id: string;
  name: string; /// custom name that the user may change and maybe save this styling for other places
  type: SubtitlesStylesEnum;
  style: ISubtitleStyle;
}

export interface ISubtitleSettingsInDTO extends IBasicSubtitleSettings {}

// Local
export interface ISubtitleSettings extends IBasicSubtitleSettings {}

export interface ISubtitleSettingsOutDTO extends IBasicSubtitleSettings {}
