import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';

export interface IButtonCss {
  color: string;
  style: { [_class: string]: any };
}

export interface ITextSettings {
  htmlContent: SafeHtml;
  align: 'left' | 'center' | 'right';
}

export interface IButtonForConfirmationModal {
  text: string;
  css: IButtonCss;
  callback: () => void;
}

export interface IConfirmationModalData {
  title: ITextSettings;
  body: ITextSettings;
  buttons: IButtonForConfirmationModal[];
}

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmationModalData
  ) {}
  onButtonClick(button: IButtonForConfirmationModal): void {
    button.callback();

    this.dialogRef.close(button);
  }
}
