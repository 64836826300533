<full-screen-loading *ngIf="!(pageIsReady$ | async)"></full-screen-loading>
<div *ngIf="pageIsReady$ | async" class="studio-container">
  <div class="top-section">
    <project-title
      [phase]="ProjectStatus.RECORDING"
      fxHide
      fxShow.gt-sm></project-title>
    <div fxShow fxHide.gt-sm>
      <button mat-icon-button (click)="gotToProjectPlanning()">
        <mat-icon>arrow_back_ios</mat-icon>
      </button>
    </div>
    <div class="progress-and-finish">
      <!--            <mat-progress-bar value="40"></mat-progress-bar>-->
      <button
        (click)="ExportAsync()"
        [featureFlag]="{
          features: ['Editroom_access'],
          featureFlagLogic: 'AND',
          featureFlagMode: 'ngIf',
          negativeLogic: true,
        }"
        color="primary"
        mat-raised-button
        matTooltip="Export">
        <mat-icon>movie_filter</mat-icon>
        Export
      </button>
      <button
        (click)="goToEditRoom()"
        [featureFlag]="{
          features: ['shuffllTeamFeature', 'Editroom_access'],
          featureFlagLogic: 'OR',
          featureFlagMode: 'ngIf',
          negativeLogic: false,
        }"
        color="primary"
        mat-raised-button
        matTooltip="Edit Room">
        <mat-icon>video_stable</mat-icon>
        Edit Room
      </button>
    </div>
  </div>
  <app-teleprompter
    [fontSize]="24"
    [hosted]="true"
    (text-changed)="teleprompterTextChanged($event)"></app-teleprompter>
  <div
    *ngIf="(finishedDevicesSetup$ | async) && this.exportProjectModel?.scenes"
    class="story-line-container"
    [class.disabled]="
      (recordingProgressService.isRecordingInProgress$ | async) ||
      (recordingManager.isCountdownInProgress$ | async)
    ">
    <div class="story-line">
      <div class="gradient-transparent"></div>
      <ngx-slick-carousel
        #slickCarousel="slick-carousel"
        (afterChange)="slickAfterChange($event)"
        [config]="storylineSliderConfig"
        class="carousel">
        <div
          *ngFor="
            let scene of this.exportProjectModel.scenes;
            trackBy: trackBySceneId
          "
          [class]="{
            'aspect-169': currentAspectRatio === AspectRatioEnum._16x9,
            'aspect-11': currentAspectRatio === AspectRatioEnum._1x1,
            'aspect-916': currentAspectRatio === AspectRatioEnum._9x16,
          }"
          class="carousel-cell slide"
          ngxSlickItem>
          <div
            *ngIf="scene === currentScene"
            @dynamicScene
            [ngStyle]="{ 'aspect-ratio': currentAspectRatio }"
            [class]="{
              'aspect-916': currentAspectRatio === AspectRatioEnum._9x16,
            }"
            class="dynamic-scene">
            <ng-container *ngIf="currentScene.chosenTake as take">
              <studio-scene-chips
                [showIsHiddenChip]="currentScene.isHidden"
                [showIsPreviewChip]="
                  take.status === TakeStatusEnum.RECORDED &&
                  !!take.lottieComposedConfigs
                "
                [showIsLiveChip]="
                  take.status !== TakeStatusEnum.RECORDED ||
                  !take?.lottieComposedConfigs
                "
                [showIsRecordingChip]="
                  recordingProgressService.isRecordingInProgress$ | async
                "
                [autoHide]="true">
              </studio-scene-chips>

              <!-- Playback -->
              <app-lottie-video-composed
                #playbackScene
                *ngIf="
                  take.status === TakeStatusEnum.RECORDED &&
                  take.lottieComposedConfigs as composedConfig
                "
                [compose-configs]="composedConfig.composeConfigs"
                [displayVgControls]="true"
                [notifyTime]="false"
                [showLoadingIndication]="true"
                [playNowOrWait]="composedConfig.playOrNot"
                [playOrPause]="this.playOrPause$ | async"
                [special-end-time]="null"
                [flipHorizontally]="true"
                [freezeAtFirstFrame]="this.freezeAtFirstFrame"
                (freezedAtFirstFrame)="freezedAtFirstFrame()">
              </app-lottie-video-composed>

              <!-- Live -->
              <studio-live-scene
                *ngIf="
                  take.status !== TakeStatusEnum.RECORDED ||
                  !take?.lottieComposedConfigs
                "
                [scene]="scene"
                [take]="take">
              </studio-live-scene>
            </ng-container>
          </div>

          <studio-static-scene
            *ngIf="scene !== currentScene"
            [scene]="scene"
            class="static-scene"
            [ngStyle]="{ 'aspect-ratio': currentAspectRatio }"
            [designBasePath]="project.designGroup?.design?.basePath">
          </studio-static-scene>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
  <scene-carousel-dots
    [class.disabled]="
      (recordingProgressService.isRecordingInProgress$ | async) ||
      (recordingManager.isCountdownInProgress$ | async)
    "
    (carouselDotClicked)="goToSpecificSceneIndex($event)"
    [currentIndex]="currentSceneIndex"
    [sceneStatusUpdates]="recordingStatusManager.sceneStatusUpdtes$ | async">
  </scene-carousel-dots>
  <studio-buttons-toolbar
    (addTakeButtonClicked)="haveAnotherTakeButtonClicked()"
    (muteToggled)="toggleMuteMicClicked()"
    (recordButtonClicked)="recordButtonClicked()"
    (stopButtonClicked)="stopRecordingButtonClickedAsync(false)"
    (editSceneClicked)="
      openSceneSetupDialog(currentScene, project.designGroup.design)
    "
    (nextSceneClicked)="goToSpecificSceneIndex(currentSceneIndex + 1)"
    [chosenScene]="currentScene"
    [isCoundownInProgress]="recordingManager.isCountdownInProgress$ | async"
    [isRecordingInProgress]="
      recordingProgressService.isRecordingInProgress$ | async
    "></studio-buttons-toolbar>
</div>
