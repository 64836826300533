import {Component, Input, OnInit} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';
import {MediaDevicesService} from 'src/app/services/recording/media-devices.service';

@Component({
  selector: 'app-voice-detector-icon',
  templateUrl: './voice-detector-icon.component.html',
  styleUrls: ['./voice-detector-icon.component.scss']
})
export class VoiceDetectorIconComponent implements OnInit {
  /**
   * Subject used for unsubscribing from observables.
   */
  private destroy$ = new Subject<void>();

  /**
   * Indicates whether voice activity is detected from the microphone.
   */
  isVoiceActive = false;

  @Input('showMicIconConstantly') showMicIconConstantly: boolean = false;

  constructor(private mediaDevicesService: MediaDevicesService) {
  }

  ngOnInit(): void {
    /**
     * Subscribe to the voice activity status from the MediaDevicesService.
     */
    this.mediaDevicesService.isVoiceActive$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isVoiceActive => {
        this.isVoiceActive = isVoiceActive;
      });
  }

  /**
   * Cleans up resources when the component is destroyed.
   */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
