import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/guards/auth.guard';
import { PrivacyComponent } from './pages/public/others/privacy/privacy.component';
import { TermsComponent } from './pages/public/others/terms/terms.component';
import { AlreadyLoggedInGuard } from './services/guards/alreadyLoggedIn.guard';
import { HomepageWpComponent } from './pages/public/homepage-wp/homepage-wp.component';
import { ConnectionErrorPageComponent } from './pages/public/connection-error-page/connection-error-page.component';
import { DashboardComponent } from './pages/private/dashboard/dashboard.component';
import { ProjectPlanningComponent } from './pages/private/dashboard/project/project-planning/project-planning.component';
import { BrandingComponent } from './pages/private/dashboard/branding/branding.component';
import { TeleprompterComponent } from './components/major/teleprompter/teleprompter.component';
import { AiPrompterComponent } from './components/major/teleprompter/ai-prompter/ai-prompter.component';
import { AssetsComponent } from './pages/private/dashboard/assets/assets.component';
import { DiscoverComponent } from './pages/private/dashboard/discover/discover.component';
import { LottieTesterComponent } from './pages/public/lottie-tester/lottie-tester.component';

import { ExportsComponent } from './pages/private/dashboard/exports/exports.component';
import { EditRoomComponent } from './pages/private/dashboard/project/edit-room/edit-room.component';
import { AdminGuard } from './services/guards/admin.guard';
import { OnboardingMobileStepComponent } from './components/minor/onboarding-mobile-step/onboarding-mobile-step.component';
import {
  STUDIO_PRODUCTION_ID_PARAM,
  STUDIO_URL,
} from './pages/private/host/services/shared/constants/client/project/studio.routes';
import { MyLibraryComponent } from './pages/private/dashboard/project/my-library/my-library.component';
import { TakeComponent } from './pages/private/dashboard/project/shared-components/take/take.component';
import { StudioComponent } from './pages/private/dashboard/project/studio/studio.component';
import { PlansPageComponent } from './pages/private/dashboard/plans-and-payments/plans-page/plans-page.component';
import { EDIT_ROOM_URL } from './pages/private/host/services/shared/constants/client/project/edit-room.routes';
import { AssTesterComponent } from './pages/public/ass-tester/ass-tester.component';
import { NewProjectComponent } from './pages/private/dashboard/project/new-project/new-project.component';
import { PaymentsGuard } from './services/guards/payments.guard';

const routes: Routes = [
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms', component: TermsComponent },
  {
    path: 'host/:id',
    redirectTo: '/dashboard/(panel:library)',
  },

  {
    path: 'onboarding',
    loadChildren: () =>
      import('./modules/onboarding/onboarding.module').then(
        (m) => m.OnboardingModule
      ),
  },

  {
    path: 'onboarding/mobile',
    canActivate: [AuthGuard],
    component: OnboardingMobileStepComponent,
  },
  {
    path: 'dashboard',
    // canActivate: [AuthGuard, WelcomeGuard],
    canActivate: [AuthGuard],

    component: DashboardComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/dashboard/(panel:library)',
      },
      {
        path: STUDIO_URL + `/:${STUDIO_PRODUCTION_ID_PARAM}`,
        canActivate: [AuthGuard, PaymentsGuard],
        component: StudioComponent,
        outlet: 'panel',
      },
      {
        path: 'production',
        component: ProjectPlanningComponent,
        pathMatch: 'full',
        outlet: 'panel',
      },
      {
        path: 'plans',
        component: PlansPageComponent,
        pathMatch: 'full',
        outlet: 'panel',
      },
      {
        path: 'library',
        component: MyLibraryComponent,
        pathMatch: 'full',
        outlet: 'panel',
      },
      {
        path: 'new',
        component: NewProjectComponent,
        pathMatch: 'full',
        outlet: 'panel',
      },

      {
        path: 'take',
        component: TakeComponent,
        pathMatch: 'full',
        outlet: 'panel',
      },
      {
        path: 'project/:id',
        component: ProjectPlanningComponent,
        pathMatch: 'full',
        outlet: 'panel',
      },
      {
        path: `${EDIT_ROOM_URL}/:${STUDIO_PRODUCTION_ID_PARAM}`,
        component: EditRoomComponent,
        pathMatch: 'full',
        outlet: 'panel',
      },
      {
        path: 'exports/:id',
        component: ExportsComponent,
        pathMatch: 'full',
        outlet: 'panel',
      },
      {
        path: 'discover',
        component: DiscoverComponent,
        pathMatch: 'full',
        outlet: 'panel',
      },
      {
        path: 'assets',
        component: AssetsComponent,
        pathMatch: 'full',
        outlet: 'panel',
      },
      {
        path: 'branding',
        component: BrandingComponent,
        pathMatch: 'full',
        outlet: 'panel',
      },
      {
        path: 'backoffice',
        outlet: 'panel',

        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () =>
          import('./modules/admin/admin.module').then((m) => m.AdminModule),
      },
    ],
  },

  {
    path: 'participate/:hostUsername',
    redirectTo: '/dashboard/(panel:library)',
  },
  {
    path: 'join/:hostUsername',
    redirectTo: '/dashboard/(panel:library)',
  },

  { path: 'hoster', component: AssTesterComponent },
  { path: 'lottie', component: LottieTesterComponent },

  {
    path: 'login',
    canActivate: [AlreadyLoggedInGuard],
    loadChildren: () =>
      import('./modules/sign-in/sign-in.module').then((m) => m.SignInModule),
  },
  {
    path: 'signin', // Alias for login
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'register', // Another alias for login
    redirectTo: 'login',
    pathMatch: 'full',
  },

  // {path: 'feed', component: FeedComponent},
  { path: 'maintenance', component: ConnectionErrorPageComponent },
  { path: 'prompter', component: TeleprompterComponent },
  { path: 'ai-prompter', component: AiPrompterComponent },

  { path: '', component: HomepageWpComponent }, // {path: '', component: MainComponent},
  // Social media auth - needed for redirects
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
