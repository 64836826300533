import {
  CdkDragDrop,
  CdkDragEnd,
  CdkDragMove,
  CdkDragStart,
  copyArrayItem,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  IEditAudioScene,
  IEditVideoScene,
} from 'src/app/models/job/edit-job-schema';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ResizeEvent } from 'angular-resizable-element';
import { ScenesStylesService } from 'src/app/services/styles/scenes-styles.service';
import { BehaviorSubject, combineLatest, find, map, take } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ChangeVideoListType,
  EditRoomUtilityFunctions,
  IChangeVideos,
} from '../services/edit-room-utility-functions';
import {
  IAudioEditTake,
  ICommonLocalEditTake,
  IVideoEditTake,
} from 'src/app/models/project/edit/edit-model';
import { FunctionsHelperService } from 'src/app/services/functions-helper.service';
import { SceneSetupDialogComponent } from '../../../../../../components/dialogs/scene-setup-dialog/scene-setup-dialog.component';
import { EditRoomStateService } from '../edit-room-state.service';

export interface ISelectedSceneAndMarkerTime {
  selectedScene: ICommonLocalEditTake;
  markerTime: number;
}

@Component({
  selector: 'app-tracks',
  templateUrl: './tracks.component.html',
  styleUrls: ['./tracks.component.scss'],
})
export class TracksComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('ruler')
  ruler: ElementRef<HTMLElement>;
  @ViewChild('markerPosition')
  markerDiv: ElementRef<HTMLElement>;
  @ViewChild('tracksWrapper') tracksWrapper: ElementRef;
  videoTrackerId: string = 'scenes';
  baseWidth = 100;
  @Output()
  baseWidthChanged = new EventEmitter<number>();
  @Input('videoEditTakes')
  videoEditTakes: IVideoEditTake[] = [];
  @Input('audioEditTakes')
  audioEditTakes: IAudioEditTake[] = [];
  newTimelineDuration$ = new BehaviorSubject<number>(null);
  /// Indicates wehether it's only single scene from scene list nor the track from this component
  @Input()
  isRunning: boolean;
  isRunning$ = new BehaviorSubject<boolean>(false);
  /// Using run marker because in middle of moving scenes it is loading and the marker cannot move at this point .
  @Input()
  runMarker: boolean;
  runMarker$ = new BehaviorSubject<boolean>(false);
  @Output()
  pauseOrPlay = new EventEmitter<boolean>();
  @Output()
  dragVideoTo = new EventEmitter<string>();
  @Output()
  updatedVideosOnTrack = new EventEmitter<IChangeVideos>();
  @Output()
  updatedAudiosOnTrack = new EventEmitter<IEditAudioScene[]>();
  @Output()
  updateEditTakeFields = new EventEmitter<ICommonLocalEditTake>();
  @Output()
  deleteEditTakeEvent = new EventEmitter<ICommonLocalEditTake>();
  /// sending the marker position and selcted scene to validate he is in the selected scene .
  @Output()
  cutEditTakeEventEmitter = new EventEmitter<ISelectedSceneAndMarkerTime>();
  TICKS_PER_NUMBER = 5;
  selectedScene: ICommonLocalEditTake;
  subtitleTrackHeight: number = 28;

  // @Output()
  // selectedScene = new EventEmitter<IBasicEditScene>();
  videoTrackHeight: number = 64;
  audioTrackHeight: number = 34;
  isMouseEventThrottled: boolean = false;
  mouseposition: number = 0;
  mouseposition$ = new BehaviorSubject<number>(null);
  @Output()
  newMouseposition = new EventEmitter<number>();
  @Output()
  // markerPositionNotifier = new EventEmitter<number>();
  // markerPosition$ = new BehaviorSubject<number>(0);
  markerIntervalTime = 250;
  @Input()
  timeToDisplay: number = 0;
  currentTime: number = 0;
  followLineTime: number = 0;
  // currentTime$ = new BehaviorSubject<number>(0);
  followLineTime$ = new BehaviorSubject<number>(0);
  showFollowLineParent: boolean;
  hideFollowLineChildren: boolean;
  baseCdnUrl: string;
  maxDuration: number;
  intervalId: any;
  ///
  toUpdatePreviewOnDragging: boolean = true;
  /// Using isDraggingMode to let the cdk do let the re-positioning of the marker
  isDraggingMode: boolean = false;
  /// Saving the state of the marker position to set him after the dragging (reseting the transformX from the cdkDrag)
  markerPositionAtEndOfDragging: number;
  screenWidth: number;
  maxDurationToDisplayOnTimeline: number[] = [];
  timelineSections = [];

  constructor(
    private snackBar: MatSnackBar,
    private config: ConfigurationService,
    private dialog: MatDialog,
    private stylesService: ScenesStylesService,
    private functionsHelper: FunctionsHelperService,
    public editRoomStateService: EditRoomStateService
  ) {
    this.baseCdnUrl = this.config.baseCdnUrl;
  }

  @HostListener('document:keydown.space', ['$event'])
  onSpacebarPressed(event: KeyboardEvent): void {
    this.pauseOrPlayPressed();
  }

  @HostListener('document:keydown.delete', ['$event'])
  onDeletePressed(event: KeyboardEvent): void {
    this.deleteScene();
  }

  ngAfterViewInit(): void {
    // this.editRoomStateService.markerPosition$.next(1);

    this.initializeWidths();
  }

  ngOnInit(): void {
    this.dragVideoTo.emit(this.videoTrackerId);
    this.screenWidth = screen.width;
    if (!(this.videoEditTakes?.length > 0)) {
      this.newTimelineDuration$
        .pipe(find((duration) => duration > 0))
        .subscribe((duration) => {
          if (!duration) return; // In case the Observable completes without finding a value

          const fullVideoDuration = this.videoEditTakes
            .map((editTake) => editTake.duration)
            .reduce((duration, currentValue) => duration + currentValue, 0);

          const fullAudioDuration = this.audioEditTakes
            .map((editTake) => editTake.duration)
            .reduce((duration, currentValue) => duration + currentValue, 0);

          const fullDuration =
            fullVideoDuration > fullAudioDuration
              ? fullVideoDuration
              : fullAudioDuration;

          /// Int representation of the number of seconds
          const videoInSeconds = Math.ceil(fullDuration / 1000);
          this.screenWidth = window.innerWidth;

          this.baseWidth = this.screenWidth / videoInSeconds;
          console.log('New duration - set base width', this.baseWidth);

          /// We can also call zoom once insted
          this.baseWidthChanged.emit(this.baseWidth);
        });
    }

    this.editRoomStateService.currentTime$.subscribe((currentTimeToDisplay) => {
      if (typeof currentTimeToDisplay !== 'number') {
        return;
      }
      this.currentTime = currentTimeToDisplay;
    });
    this.followLineTime$.subscribe((timeToDisplay) => {
      if (typeof timeToDisplay !== 'number') {
        return;
      }
      this.followLineTime = timeToDisplay;
    });

    this.mouseposition$.subscribe((mousePosition) => {
      if (typeof mousePosition !== 'number') return;

      console.log(`[mouseClickTracks]: Clicked at ${mousePosition}}`);
      // this.mouseposition = mousePosition;
      this.newMouseposition.emit(mousePosition);
    });

    const combinedSubjects$ = combineLatest([this.runMarker$, this.isRunning$]);

    combinedSubjects$
      .pipe(
        map(async ([runMarker, isRunning]) => {
          if (!runMarker || !isRunning) {
            if (this.intervalId) {
              clearInterval(this.intervalId);
            }
            return;
          }
          this.intervalId = setInterval(() => {
            const currentPosition =
              this.editRoomStateService.markerPosition$.value;
            const distancePerInterval = this.baseWidth / 1000; // Adjust based on your requirements
            this.editRoomStateService.markerPosition$.next(
              currentPosition + distancePerInterval
            );
          }, this.markerIntervalTime);
        })
      )
      .subscribe();
  }

  onDropVideo(event: CdkDragDrop<IEditVideoScene[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        this.videoEditTakes,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      const clonedItem = this.functionsHelper.deepClone(
        event.previousContainer.data[event.previousIndex]
      );
      clonedItem.sceneId = uuidv4();
      event.container.data.splice(event.currentIndex, 0, clonedItem);
      // Update take position
      // this.videoScenes.forEach((scene, index) => {
      //   scene.take.position = index;
      // });
    }
    const changeVideos: IChangeVideos = {
      newEditVideos: this.videoEditTakes,
      changeType: ChangeVideoListType.ORDER,
    };
    this.updatedVideosOnTrack.emit(changeVideos);
  }

  onDropAudio(event: CdkDragDrop<IEditAudioScene[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        this.audioEditTakes,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      const clonedItem = this.functionsHelper.deepClone(
        event.previousContainer.data[event.previousIndex]
      );
      ///TODO: fix this .
      // this.videoScenes.forEach((scene, index) => {
      //   scene.take.position = index;
      // });
      copyArrayItem(
        [clonedItem],
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    this.updatedAudiosOnTrack.emit(this.audioEditTakes);
  }

  mouseOverTracks(event: MouseEvent) {
    if (!this.isMouseEventThrottled) {
      this.isMouseEventThrottled = true;
      requestAnimationFrame(() => {
        const offsetX =
          event?.clientX - this.ruler.nativeElement.getBoundingClientRect().x;
        if (offsetX) {
          this.mouseposition = offsetX;
          this.followLineTime = (offsetX / this.baseWidth) * 1000;

          // this.currentTime$.next((event.offsetX / this.baseWidth) * 1000);
          // this.currentTime = (event.offsetX / this.baseWidth) * 1000;
          this.isMouseEventThrottled = false;
        }
      });
    }
  }

  mouseLeavesTrack() {
    this.showFollowLineParent = false;
    this.editRoomStateService.currentTime$.next(this.timeToDisplay);
  }

  mouseEntersTrack() {
    this.showFollowLineParent = true;
  }

  mouseClickTracks(event: MouseEvent) {
    const offsetX =
      event?.clientX - this.ruler.nativeElement.getBoundingClientRect().x;
    if (offsetX) {
      this.setMousePosition(offsetX);
    }
  }

  private setMousePosition(offsetX: number) {
    const position = (offsetX / this.baseWidth) * 1000;
    this.mouseposition$.next(position);
    this.editRoomStateService.markerPosition$.next(offsetX);
    return;
  }

  onMarkerDragged(event: CdkDragMove) {
    // Access the current drag position information
    const trackerStartFrom = this.ruler.nativeElement.getBoundingClientRect();
    const offsetX = event.pointerPosition.x - trackerStartFrom.x; // X-coordinate
    // ((event.pointerPosition.x - trackerStartFrom.x) * 100) / this.baseWidth; // X-coordinate
    this.markerPositionAtEndOfDragging = offsetX;
    if (this.toUpdatePreviewOnDragging) {
      this.mouseposition$.next(null);

      /// Timeout for not spamming the update preview event
      this.toUpdatePreviewOnDragging = false;
      setTimeout(() => {
        this.toUpdatePreviewOnDragging = true;
      }, 300);

      const position = (offsetX / this.baseWidth) * 1000;
      this.mouseposition$.next(position);
      // this.markerPosition$.next(offsetX);
    }
  }

  onMarkedEnded(event: CdkDragEnd) {
    console.log('wtf?', event);

    this.editRoomStateService.markerPosition$.next(
      this.markerPositionAtEndOfDragging
    );
    event.source._dragRef.reset();

    this.isDraggingMode = false;
  }

  onMarkedDragStarted(event: CdkDragStart) {
    clearInterval(this.intervalId);

    this.isDraggingMode = true;
  }

  selectScene(scene: ICommonLocalEditTake) {
    console.log('new selected scene', scene);
    this.selectedScene = scene;
  }

  editScene(scene: IVideoEditTake) {
    this.dialog
      .open(SceneSetupDialogComponent, {
        minHeight: 'fit-content',
        data: scene,
      })
      .afterClosed()
      .subscribe((result: IVideoEditTake) => {
        if (result) {
          this.updateEditTakeFields.emit(result);
        }
        this.newTimelineDuration$.next(this.getFullDuration());
      });
  }

  validateResize(event: ResizeEvent): boolean {
    const MIN_DIMENSIONS_PX: number = 50;
    const isWidth = event.rectangle.width < MIN_DIMENSIONS_PX;
    const isHeight = event.rectangle.height < MIN_DIMENSIONS_PX;
    if (
      event.rectangle.width &&
      event.rectangle.height &&
      (isWidth || isHeight)
    ) {
      return false;
    }
    return true;
  }

  getMaxDuration(unit: 'sec' | 'px' | 'arr'): number[] {
    const fullDuration = this.getFullDuration();
    console.log('fullDuration', fullDuration);

    if (!fullDuration) return [10, 20, 30];

    const maxLength = Math.round(fullDuration) / 1000; // Round to the nearest integer
    if (unit === 'sec') {
      return [maxLength];
    } else if (unit === 'px') {
      return [maxLength * this.baseWidth];
    } else if (unit === 'arr') {
      // Add some more cells just in case
      // maxLength = maxLength*2
      this.maxDuration = maxLength;
      console.log('maxDuration', this.maxDuration);
      return Array.from(
        { length: Math.ceil(maxLength / this.TICKS_PER_NUMBER) },
        (_, index) => (index + 1) * 5
      );
    }
  }

  /// Change this to a property ! it's getting spammed in the html .
  getFullDuration() {
    const videoDuration = this.videoEditTakes?.reduce((total, scene) => {
      const sceneDuration = scene.durationOnTrack;
      return total + sceneDuration;
    }, 0);

    const audioDuration = this.audioEditTakes?.reduce((total, scene) => {
      const sceneDuration = scene.durationOnTrack;
      return total + sceneDuration;
    }, 0);

    return Math.max(videoDuration ?? 0, audioDuration ?? 0);
  }

  pauseOrPlayPressed() {
    this.pauseOrPlay.emit(!this.isRunning);
  }

  deleteScene() {
    if (!this.selectScene) {
      console.warn(`No scene was selected to remove from timeline panel.`);
      return;
    }
    this.deleteEditTakeEvent.emit(this.selectedScene);
  }

  cutScene() {
    if (!this.selectedScene) {
      console.warn(`Select a scene first to cut`);
      this.snackBar.open(`Please select a scene first .`, 'Dismiss', {
        duration: 2000,
      });
      return;
    }
    const markerInTime =
      EditRoomUtilityFunctions.convertMarkerPositionToMilliseconds(
        this.editRoomStateService.markerPosition$.value,
        this.baseWidth
      );
    const sceneAndMarkerTime: ISelectedSceneAndMarkerTime = {
      selectedScene: this.selectedScene,
      markerTime: markerInTime,
    };
    this.cutEditTakeEventEmitter.emit(sceneAndMarkerTime);
  }

  /// TODO: Resize animation limit
  resizing(event: any) {}

  //** Utils */
  onResizeEnd = (event: { scene: ICommonLocalEditTake; data: ResizeEvent }) => {
    const { scene, data } = event;
    if (!scene || !data?.edges) {
      return;
    }

    if (data.edges.right !== undefined) {
      this.handleRightEdgeMove(scene, data.edges.right as number);
    } else if (data.edges.left !== undefined) {
      this.handleLeftEdgeMove(scene, data.edges.left as number);
    }

    this.updateEditTakeFields.emit(scene);
    this.newTimelineDuration$.next(this.getFullDuration());
  };

  zoom(inOut: number) {
    this.baseWidth = this.baseWidth * inOut;
    this.baseWidthChanged.emit(this.baseWidth);
    this.editRoomStateService.markerPosition$.next(
      this.editRoomStateService.markerPosition$.value / this.baseWidth
    );
    this.videoEditTakes.forEach((scene) => {
      this.stylesService.adjustSceneWidth(scene, this.baseWidth);
    });
    const changeVideos: IChangeVideos = {
      newEditVideos: this.videoEditTakes,
      changeType: ChangeVideoListType.ZOOM,
    };
    this.updatedVideosOnTrack.emit(changeVideos);
    this.audioEditTakes.forEach((scene) => {
      this.stylesService.adjustSceneWidth(scene, this.baseWidth);
    });
    this.updatedAudiosOnTrack.emit(this.audioEditTakes);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['runMarker']) {
      this.runMarker$.next(this.runMarker);
    }
    if (changes['isRunning']) {
      this.isRunning$.next(this.isRunning);
    }
    if (changes['videoEditTakes'] || changes['audioEditTakes']) {
      this.newTimelineDuration$.next(this.getFullDuration());
      // this.maxDurationToDisplayOnTimeline = this.getMaxDuration('arr');
      this.timelineSections = this.getMaxDuration('arr');
      this.initializeWidths();
    }
    if (changes['timeToDisplay']) {
      this.editRoomStateService.currentTime$.next(this.timeToDisplay);
      const nextMarkerPosition =
        EditRoomUtilityFunctions.convertMillisecondsToMarkerPosition(
          this.timeToDisplay,
          this.baseWidth
        );
      if (!this.isDraggingMode) {
        this.editRoomStateService.markerPosition$.next(nextMarkerPosition);
      }
    }
  }

  private initializeWidths(): void {
    this.videoEditTakes.forEach((scene) => {
      this.stylesService.adjustSceneWidth(scene, this.baseWidth);
    });
    this.audioEditTakes.forEach((scene) => {
      this.stylesService.adjustSceneWidth(scene, this.baseWidth);
    });
  }

  private handleRightEdgeMove(scene: ICommonLocalEditTake, moveValue: number) {
    const movedInTime = (moveValue / this.baseWidth) * 1000;
    let newTrimEnd = scene.trimEnd - movedInTime;

    if (newTrimEnd >= scene.duration) {
      console.warn(
        `Could not trim end because trimming is longer than video duration.`
      );
      return;
    }

    newTrimEnd = Math.max(newTrimEnd, 0);

    if (EditRoomUtilityFunctions.isEditVideoScene(scene)) {
      scene.trims.videoTrims.end = newTrimEnd;
      scene.trims.lottieTrims.end = newTrimEnd;
    }
  }

  private handleLeftEdgeMove(scene: ICommonLocalEditTake, moveValue: number) {
    const movedInTime = (moveValue / this.baseWidth) * 1000;
    const newTrimStart = Math.max(scene.trimStart + movedInTime, 0);

    if (newTrimStart >= scene.duration) {
      console.warn(
        `Could not trim start because trimming is longer than video duration.`
      );
      return;
    }

    if (EditRoomUtilityFunctions.isEditVideoScene(scene)) {
      scene.trims.videoTrims.start = newTrimStart;
      scene.trims.lottieTrims.start = 0;
    }
  }
}
