import { Injectable } from '@angular/core';
import {
  IScene,
  ISceneConfigs,
  ISceneFormControl,
  ISceneInDTO,
  ISceneOutDTO,
  Scene,
} from 'src/app/models/project/scene-model';
import {
  ITake,
  ITakeOutDTO,
  ITakeWithLottieComposed,
  Take,
} from 'src/app/models/project/take/take-model';
import { TakeConverterService } from './take/take-converter.service';
import { IConvertorAsync } from 'src/app/models/project-model';
import { ICustomLayout, ISceneAndEditTakes } from 'src/app/models/defines';
import { IBasicLottieLayer } from 'src/app/models/lottie/lottie-defines';
import { FormControl } from '@angular/forms';
import { IProjectIndexData } from 'src/app/services/state-management/project/shared-project-indexdb.service';
import { IComposeAndPlayOrNot } from '../../../models/job/editor-defines';
import {
  ITrimLayers,
  IVideoEditTake,
} from '../../../models/project/edit/edit-model';
import { VideoEditTake } from '../../../models/job/edit-job-schema';

@Injectable({
  providedIn: 'root',
})
export class SceneConverterService
  implements IConvertorAsync<ISceneInDTO, IScene, ISceneOutDTO>
{
  constructor(private takeConvertor: TakeConverterService) {}

  async inToLocalAsync(
    inScene: ISceneInDTO,
    baseDesignPath: string,
    waitForLocalVideos: boolean,
    projectId: string,
    projectDBData: IProjectIndexData,
    streamId?: string
  ) {
    if (!inScene) {
      console.error(
        `Cannot convert scene  to out dto scene because it's null.`
      );
      return;
    }
    const basicLottieLayers: IBasicLottieLayer[] =
      inScene.composition.layouts.map((layout) => {
        const basicLottieLayer: IBasicLottieLayer = {
          lottieId: layout._id,
          lottieJsonPath: layout.lottiePath,
        };
        return basicLottieLayer;
      });

    const takes = await Promise.all(
      inScene.takes.map((inTake) =>
        this.takeConvertor.inToLocalAsync(
          inTake,
          basicLottieLayers,
          inScene.composition.layouts[0],
          baseDesignPath,
          inScene.composition.name,
          waitForLocalVideos,
          projectId,
          inScene.id,
          inScene.name,
          projectDBData,
          streamId
        )
      )
    );
    let selectedTakeId = inScene.selectedTakeId;
    let chosenTake: ITake;
    /// If a selected take was not initialized, we take the last take in the array
    if (!selectedTakeId) {
      chosenTake = takes[takes.length - 1];
      selectedTakeId = chosenTake.id;
    } else {
      chosenTake = takes.find((take) => take.id === selectedTakeId);
    }

    const sceneFormControls: ISceneFormControl = {
      script: new FormControl<string>(inScene.copy.script),
      title: new FormControl<string>(inScene.name),
    };

    const scene: ISceneConfigs = {
      id: inScene.id,
      composition: inScene.composition,
      takes: takes,
      selectedTakeId: selectedTakeId,
      copy: inScene.copy,
      chosenTake: chosenTake,
      isHidden: inScene.isHidden,
      name: inScene.name,
      formControls: sceneFormControls,
      status: inScene.status,
      stagePositions: inScene.stagePositions,
    };

    const newScene = new Scene(scene);

    return newScene;
  }

  localToOut(scene: IScene) {
    if (!scene) {
      console.error(
        `Cannot convert scene  to out dto scene because it's null.`
      );
      return;
    }
    const outDTOTakes: ITakeOutDTO[] = scene.takes?.map((take) =>
      this.takeConvertor.localToOut(take)
    );
    const outDTOScene: ISceneOutDTO = {
      id: scene.id,
      composition: scene.composition,
      takes: outDTOTakes ?? [],
      selectedTakeId: scene.selectedTakeId,
      isHidden: scene.isHidden,
      name: scene.composition.name,
      copy: scene.copy,
      status: scene.status,
      stagePositions: scene.stagePositions,
    };
    return outDTOScene;
  }

  public getCustomLayout(
    customLayouts: ICustomLayout[],
    customLayoutId: string
  ) {
    if (!customLayoutId) {
      return null;
    }

    return customLayouts.find(
      (customLayout) => customLayout.name === customLayoutId
    );
  }
}
