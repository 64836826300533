import {Component, OnInit} from '@angular/core';
import {ConfigurationService} from 'src/app/services/configuration.service';
import {ProjectAuthApiService} from '../../../services/api/auth/project-auth-api.service';
import {
    SnackBarService,
    SnackbarActionEnum,
} from 'src/app/services/utils/snack-bar.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FeedbackDialogComponent} from '../feedback-dialog/feedback-dialog.component';
import {FeedbackEnum} from 'src/app/models/defines';
import {ToastrService} from 'ngx-toastr';
import {FeedbacksService} from 'src/app/services/utils/feedbacks.service';

@Component({
    selector: 'finish-recording-dialog',
    templateUrl: './finish-recording-dialog.component.html',
    styleUrls: ['./finish-recording-dialog.component.scss'],
})
export class FinishRecordingDialogComponent implements OnInit {
    congratsLottieAnimation = '/files/lottie-assets/congrats-animation.json';
    assetBasePath: string;

    isLoading$ = this.projectAuthApiService.isLoadingExportData$;

    constructor(
        private config: ConfigurationService,
        public projectAuthApiService: ProjectAuthApiService,
        private dialogRef: MatDialogRef<FinishRecordingDialogComponent>,
        private snackBarService: SnackBarService,
        private dialog: MatDialog,
        private toastr: ToastrService,
        private feedbacksService: FeedbacksService
    ) {
        this.assetBasePath = this.config.baseCdnUrl;
    }

    ngOnInit(): void {
        const isFeedbackSubmited =
            this.feedbacksService.getIsFeedbackSucmitedFromLocaStorage();

        if (isFeedbackSubmited !== 'true') {
            const toastRef = this.toastr.info(
                '30 seconds to be a hero! Help us out with your feedback!<br><b><u> Click here<b><u>',
                'Feedback',
                {
                    disableTimeOut: true,
                    tapToDismiss: false,
                    closeButton: true,
                }
            );
            toastRef.onTap.subscribe(() => {
                const feedbackDialog = this.dialog.open(FeedbackDialogComponent, {
                    width: '500px',
                    height: '450px',
                    data: {
                        feedbackFormId: FeedbackEnum.EXPORT_FEEDBACK,
                    },
                });
                feedbackDialog.afterClosed().subscribe((res) => {
                    if (res === true) {
                        this.toastr.clear();
                    }
                });
            });
        }
    }

    closeDialog() {
        if (this.isLoading$.value) {
            this.snackBarService.openMessage(
                `Please try again in 10 seconds`,
                SnackbarActionEnum.Close,
                5000
            );
            return;
        }

        this.dialogRef.close();
    }
}
