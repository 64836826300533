import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { MissingArgumentsError } from '../../models/errors/general.errors';

@Injectable({
  providedIn: 'root',
})
export class DbService {
  private databases: Map<string, Dexie> = new Map();

  constructor() {}

  async openDatabaseAsync(
    name: string,
    schema: { [key: string]: string },
    version: number = 1
  ): Promise<Dexie> {
    // Check if the database is already cached in memory
    if (this.databases.has(name)) {
      return this.databases.get(name)!;
    }

    // Check if the database exists in IndexedDB with the desired version
    const existingDBs = await Dexie.getDatabaseNames();

    if (existingDBs.includes(name)) {
      const tempDb = new Dexie(name);
      await tempDb.open(); // Open the database to inspect its version

      if (tempDb.verno === version) {
        this.databases.set(name, tempDb);
        console.log(`NOT OPENING NEW ONE`);
        return tempDb; // Return the existing database with the matching version
      }

      // If the version doesn't match, close the temporary database
      tempDb.close();
    }

    // Create and open a new database if it doesn't exist or the version doesn't match
    const db = new Dexie(name);
    db.version(version).stores(schema);
    await db.open();
    this.databases.set(name, db);
    return db;
  }
  closeDatabase(name: string): void {
    if (!this.databases.has(name)) return;

    this.databases.get(name)!.close();
    this.databases.delete(name);
  }

  async updateRecordAsync<T>(
    dbName: string,
    tableName: string,
    key: string | number,
    updates: Partial<T>
  ): Promise<void> {
    const db = this.databases.get(dbName);
    if (!db) {
      throw new Error(`Database ${dbName} is not open`);
    }

    await db.table(tableName).update(key, updates);
  }

  async addRecordAsync<T>(
    dbName: string,
    tableName: string,
    record: T
  ): Promise<void> {
    const db = this.databases.get(dbName);
    if (!db) {
      throw new Error(`Database ${dbName} is not open`);
    }

    await db.table(tableName).add(record);
  }

  async getTableDataAsync<T>(dbName: string, tableName: string): Promise<T[]> {
    const db = this.databases.get(dbName);
    if (!db) {
      throw new Error(`Database ${dbName} is not open`);
    }

    return db.table(tableName).toArray();
  }

  /**
   * Deletes all IndexedDB databases that start with the specified prefix,
   * except those that are included in the `skipOn` list. The deletion
   * operations are performed concurrently.
   *
   * @param {string} startsWith - The prefix that the database names should start with to be considered for deletion.
   * @param {string | string[]} skipOn - A database name or an array of database names to exclude from deletion.
   * @returns {Promise<void>} - A promise that resolves when all eligible databases have been deleted.
   * @throws {MissingArgumentsError} - Throws an error if `startsWith` is not provided.
   */
  async deleteDatabasesAsync(
    startsWith: string,
    skipOn: string | string[]
  ): Promise<void> {
    if (!startsWith) {
      throw new MissingArgumentsError(
        `Cannot delete databases because 'startsWith' is null or undefined`
      );
    }

    // Ensure skipOn is always an array
    // The filter(Boolean) method ensures that if skipOn is undefined or an empty string,
    // it will be filtered out, resulting in an empty array.
    const skipList = Array.isArray(skipOn) ? skipOn : [skipOn].filter(Boolean);

    const allDatabases = await Dexie.getDatabaseNames();
    const matchDatabases = allDatabases.filter((dbName) =>
      dbName.startsWith(startsWith)
    );

    // Create an array of promises to delete the databases concurrently
    const deletePromises = matchDatabases.map(async (dbName) => {
      if (skipList.includes(dbName)) {
        console.log(`Skipped deleting database: ${dbName}`);
        return; // Skip the deletion of this database
      }

      this.closeDatabase(dbName);
      await Dexie.delete(dbName);
      console.log(`Deleted database: ${dbName}`);
    });

    // Wait for all deletions to complete
    await Promise.all(deletePromises);
  }
}
