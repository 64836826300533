import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MissingArgumentsError } from 'src/app/models/errors/general.errors';

import {
  getPostEditUrl,
  getSaveEditUrlPUT,
} from 'src/app/constants/private/urls/projects/edit.urls';
import {
  IEditRequestBody,
  ISaveEditRequestBody,
} from 'src/app/services/http-models/project/export/export-http-request-model';
import {
  IBasicSceneTakePair,
  IEditInDTO,
  IExportEditOutDTO,
} from 'src/app/models/project/edit/edit-model';

@Injectable({
  providedIn: 'root',
})
export class EditApiService {
  constructor(private http: HttpClient) {}

  public postEdit$(outEditDTO: IExportEditOutDTO, isDummy: boolean) {
    if (!outEditDTO) {
      throw new MissingArgumentsError(
        `Could not post export because argument is null`
      );
    }
    const body: IEditRequestBody = {
      edit: outEditDTO,
      isDummy: isDummy,
    };

    const url = getPostEditUrl(outEditDTO.projectId);

    return this.http.post<IEditInDTO>(url, body);
  }

  public saveEdit$(
    projectId: string,
    editId: string,
    dataToUpdate: IBasicSceneTakePair[]
  ) {
    if (!projectId || !editId || !dataToUpdate) {
      throw new MissingArgumentsError(
        `Could not send save edit request because one of the argument is null`
      );
    }
    const body: ISaveEditRequestBody = {
      dataToUpdate: {
        selectedShots: dataToUpdate,
      },
    };
    const url = getSaveEditUrlPUT(projectId, editId);
    return this.http.put<IEditInDTO>(url, body);
  }
}
