import { Injectable } from '@angular/core';
import { IOLDBASICEDITSCENEZAIN } from 'src/app/models/job/edit-job-schema';
import { ICommonLocalEditTake } from 'src/app/models/project/edit/edit-model';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ScenesStylesService {
  constructor() {}

  adjustSceneWidth<type extends ICommonLocalEditTake>(
    scene: type,
    baseWidth: number
  ): type {
    scene.style = { width: (scene.durationOnTrack / 1000) * baseWidth + 'px' };
    return scene;
  }

  private getWidth(
    length: number,
    trimStart: number,
    trimEnd: number,
    baseWidth: number
  ) {
    return length * baseWidth;
    return (length - trimEnd - trimStart) * baseWidth;
  }
}
