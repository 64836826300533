import { AspectRatioEnum } from './project/edit/edit-model';
import { IComposition, ILayout } from './defines';

export enum AddScenePositionEnum {
  BEFORE = 'before',
  AFTER = 'after',
}

export interface IDesign {
  _id: string;
  compositions: IComposition[];
  basePath: string;
  aspectRatio: AspectRatioEnum;
}

export interface IDesignGroup {
  _id?: string;
  name: string;
  icon: string;
  shortDescription: string;
  longDescription: string;
  design: IDesign;
}

export interface IDesignSuggestions {
  private: IDesignGroup[];
  public: IDesignGroup[];
}

export interface ICompositionAndDesignGroup {
  composition: IComposition;
  designGroup: IDesignGroup;
}

export interface IExtendedDesignGroup extends IDesignGroup {
  iconLayout?: ILayout;
}
