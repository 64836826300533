import { Injectable } from '@angular/core';
import { throttleTime } from 'rxjs';
import { EditStyleApiService } from '../api/auth/projects/edit-style-api.service';
import {
  ITakeInDTO,
  IUpdateTakePropertie,
} from 'src/app/models/project/take/take-model';
import { HttpErrorResponse } from '@angular/common/http';
import { TakeApiService } from '../api/auth/projects/take-api.service';

@Injectable({
  providedIn: 'root',
})
export class SubtitlesService {
  constructor(
    private editStyleApiService: EditStyleApiService,
    private takeApiService: TakeApiService
  ) {}

  /**
   * Updates a specific property of a subtitle style .
   * @param {string} projectId - The ID of the project containing the edit.
   * @param {string} editId - The ID of the edit containing the subtitles.
   * @param {string} subtitleId - The ID of the subtitles to update.
   * @param {string} propertyKey - The key of the property to update.
   * @param {any} newValue - The new value to set for the property.
   */
  public updateSubtitlesStyleProperty(
    projectId: string,
    editId: string,
    subtitleId: string,
    propertyKey: string,
    newValue: any
  ) {
    const params = {
      projectId: projectId,
      editId: editId,
      settingId: subtitleId,
      value: newValue,
      key: propertyKey,
    };
    this.editStyleApiService
      .updateSubtitleStyleProperty$(params)
      .pipe(
        throttleTime(500) // Allow at most 1 request per 500ms
      )
      .subscribe({
        next: async (updatedStyle) => {
          if (!updatedStyle) {
            return;
          }
        },
        error: (error) => {
          console.error(`Could not update subtitles style! ${error}`);
        },
      });
  }

  /**
   * Updates a Strategy of a edit plugins .
   * @param {string} projectId - The ID of the project containing the edit.
   * @param {string} editId - The ID of the edit containing the plugins.
   * @param {string} propertyKey - The key of the property to update.
   * @param {any} newValue - The new value to set for the property.
   */
  public updateStrategy(projectId: string, editId: string, newValue: any) {
    const params = {
      projectId: projectId,
      editId: editId,
      strategy: newValue,
    };
    this.editStyleApiService
      .updateStrategyProperty$(params)
      .pipe(
        throttleTime(500) // Allow at most 1 request per 500ms
      )
      .subscribe({
        next: async (updatedStyle) => {
          if (!updatedStyle) return;
        },
        error: (error) => {
          console.error(`Could not update subtitles strategy! ${error}`);
        },
      });
  }

  /**
   * Update specific subtitle of take.
   * @param {string} projectId - The ID of the project containing the edit.
   * @param {string} sceneId - The ID of the scene containing the take.
   * @param {ITakeInDTO} take - The take containing the subtitle.
   */
  public updateSubtitleInDB(
    projectId: string,
    sceneId: string,
    take: ITakeInDTO
  ) {
    // Todo: Throttle the updates
    const proeprtiesToUpdate: IUpdateTakePropertie[] = [
      {
        key: 'copy',
        value: take.copy,
      },
    ];
    this.takeApiService
      .updateTake$(projectId, sceneId, take.id, proeprtiesToUpdate)
      .subscribe({
        next: async (inTake) => {
          if (!inTake) {
            console.error(
              `Something strange happened while trying to update take.`
            );
          }
          /// We don't want to lose the ref for copy
          Object.assign(take.copy, inTake.copy);
        },
        error(err: HttpErrorResponse) {},
      });
  }
}
