<div *ngIf="!this.isInitalized; else content">Please wait</div>

<ng-template #content>
  <div>
    <div class="top-bar row-spacebetween">
      <div class="row-centered">
        <button
          mat-icon-button
          class="small-icon-button"
          (click)="backClicked.emit(true)">
          <mat-icon>arrow_back_ios</mat-icon>
        </button>
        <h4 class="title">Subtitles Styling</h4>
      </div>
    </div>

    <div class="section">
      <h5 class="section-title primary bold">Animations</h5>
      <div class="section-content">
        <subtitle-animation-selector
          (animationSelected)="
            onAnimationSelected($event)
          "></subtitle-animation-selector>
      </div>
    </div>
  </div>
  <div class="section">
    <h5 class="section-title primary bold">Text</h5>
    <div class="section-content">
      <div class="row-centered form-row">
        <!-- Font Select -->
        <mat-form-field class="font-select" subscriptSizing="dynamic">
          <mat-label>Font</mat-label>

          <mat-select
            [ngModel]="this.defaultSubTitleSettings.style.Fontname"
            (selectionChange)="
              updateStyle('Fontname', $event.value, subtitlesStylesEnum.BOTH)
            ">
            <mat-select-trigger>
              <img
                class="selected-font"
                [src]="this.baseCdnUrl + this.selectedFont.font.imageUrl" />
            </mat-select-trigger>
            <ng-template matSelectTrigger let-selected>
              <span>{{ selected.value?.name || 'Select Font' }}</span>
            </ng-template>
            <ng-container *ngFor="let font of this.fonts">
              <mat-option [value]="font.name">
                <img
                  class="font-image"
                  [src]="this.baseCdnUrl + font.imageUrl"
                  [alt]="font.name" />
              </mat-option>
            </ng-container>
            <!-- Add other font options -->
          </mat-select>
        </mat-form-field>
        <mat-form-field class="font-select" subscriptSizing="dynamic">
          <mat-label>Size</mat-label>
          <mat-select
            [ngModel]="
              (Number(defaultSubTitleSettings.style.Fontsize) / 3.5).toString()
            "
            (selectionChange)="
              updateStyle(
                'Fontsize',
                (Number($event.value) * 3.5).toString(),
                subtitlesStylesEnum.BOTH
              )
            ">
            <mat-option *ngFor="let size of fontSizes" [value]="size">
              {{ size }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row-centered form-row">
      <mat-button-toggle-group
        name="fontStyle"
        aria-label="Font Style"
        [ngModel]="this.defaultSubTitleSettings.style.Alignment"
        (change)="
          updateStyle('Alignment', $event.value, subtitlesStylesEnum.BOTH)
        ">
        <mat-button-toggle value="1">
          <mat-icon>format_align_left</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="2">
          <mat-icon>format_align_center</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="3">
          <mat-icon>format_align_right</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <mat-button-toggle-group
        aria-label="Text Decoration"
        multiple
        (change)="updateTextDecoration($event)"
        hideMultipleSelectionIndicator="true">
        <mat-button-toggle
          [disabled]="!this.selectedFont.hasBold"
          value="bold"
          [checked]="defaultSubTitleSettings.style.Bold === '1'">
          <mat-icon>format_bold</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          [disabled]="!this.selectedFont.hasItalic"
          value="italic"
          [checked]="defaultSubTitleSettings.style.Italic === '1'">
          <mat-icon>format_italic</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          value="underlined"
          [checked]="defaultSubTitleSettings.style.Underline === '1'">
          <mat-icon>format_underlined</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <div class="default-color">
        <div
          class="color-picker-container"
          [(colorPicker)]="this.defaultSubTitleSettings.style.PrimaryColour"
          [style.background]="this.defaultSubTitleSettings.style.PrimaryColour"
          [cpPosition]="'top-right'"
          [cpUseRootViewContainer]="true"
          (colorPickerSelect)="
            updateStyle('PrimaryColour', $event, subtitlesStylesEnum.NORMAL)
          "></div>
        <span>Text</span>
      </div>

      <div class="higlight-color">
        <div
          class="color-picker-container"
          [(colorPicker)]="this.highlightSubTitleSettings.style.PrimaryColour"
          [style.background]="
            this.highlightSubTitleSettings.style.PrimaryColour
          "
          [cpPosition]="'top-right'"
          [cpUseRootViewContainer]="true"
          (colorPickerSelect)="
            updateStyle(
              'PrimaryColour',
              $event,
              subtitlesStylesEnum.HIGHLIGHTED
            )
          "></div>
        <span>Highlight</span>
      </div>
    </div>
  </div>

  <div class="section">
    <h5 class="section-title primary bold">Outline and Shadow</h5>
    <div class="section-content">
      <div class="outline-shadow-container">
        <div class="outline-container">
          <div class="toggle-container">
            <span>Outline</span>
            <mat-slide-toggle
              [(ngModel)]="this.isAutoOutlineToggleOn"
              (ngModelChange)="
                onOutlineToggleChange($event)
              "></mat-slide-toggle>
          </div>
          <div class="selection" *ngIf="this.isAutoOutlineToggleOn">
            <mat-form-field>
              <mat-label>Width</mat-label>

              <input
                matInput
                class="input-number"
                max="10"
                min="1"
                [placeholder]="this.defaultSubTitleSettings.style.Outline"
                [ngModel]="this.defaultSubTitleSettings.style.Outline"
                (ngModelChange)="
                  updateStyle('Outline', $event, subtitlesStylesEnum.BOTH)
                "
                type="number" />
            </mat-form-field>
            <div
              class="color-picker-container"
              [(colorPicker)]="this.defaultSubTitleSettings.style.OutlineColour"
              [style.background]="
                this.defaultSubTitleSettings.style.OutlineColour
              "
              [cpPosition]="'top-right'"
              [cpUseRootViewContainer]="true"
              (colorPickerSelect)="
                updateStyle('OutlineColour', $event, subtitlesStylesEnum.BOTH)
              "></div>
          </div>
        </div>
        <div class="background-container">
          <div class="toggle-container">
            <span>Box</span>
            <mat-slide-toggle
              [(ngModel)]="this.isAutoBackgroundToggleOn"
              (ngModelChange)="
                onBackgroundToggleChange($event)
              "></mat-slide-toggle>
          </div>
          <div class="selection" *ngIf="this.isAutoBackgroundToggleOn">
            <mat-form-field>
              <mat-label>Width</mat-label>
              <input
                matInput
                class="input-number"
                max="10"
                min="1"
                [ngModel]="this.defaultSubTitleSettings.style.Outline"
                (ngModelChange)="
                  updateStyle('Outline', $event, subtitlesStylesEnum.BOTH)
                "
                [placeholder]="this.defaultSubTitleSettings.style.Outline"
                type="number" />
            </mat-form-field>
            <div
              class="color-picker-container"
              [(colorPicker)]="this.defaultSubTitleSettings.style.OutlineColour"
              [style.background]="
                this.defaultSubTitleSettings.style.OutlineColour
              "
              [cpPosition]="'top-right'"
              [cpUseRootViewContainer]="true"
              (colorPickerSelect)="
                updateStyle('OutlineColour', $event, subtitlesStylesEnum.BOTH)
              "></div>
          </div>
        </div>
        <div class="shadow-container">
          <div class="toggle-container">
            <span>Shadow</span>
            <mat-slide-toggle
              [(ngModel)]="this.isAutoShadowToggleOn"
              (ngModelChange)="onShadowToggleChange($event)"></mat-slide-toggle>
          </div>
          <div class="selection" *ngIf="this.isAutoShadowToggleOn">
            <mat-form-field>
              <mat-label>Width</mat-label>
              <input
                matInput
                class="input-number"
                max="10"
                min="1"
                [ngModel]="this.defaultSubTitleSettings.style.Shadow"
                (ngModelChange)="
                  updateStyle('Shadow', $event, subtitlesStylesEnum.BOTH)
                "
                type="number" />
            </mat-form-field>

            <!-- TODO: Retrive if we need to allow the user to choose the shadow color. -->
            <!-- <div
              class="color-picker-container"
              [(colorPicker)]="this.defaultSubTitleSettings.style.BackColour"
              [style.background]="this.defaultSubTitleSettings.style.BackColour"
              [cpPosition]="'top-right'"
              [cpUseRootViewContainer]="true"
              (colorPickerSelect)="
                updateStyle('BackColour', $event, subtitlesStylesEnum.NORMAL)
              "></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="section">
    <h5 class="section-title primary bold">Background</h5>
    <div class="section-content">
      <div class="row-centered form-row">
        <div
          class="color-picker-container"
          [(colorPicker)]="this.defaultSubTitleSettings.style.BackColour"
          [style.background]="this.defaultSubTitleSettings.style.BackColour"
          (colorPickerSelect)="updateStyle('BackColour', $event)"></div>
      </div>

      <div>
        <mat-form-field class="font-select" subscriptSizing="dynamic">
          <mat-select>
            <mat-option value="Wrap">Wrap</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div> -->
</ng-template>
