<div
  class="loading-progress-spinner"
  *ngIf="(isLoading | async) && showLoadingIndication">
  <mat-progress-spinner mode="indeterminate" color="primary">
  </mat-progress-spinner>
</div>
<div
  class="all-wrapper"
  [class.hideme]="isHidden || ((isLoading | async) && showLoadingIndication)"
  *ngIf="this.composedEditTakes$ | async as composedEditTakes">
  <ng-container
    *ngFor="let composedEditTake of composedEditTakes; index as editTakeIndex">
    <ng-container
      *ngFor="
        let layerAndOptions of composedEditTake.lottiesLayersAndOptions;
        index as lottieIndex
      ">
      <dynamic-lottie
        class="lottie-display"
        (destroy)="
          animationDestroyed(
            composedEditTake.id + layerAndOptions.lottieLayer.lottieId
          )
        "
        [config]="layerAndOptions.lottieData"
        [lottieOptions]="layerAndOptions.options"
        [class.hideme]="layerAndOptions.isHidden$ | async"
        (animationCreated)="
          animationCreated(
            $event,
            layerAndOptions,
            composedEditTake.id,
            layerAndOptions.lottieLayer.lottieId
          )
        "
        >>
      </dynamic-lottie>
      <!-- <ng-lottie
                          class="lottie-display"
                          (destroy)="
                            animationDestroyed(
                              composedScene.id + layerAndOptions.lottieLayer.lottieId
                            )
                          "
                          [class.hideme]="layerAndOptions.isHidden$ | async"
                          [options]="layerAndOptions.options"
                          (animationCreated)="
                            animationCreated(
                              $event,
                              layerAndOptions,
                              composedScene.id,
                              layerAndOptions.lottieLayer.lottieId
                            )
                          ">
                        </ng-lottie> -->
    </ng-container>
    <ng-container *ngIf="composedEditTake.videoLayers as videoLayers">
      <app-scrub-bar
        *ngIf="videoLayers.length === 0"
        (newSeekInMillisEmitter)="newTimeFromScrubBar($event)"
        (dragStartedEmitter)="dragStartedFromScrubBar()"
        (dragEndedEmitter)="dragEndedFromScrubBar()"
        class="scrub-bar"
        [vgApiId]="composedEditTake.id"></app-scrub-bar>
      <div
        class="video-layer"
        style="width: 100%; height: 100%"
        *ngIf="videoLayers.length > 0">
        <vg-player
          #vgPlayer
          [id]="composedEditTake.id"
          (onPlayerReady)="
            onPlayerReady($event, composedEditTake.id, videoLayers[0])
          ">
          <vg-controls
            (dragstart)="$event.stopPropagation()"
            (drag)="$event.stopPropagation()"
            (mousedown)="$event.stopPropagation()"
            *ngIf="this.displayVgControls && areVideosReady$ | async"
            [vgAutohide]="true"
            [vgAutohideTime]="2.5">
            <!-- (vgPlay)="onVgPlayPausea($event)" -->
            <!-- <vg-scrub-bar #customScrubBar>
                                      <vg-scrub-bar-current-time
                                        [vgSlider]="true"
                                      ></vg-scrub-bar-current-time>
                                    </vg-scrub-bar> -->
            <app-scrub-bar
              (newSeekInMillisEmitter)="newTimeFromScrubBar($event)"
              (dragStartedEmitter)="dragStartedFromScrubBar()"
              (dragEndedEmitter)="dragEndedFromScrubBar()"
              [duration]="composedEditTake.duration"
              class="scrub-bar"
              [vgApiId]="composedEditTake.id"></app-scrub-bar>
            <div class="bottom-row row-centered">
              <button
                class="play-button"
                mat-icon-button
                (click)="pauseOrPlayPressed(composedEditTake.id)"
                tabindex="0"
                [matTooltip]="playOrPause.isPlaying ? 'Pause' : 'Play'">
                <mat-icon>
                  {{ playOrPause.isPlaying ? 'pause' : 'play_arrow' }}</mat-icon
                >
              </button>
              <div class="row-centered">
                <vg-time-display
                  vgFormat="mm:ss /"
                  vgProperty="current"></vg-time-display>

                <div class="time-display">
                  {{ composedEditTake.duration * 1000 | timeFormat }}
                </div>
              </div>
            </div>
          </vg-controls>

          <ng-container *ngFor="let videoLayer of videoLayers; let i = index">
            <!-- custom-video.component.html -->
            <video
              #media
              [class.hideme]="toHideVideoLayer"
              *ngIf="
                !isIOS && !videoLayer.localUrlConfigs && videoLayer.dashPath;
                else noDash
              "
              [vgDash]="baseCdnUrl + videoLayer.dashPath"
              [class.flip-horizontally]="flipHorizontally"
              [style.top]="videoLayer.videoPosition.y + '%'"
              [style.left]="videoLayer.videoPosition.x + '%'"
              [style.height]="videoLayer.videoPosition.height + '%'"
              [style.width]="videoLayer.videoPosition.width + '%'"
              class="no-controls"
              playsinline="true"
              disablePictureInPicture
              [vgMedia]="$any(media)"
              [vgMaster]="i === 0"
              [id]="composedEditTake.id + editTakeIndex"
              preload="auto"></video>
            <!-- [src]="baseCdnUrl + videoLayer.uploadPath" -->

            <ng-template #noDash>
              <video
                [class.hideme]="toHideVideoLayer"
                #media
                [appVideoSource]="{
                  localUrl: videoLayer.localUrlConfigs,
                  uploadUrl: baseCdnUrl + videoLayer.uploadPath,
                }"
                [class.flip-horizontally]="flipHorizontally"
                [style.top]="videoLayer.videoPosition.y + '%'"
                [style.left]="videoLayer.videoPosition.x + '%'"
                [style.height]="videoLayer.videoPosition.height + '%'"
                [style.width]="videoLayer.videoPosition.width + '%'"
                class="no-controls"
                [vgMedia]="$any(media)"
                playsinline="true"
                disablePictureInPicture
                [vgMaster]="i === 0"
                [id]="composedEditTake.id + editTakeIndex"
                preload="auto"></video>
            </ng-template>
          </ng-container>

          <!-- crossorigin -->
          <!-- <audio
                                    #audioMedia
                                    [vgMedia]="audioMedia"
                                    id="audioMedia"
                                    preload="auto"
                                    [volume]="0.3"
                                  >
                                    <source
                                      *ngFor="let audio of audioScenesOnTrack"
                                      [src]="this.baseCdnUrl + audio.audioPath"
                                      type="audio/mpeg"
                                    />
                                  </audio> -->
        </vg-player>
      </div>
    </ng-container>
  </ng-container>
</div>

<canvas class="subtitles-canvas" #subtitlesCanvas></canvas>
