<div class="wrapper">
  <div
    class="steps"
    matTooltip="You are in the {{ phase }} phase"
    fxHide
    fxShow.gt-sm>
    <div [class.selected]="phase === ProjectStatus.PLANNING" class="step">
      Planning
    </div>
    <mat-icon>keyboard_arrow_right</mat-icon>
    <div [class.selected]="phase === ProjectStatus.RECORDING" class="step">
      Recording
    </div>
    <mat-icon>keyboard_arrow_right</mat-icon>
    <div [class.selected]="phase === ProjectStatus.EDITING" class="step">
      Editing
    </div>
  </div>

  <div class="project-name" *ngIf="name">
    <h2 class="header__title no-margin" fxHide fxShow.gt-sm>
      <app-sidenav-toggle></app-sidenav-toggle>
    </h2>
    <editable
      class="minimal-editable"
      [class.disabled-edit]="!allowNameChange"
      (save)="update()"
      (cancel)="cancel()"
      *ngIf="nameFormControl"
      [enabled]="allowNameChange">
      <ng-template viewMode>
        <div class="bordered input-with-edit-icon" [matTooltip]="this.name">
          {{ this.name }}
          <div class="edit-icon" [hidden]="!allowNameChange">
            <mat-icon>edit</mat-icon>
          </div>
        </div>
      </ng-template>
      <ng-template editMode>
        <input
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          cdkTextareaAutosize
          editableOnEnter
          editableOnEscape
          editableFocusable
          [formControl]="nameFormControl" />
      </ng-template>
    </editable>
  </div>
</div>
