<div
  class="prompter-wrapper"
  [style.background-color]="backgroundColor"
  [class.standalone]="!hosted"
  [class.hosted]="hosted"
  [class.ai-mode]="aiMode"
  [class.manual-mode]="!aiMode">
  <div
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    *ngIf="!hosted">
    <div class="title-bar row-centered row-spacebetween">
      <div class="row-centered">
        <mat-icon>drag_indicator</mat-icon>
        Teleprompter
      </div>

      <div>
        <button mat-icon-button class="small-icon-button" mat-dialog-close>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="shuffll-card gradient-primary" *ngIf="displayNotes">
    <!--    <div>-->
    <!--      <h4>-->
    <!--        Notes-->
    <!--      </h4>-->
    <!--    </div>-->
    <div>
      <mat-form-field
        id="notes"
        (focusout)="saveNotes()"
        class="notes-text-area">
        <mat-label>Notes</mat-label>
        <textarea
          matInput
          [(ngModel)]="notes"
          name="notes"
          spellcheck="false"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"></textarea>
      </mat-form-field>
    </div>
  </div>

  <mat-menu #wordMenu="matMenu">
    <ng-template matMenuContent let-word="word">
      <button mat-menu-item (click)="voiceRecognition.jumpToWord(word)">
        Jump here
      </button>
      <button
        mat-menu-item
        *ngIf="!word.events?.length"
        (click)="createEvent(word)">
        Add Scene Trigger
      </button>
      <button
        mat-menu-item
        *ngIf="word.events?.length"
        (click)="removeEvent(word)">
        Remove Scene Trigger
      </button>
    </ng-template>
  </mat-menu>

  <div
    class="scroller-wrap"
    id="scroller-wrap"
    [class.streched]="!displayNotes"
    [class.transparent-scrollbar]="hosted"
    joyrideStep="walkthrough-teleprompter"
    title="Meet the AI teleprompter"
    text="Here are your script suggestions. The AI teleprompter will detect and follow your speech.
You can use triggers on each word to switch scenes and activate effects."
    stepPosition="right"
    (next)="finishedWalkthroughStep.next(true)"
    #scrollerWrap
    [class.flipped-x]="flippedX"
    [class.flipped-y]="flippedY">
    <div class="bottom-gradient" *ngIf="hosted"></div>
    <!--- Regular Mode-->
    <textarea
      class="scroller"
      id="scroller"
      #scroller
      [placeholder]="hosted ? 'Write your script here' : ''"
      [style.color]="textColor"
      cdkTextareaAutosize
      *ngIf="!aiMode"
      [value]="manualText"
      (blur)="onTextBlur($event)"
      [style.font-size]="fontSize + 'px'"
      [style.line-height]="fontSize * 1.5 + 'px'"
      [style.text-align]="textAlignment.align"
      [style.padding-left]="margin + '%'"
      [style.padding-right]="margin + '%'">
    </textarea>

    <div
      class="ai-scroller"
      *ngIf="aiMode"
      [style.color]="textColor"
      [style.font-size]="fontSize + 'px'"
      [style.line-height]="fontSize * 1.5 + 'px'"
      [style.text-align]="textAlignment.align"
      [style.padding-left]="margin + '%'"
      [style.padding-right]="margin + '%'">
      <!--AI MODE-->
      <span
        class="text-span"
        [matMenuTriggerFor]="wordMenu"
        #t="matMenuTrigger"
        [class.menu-open]="t.menuOpen"
        [matTooltip]="'Click to open menu'"
        [matMenuTriggerData]="{ word: text }"
        [class.word]="text.type === 'word'"
        [class.symbol]="text.type === 'symbol'"
        [class.space]="text.type === 'space'"
        [class.done]="
          voiceRecognition.lastFoundWord.value &&
          text.index <= voiceRecognition.lastFoundWord.value.index
        "
        [class.period]="
          text?.type === 'symbol' && text.text?.trim()?.endsWith('.')
        "
        [id]="text.uuid"
        *ngFor="let text of getText()"
        matBadge="1"
        matBadgeOverlap="false"
        matBadgePosition="after"
        [matBadgeHidden]="
          text.type !== 'word' || !text.events || text.events.length === 0
        "
        matBadgeColor="primary"
        >{{ text.text }}</span
      >
    </div>
  </div>
  <div class="toolbar-area">
    <div class="tab-title">
      <div class="top-right" *ngIf="!aiMode">
        <button
          [class.small-icon-button]="hosted"
          mat-mini-fab
          class="cool-button gray play-pause"
          color="secondary"
          [class.pause]="playing"
          cdkFocusInitial
          (click)="playPause(!this.playing)">
          <mat-icon>{{ playing ? 'pause' : 'play_arrow' }}</mat-icon>
        </button>
        <button
          [class.small-icon-button]="hosted"
          mat-mini-fab
          class="cool-button gray"
          color="secondary"
          (click)="rewindToStart('smooth')"
          matTooltip="Start Over">
          <mat-icon>restart_alt</mat-icon>
        </button>
        <button
          [class.small-icon-button]="hosted"
          mat-mini-fab
          class="cool-button gray"
          color="secondary"
          [matMenuTriggerFor]="mainMenu">
          <mat-icon>settings</mat-icon>
        </button>

        <!-- <button
          class="cool-button gray speed-slider"
          color="secondary"
          matTooltip="Speed"
          [class.small-icon-button]="hosted"
          mat-mini-fab
          [matMenuTriggerFor]="speedMenu"
          [disabled]="aiMode"
          (click)="$event.stopPropagation()"
          (keydown)="$event.stopPropagation()"
          step="1"
          min="0"
          max="50"
          aria-label="speed units">
          <mat-icon>speed</mat-icon>
        </button> -->
      </div>
    </div>

    <div class="tab-title-buttons">
      <!--      <button mat-icon-button class="cool-button gray" *ngIf="!hosted" (click)="publish()" matTooltip="Publish"-->
      <!--              [disabled]="!masterUp">-->
      <!--        <mat-icon>publish</mat-icon>-->
      <!--      </button>-->

      <!--      <button mat-icon-button (click)="setCopy(manualText)" matTooltip="Save Copy">-->
      <!--        <mat-icon>save</mat-icon>-->
      <!--      </button>-->
      <button
        mat-mini-fab
        class="cool-button gray"
        color="secondary"
        *ngIf="false"
        (click)="openPrompterInANewWindow()"
        matTooltip="Open Prompter Window">
        <mat-icon>tab</mat-icon>
      </button>
      <!--      <button mat-icon-button *ngIf="hosted" (click)="togglleEditText()" matTooltip="Edit Copy">-->
      <!--        <mat-icon>edit</mat-icon>-->
      <!--      </button>-->
    </div>
    <mat-menu #mainMenu="matMenu" class="prompter-menu">
      <!--      <button mat-menu-item *ngIf="hosted" (click)="togglleEditText()" class="AI / Manual mode" style="color: #1e1e1e">-->
      <!--        <mat-icon>edit</mat-icon>-->
      <!--        {{this.aiMode ? 'Edit - Manual Mode' : 'AI Mode'}}-->
      <!--      </button>-->
      <!--      <button mat-menu-item (click)="openPrompterInANewWindow()" class="Teleprompter link" style="color: #1e1e1e">-->
      <!--        <mat-icon>tab</mat-icon>-->
      <!--        Open in a new tab-->
      <!--      </button>-->

      <!--      <mat-divider></mat-divider>-->

      <!--      <div mat-menu-item>-->
      <!--        AI Mode-->
      <!--        &lt;!&ndash;        <mat-slide-toggle (change)="slideToggleChange($event)" [checked]="aiMode" color="primary"&ndash;&gt;-->
      <!--        &lt;!&ndash;                          class="ai-mode-toggle"&ndash;&gt;-->
      <!--        &lt;!&ndash;                          matTooltip="Voice Recognition Mode">&ndash;&gt;-->

      <!--        &lt;!&ndash;        </mat-slide-toggle>&ndash;&gt;-->
      <!--      </div>-->

      <!--      <div mat-menu-item class="toggle-menu-item">-->
      <!--        Show Notes-->
      <!--        <mat-slide-toggle (change)="displayNotesChange($event)" [checked]="displayNotes" color="primary"-->
      <!--                          class="ai-mode-toggle"-->
      <!--                          matTooltip="Show Notes">-->

      <!--        </mat-slide-toggle>-->
      <!--      </div>-->
      <!-- <button mat-menu-item [matMenuTriggerFor]="speedMenu" [disabled]="aiMode">
        <mat-icon>speed</mat-icon>
        Speed: <b>{{ speed }}</b>
      </button> -->

      <button mat-menu-item [matMenuTriggerFor]="marginMenu">
        <mat-icon>document_scanner</mat-icon>
        Margins: <b>{{ margin }}</b>
      </button>

      <button mat-menu-item [matMenuTriggerFor]="speedMenu">
        <mat-icon>speed</mat-icon>
        Speed: <b>{{ speed }}</b>
      </button>

      <button mat-menu-item [matMenuTriggerFor]="fontSizeMenu">
        <mat-icon>format_size</mat-icon>
        Font Size: <b>{{ fontSize }}</b>
      </button>

      <mat-divider></mat-divider>

      <div
        (click)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
        class="menu-style-toolbar">
        <button mat-icon-button (click)="toggleTextAlign()" matTooltip="Align">
          <mat-icon>{{ textAlignment.icon }}</mat-icon>
        </button>

        <button
          mat-icon-button
          (click)="$event.stopPropagation(); flippedX = !flippedX"
          (keydown)="$event.stopPropagation()"
          matTooltip="Mirror Horizontally">
          <mat-icon>swap_horiz</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="$event.stopPropagation(); flippedY = !flippedY"
          (keydown)="$event.stopPropagation()"
          matTooltip="Mirror Vertically">
          <mat-icon>swap_vert</mat-icon>
        </button>

        <div
          class="color-picker-container"
          [(colorPicker)]="backgroundColor"
          [style.background]="backgroundColor"
          [cpDialogDisplay]="'popup'"></div>
        <div
          class="color-picker-container"
          style="margin-left: 7px"
          [(colorPicker)]="textColor"
          [style.background]="textColor"
          [cpDialogDisplay]="'popup'">
          T
        </div>
      </div>

      <mat-menu #speedMenu="matMenu">
        <mat-slider
          class="mat-menu-speed-slider"
          (click)="$event.stopPropagation()"
          (keydown)="$event.stopPropagation()"
          thumbLabel
          step="1"
          min="0"
          max="50"
          aria-label="speed units">
          <input matSliderThumb [(ngModel)]="speed" (ngModelChange)="onSpeedChange($event)" />
        </mat-slider>
      </mat-menu>

      <mat-menu #marginMenu="matMenu" class="fix-padding-menu">
        <mat-slider
          (click)="$event.stopPropagation()"
          (keydown)="$event.stopPropagation()"
          thumbLabel
          step="2"
          min="0"
          max="50"
          aria-label="margin units">
          <input matSliderThumb [(ngModel)]="margin" name="margin" />
        </mat-slider>
      </mat-menu>

      <mat-menu #fontSizeMenu="matMenu" class="fix-padding-menu">
        <mat-slider
          (click)="$event.stopPropagation()"
          (keydown)="$event.stopPropagation()"
          thumbLabel
          step="2"
          min="12"
          max="120"
          aria-label="units">
          <input matSliderThumb [(ngModel)]="fontSize" />
        </mat-slider>
      </mat-menu>
    </mat-menu>
  </div>
</div>
