<div style="height: 10px">
  <mat-progress-bar mode="indeterminate" *ngIf="loadingData"></mat-progress-bar>
</div>

<div class="dashboard-page padded" autosize>
  <div class="top-part">
    <div class="title-section">
      <!--      TODO: what if there's no where to go back?-->
      <button class="back-button" mat-icon-button (click)="goToDashboard()">
        <mat-icon>arrow_back_ios</mat-icon>
      </button>
      <h2 class="title">
        Exports{{ project?.name ? ' : ' + project.name : '' }}
      </h2>
    </div>
    <div class="top-buttons-section">
      <button
        mat-icon-button
        (click)="autoRefreshClicked()"
        [matTooltip]="
          (this.enableAutoRefreshData$ | async)
            ? 'Disable Auto Refresh'
            : 'Enable Auto Refresh'
        "
        [color]="(this.enableAutoRefreshData$ | async) ? 'primary' : 'regular'">
        <mat-icon>autorenew</mat-icon>
      </button>
      <!--            <button mat-stroked-button color="primary" (click)="gotoEdit()">-->
      <!--                <mat-icon>auto_awesome</mat-icon>-->
      <!--                Edit Room-->
      <!--            </button>-->
    </div>
  </div>
  <div class="main-area">
    <div class="main-video" *ngIf="recentExport$ | async as recentExport">
      <div class="share-download">
        <app-button-to-display-links
          [buttonConfigs]="
            recentExport.buttonConfigs$ | async
          "></app-button-to-display-links>
        <!-- <button
                    mat-icon-button
                    [matMenuTriggerFor]="shareMenu"
                    [disabled]="
                      recentExport.status !== JobStatus.COMPLETED ||
                      (!recentExport.exportedVideos.originalExported &&
                        !recentExport.exportedVideos.exportedWithAudioClean)
                    "
                  >
                    <mat-icon>share</mat-icon>
                  </button>

                  <mat-menu #shareMenu="matMenu" yPosition="below">
                    <button
                      *ngIf="recentExport.exportedVideos.originalExported.uploadPath"
                      mat-menu-item
                      (click)="
                        copyToClipboard(
                          recentExport.exportedVideos.originalExported.uploadPath
                        )
                      "
                    >
                      <mat-icon>link</mat-icon>
                      Copy Regular Version URL
                    </button>

                    <button
                      mat-menu-item
                      *ngIf="
                        recentExport.exportedVideos.exportedWithAudioClean.uploadPath
                      "
                      (click)="
                        copyToClipboard(
                          recentExport.exportedVideos.exportedWithAudioClean.uploadPath
                        )
                      "
                    >
                      <mat-icon>graphic_eq</mat-icon>
                      Copy Cleaned Audio Version URL
                    </button>
                  </mat-menu>

                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="downloadMenu"
                    [disabled]="
                      recentExport.status !== JobStatus.COMPLETED ||
                      (!recentExport.exportedVideos.originalExported &&
                        !recentExport.exportedVideos.exportedWithAudioClean)
                    "
                  >
                    <mat-icon>download</mat-icon>
                  </button>

                  <mat-menu #downloadMenu="matMenu" yPosition="below">
                    <a
                      style="text-decoration: none"
                      target="_blank"
                      [href]="
                        this.baseCdn +
                        '/' +
                        recentExport.exportedVideos.originalExported.uploadPath
                      "
                      *ngIf="recentExport.exportedVideos.originalExported.uploadPath"
                      download
                    >
                      <button mat-menu-item>
                        <mat-icon>download</mat-icon>
                        Regular Version
                      </button>
                    </a>

                    <a
                      style="text-decoration: none"
                      target="_blank"
                      [href]="
                        this.baseCdn +
                        '/' +
                        recentExport.exportedVideos.exportedWithAudioClean.uploadPath
                      "
                      *ngIf="
                        recentExport.exportedVideos.exportedWithAudioClean.uploadPath
                      "
                      download
                    >
                      <button mat-menu-item>
                        <mat-icon>graphic_eq</mat-icon>
                        Cleaned Audio Version
                      </button>
                    </a>
                  </mat-menu> -->
      </div>

      <div>
        <div
          *ngIf="recentExport"
          class="video-wrapper full-width border-radius-small">
          <div class="video-rendering">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            <div class="loading-camera-wrap">
              <ng-lottie
                class="loading-camera"
                containerClass="loading-camera-wrap"
                [options]="{
                  path: 'assets/animations/loading-camera.json',
                }"></ng-lottie>
            </div>
            <div class="loading-camera-text">Your video is on its way!</div>
          </div>
          <!-- <div
            class="video-rendering"
            *ngIf="recentExport.export?.status !== EditJobStatusEnum.COMPLETED">
            <mat-progress-bar featureFlagMode="indeterminate"></mat-progress-bar>
            <div class="loading-camera-wrap">
              <ng-lottie
                class="loading-camera"
                containerClass="loading-camera-wrap"
                [options]="{
                  path: 'assets/animations/loading-camera.json'
                }"></ng-lottie>
            </div>
            <div class="loading-camera-text">Your video is on its way!</div>
          </div> -->

          <!-- *ngIf="recentExport.export?.status === EditJobStatusEnum.COMPLETED" -->
          <div
            *ngIf="recentExport.export?.exports?.originalExported"
            class="player-wrapper video-wrapper border-radius-small">
            <vg-player
              #vgPlayer
              (onPlayerReady)="onPlayerReady($event, recentExport.export)">
              <video
                *ngIf="
                  !isIOS && recentExport.export.exportedVideos.dashPath;
                  else noDash
                "
                [vgDash]="
                  baseCdnUrl + recentExport.export.exportedVideos.dashPath
                "
                controls
                class="video-file"
                preload="auto"
                #media
                [vgMedia]="media"
                id="singleVideo"
                #vgDash="vgDash"
                crossorigin></video>

              <ng-template #noDash>
                <video
                  [appVideoSource]="{
                    uploadUrl:
                      baseCdnUrl +
                      recentExport.export.exportedVideos.uploadPath,
                    localUrl: null,
                  }"
                  controls
                  class="video-file"
                  preload="auto"
                  #media
                  [vgMedia]="media"
                  id="singleVideo"
                  crossorigin></video>
              </ng-template>
            </vg-player>
          </div>
        </div>
        <div class="main-video-details">
          <div>
            <h3>
              <b>{{ project?.name }}</b>
            </h3>
            <h4>
              {{
                recentExport.export?.createdAt
                  | date: "MMM dd, yyyy 'at' hh:mm a"
              }}
            </h4>
          </div>
          <!--                    <div class="profile-image-wrapper" matTooltip="Exported by you">-->
          <!--                        <current-user-img></current-user-img>-->
          <!--                    </div>-->
        </div>
      </div>
      <div
        class="previous-videos"
        *ngIf="previousExports$ | async as previousExports">
        <ng-container *ngIf="previousExports.length > 0">
          <h3><b>Previous Exports</b></h3>
          <div
            class="previous-video"
            *ngFor="let previousVideo of previousExports">
            <!-- TODO: MOVE TO COMPONENT ! -->
            <div class="player-wrapper video-wrapper border-radius-small">
              <vg-player
                *ngIf="previousVideo?.export.exportedVideos"
                (onPlayerReady)="onPlayerReady($event, previousVideo.export)">
                <video
                  *ngIf="previousVideo?.export.exportedVideos"
                  [vgDash]="
                    this.baseCdnUrl +
                    (previousVideo.export.exportedVideos.dashPath ??
                      previousVideo.export.exportedVideos.uploadPath)
                  "
                  #vgDash="vgDash"
                  controls
                  [id]="previousVideo.export.id"
                  crossorigin="anonymous"
                  preload="auto"
                  #media
                  [vgMedia]="media"></video>
              </vg-player>
              <div
                class="video-rendering small"
                *ngIf="!previousVideo?.export.exportedVideos">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                <div class="loading-camera-wrap">
                  <ng-lottie
                    class="loading-camera"
                    containerClass="loading-camera-wrap"
                    [options]="{
                      path: 'assets/animations/loading-camera.json',
                    }">
                  </ng-lottie>
                </div>
                <div class="loading-camera-text">Your video is on its way!</div>
              </div>
            </div>
            <div class="details">
              <h3>
                <b>{{ project?.name }}</b>
              </h3>
              <h4>
                {{
                  previousVideo.export.createdAt
                    | date: "MMM dd, yyyy 'at' hh:mm a"
                }}
              </h4>
              <div
                class="profile-image-wrapper small"
                matTooltip="Exported by you">
                <current-user-img></current-user-img>
              </div>
            </div>
            <div class="share-download">
              <app-button-to-display-links
                [buttonConfigs]="
                  previousVideo.buttonConfigs$ | async
                "></app-button-to-display-links>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
