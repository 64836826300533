import { TranscriptWord } from '../models/project/take/transcript-model';

interface AlignedWord extends TranscriptWord {
  originalIndex: number | null;
}

export function subtitleAlignment(
  words: TranscriptWord[],
  newSentence: string
): AlignedWord[] {
  const masterWords = words.map((word) => word.text);
  const newWords = newSentence.split(/\s+/);

  const m = masterWords.length;
  const n = newWords.length;

  // Initialize the dynamic programming matrix
  const dp: number[][] = Array(m + 1)
    .fill(null)
    .map(() => Array(n + 1).fill(0));

  // Fill the dp matrix
  for (let i = 1; i <= m; i++) {
    for (let j = 1; j <= n; j++) {
      if (masterWords[i - 1].toLowerCase() === newWords[j - 1].toLowerCase()) {
        dp[i][j] = dp[i - 1][j - 1] + 1;
      } else {
        dp[i][j] = Math.max(dp[i - 1][j], dp[i][j - 1]);
      }
    }
  }

  // Backtrack to find the alignment
  const alignment: AlignedWord[] = [];
  let i = m,
    j = n;
  let lastReplacedIndex = m - 1; // Start with the last word in master subtitle

  while (i > 0 && j > 0) {
    if (masterWords[i - 1].toLowerCase() === newWords[j - 1].toLowerCase()) {
      // Words match, use the master word's timing
      alignment.unshift({
        ...words[i - 1],
        originalIndex: i - 1,
      });
      lastReplacedIndex = i - 1;
      i--;
      j--;
    } else if (dp[i - 1][j] > dp[i][j - 1]) {
      // Word from master doesn't match, update lastReplacedIndex
      lastReplacedIndex = i - 1;
      i--;
    } else {
      // New word, use timing from the closest replaced word
      alignment.unshift({
        text: newWords[j - 1],
        start: words[lastReplacedIndex].start,
        end: words[lastReplacedIndex].end,
        originalIndex: null,
        confidence: -1,
      });
      j--;
    }
  }

  // Add any remaining new words at the beginning
  while (j > 0) {
    alignment.unshift({
      text: newWords[j - 1],
      start: words[0].start,
      end: words[0].end,
      originalIndex: null,
      confidence: -1,
    });
    j--;
  }

  return alignment;
}

//
// // Example usage
// const masterSubtitle: Word[] = [
//   { text: 'The', startTime: 0, endTime: 0.2 },
//   { text: 'quick', startTime: 0.3, endTime: 0.7 },
//   { text: 'brown', startTime: 0.8, endTime: 1.1 },
//   { text: 'fox', startTime: 1.2, endTime: 1.5 },
//   { text: 'jumps', startTime: 1.6, endTime: 2.0 },
//   { text: 'over', startTime: 2.1, endTime: 2.3 },
//   { text: 'the', startTime: 2.4, endTime: 2.5 },
//   { text: 'lazy', startTime: 2.6, endTime: 3.0 },
//   { text: 'dog', startTime: 3.1, endTime: 3.5 },
// ];
//
// const newSentence = 'The fast brown cat leaps twice over the sleepy dog';
//
// const alignedSubtitle = subtitleAlignment(masterSubtitle, newSentence);
//
// console.log('Aligned subtitle:');
// alignedSubtitle.forEach((word) => {
//   console.log(
//     `Word: ${word.text.padEnd(10)} | Start: ${word.startTime.toFixed(2)} | End: ${word.endTime.toFixed(2)} | Original Index: ${word.originalIndex !== null ? word.originalIndex : 'N/A'}`
//   );
// });
