<h2 mat-dialog-title>AI Image Generation</h2>
<div mat-dialog-content>
  <div style="margin-bottom: 15px">
    Write a prompt of 20 characters or more to generate an image
  </div>
  <mat-form-field appearance="fill" class="field">
    <input
      matInput
      type="text"
      #promptInput="ngModel"
      [(ngModel)]="prompt"
      name="prompt"
      minlength="20"
      pattern="^[a-zA-Z0-9\s\-@.]+$"
      required
      placeholder="Describe your image" />

    <mat-error
      *ngIf="promptInput.errors?.required || promptInput.errors?.minlength"
      >Write a prompt of 20 characters or more
    </mat-error>
    <mat-error *ngIf="promptInput.errors?.pattern"
      >Prompt can only contain english characters, numbers, spaces and dashes
    </mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="null" cdkFocusInitial>Cancel</button>
  <button
    mat-button
    mat-raised-button
    color="primary"
    [disabled]="!promptInput.valid"
    [mat-dialog-close]="prompt">
    Generate
  </button>
</div>
