<div class="scene-actions">
  <ng-container
    *ngIf="this.chosenScene?.chosenTake.status === TakeStatusEnum.RECORDED">
    <div class="scene-actions__icon__record">
      <button
        (click)="addTakeButtonClicked.emit()"
        [class.active]="true"
        [disabled]="this.chosenScene?.isHidden || this.disableRecording"
        class="cool-button gray"
        color="secondary"
        mat-flat-button>
        <mat-icon>fiber_manual_record</mat-icon>
        <span class="icon-text">Have Another Take</span>
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="!isRecordingInProgress; else recording">
    <ng-container
      *ngIf="
        this.chosenScene?.chosenTake &&
        this.chosenScene.chosenTake.status !== TakeStatusEnum.RECORDED
      ">
      <div class="scene-actions__icon__record">
        <button
          (click)="recordButtonClicked.emit()"
          [class.active]="true"
          [disabled]="
            this.chosenScene.isHidden ||
            this.disableRecording ||
            this.isCoundownInProgress
          "
          class="cool-button gray"
          color="secondary"
          mat-flat-button
          text="ready to hit record? Once you're done, tap stop to either preview your take or redo the scene. When you're satisfied, feel free to move on to the next scene!"
          title="Start record">
          <mat-icon>fiber_manual_record</mat-icon>
          <span class="icon-text" *ngIf="this.isCoundownInProgress"
            >Get Ready 👌</span
          >
          <span class="icon-text" *ngIf="!this.isCoundownInProgress">{{
            this.chosenScene.stagePositions.length <= 1
              ? 'Record Voiceover'
              : 'Record'
          }}</span>
        </button>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #recording>
    <div [ngClass]="'stop'" class="scene-actions__icon__record">
      <button
        (click)="stopButtonClicked.emit()"
        [class.active]="true"
        class="cool-button gray"
        color="secondary"
        mat-flat-button>
        <mat-icon>stop</mat-icon>
        <span class="icon-text">Stop</span>
      </button>
    </div>
  </ng-template>
  <div class="scene-actions__icon">
    <button
      (click)="nextSceneClicked.emit()"
      [class.active]="true"
      [disabled]="isRecordingInProgress"
      class="cool-button gray"
      color="secondary"
      mat-mini-fab
      matTooltip="Go to next scene">
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
  <div class="scene-actions__icon menu mic-button">
    <div class="button-with-arrow">
      <button
        mat-button
        class="arrow"
        [matMenuTriggerFor]="micMenu"
        [disabled]="
          (microphoneListEmpty$ | async) ||
          isRecordingInProgress ||
          isCoundownInProgress
        "
        [matTooltip]="
          isRecordingInProgress || isCoundownInProgress
            ? 'Recording in progress'
            : 'No microphones found'
        ">
        <mat-icon>arrow_drop_up</mat-icon>
      </button>
      <button
        [class.active]="true"
        (click)="muteMic()"
        class="cool-button gray"
        color="secondary"
        mat-mini-fab>
        <mat-icon *ngIf="this.isAudioMuted" class="red">mic_off</mat-icon>

        <app-voice-detector-icon
          *ngIf="!this.isAudioMuted"></app-voice-detector-icon>
      </button>
    </div>

    <mat-menu #micMenu="matMenu">
      <button
        mat-menu-item
        class="device-list-item"
        *ngFor="let microphone of mediaDevicesService.microphones$ | async"
        (click)="mediaDevicesService.selectMicrophone(microphone.deviceId)">
        <mat-icon
          *ngIf="
            (mediaDevicesService.selectedMicrophoneId$ | async) ===
            microphone?.deviceId
          "
          >check
        </mat-icon>
        <span>{{ microphone.cleanedLabel }}</span>
      </button>
    </mat-menu>
    <!--        <span>Mic</span>-->
  </div>

  <div class="scene-actions__icon menu camera-button">
    <div class="button-with-arrow">
      <button
        mat-button
        class="arrow"
        [matMenuTriggerFor]="camMenu"
        [disabled]="
          (cameraListEmpty$ | async) ||
          isRecordingInProgress ||
          isCoundownInProgress
        "
        [matTooltip]="
          isRecordingInProgress || isCoundownInProgress
            ? 'Recording in progress'
            : 'No cameras found'
        ">
        <mat-icon>arrow_drop_up</mat-icon>
      </button>
      <button
        [matMenuTriggerFor]="camMenu"
        [disabled]="
          (cameraListEmpty$ | async) ||
          isRecordingInProgress ||
          isCoundownInProgress
        "
        [class.active]="true"
        class="cool-button gray"
        color="secondary"
        mat-mini-fab>
        <mat-icon>videocam</mat-icon>
      </button>
    </div>
    <mat-menu #camMenu="matMenu">
      <button
        mat-menu-item
        class="device-list-item"
        *ngFor="let camera of mediaDevicesService.cameras$ | async"
        (click)="mediaDevicesService.selectCamera(camera.deviceId)">
        <mat-icon
          *ngIf="
            (mediaDevicesService.selectedCameraId$ | async) === camera?.deviceId
          "
          >check
        </mat-icon>

        <span>{{ camera.cleanedLabel }}</span>
      </button>
    </mat-menu>
    <!--        <span>Camera</span>-->
  </div>
  <div
    class="scene-actions__icon switch-camera"
    *ngIf="(mediaDevicesService.cameras$ | async)?.length > 1">
    <button
      (click)="switchCamera()"
      [class.active]="true"
      class="cool-button gray"
      color="secondary"
      mat-mini-fab
      matTooltip="Switch Camera">
      <mat-icon>flip_camera_ios</mat-icon>
    </button>
    <!--        <span>Settings</span>-->
  </div>

  <div class="scene-actions__icon">
    <button
      [disabled]="isRecordingInProgress || isCoundownInProgress"
      (click)="editSceneClicked.emit()"
      [class.active]="true"
      class="cool-button gray"
      color="secondary"
      mat-mini-fab
      matTooltip="Edit Scene">
      <mat-icon>edit</mat-icon>
    </button>
    <!--        <span>Settings</span>-->
  </div>
</div>
