<div class="wrapper" *ngIf="sceneStatusUpdates">
  <button
    mat-mini-fab
    class="smaller-icon-button"
    *ngFor="let sceneStatus of sceneStatusUpdates; let i = index"
    [class]="sceneStatus.leftToRecord ? 'not-recorded' : 'recorded'"
    (click)="carouselDotClicked.emit(i)"
    [class.active]="i === currentIndex"
    [matTooltip]="sceneStatus.leftToRecord ? 'Recording Required' : 'Ready'">
    <mat-icon *ngIf="!sceneStatus.leftToRecord">check</mat-icon>
    <!--        <mat-icon>fiber_manual_record</mat-icon>-->
  </button>
</div>
