export interface IMediaModel {
  id: string;
  mediaStream: MediaStream;
  stagePositionId: string; /// The id of the stage position that the current media is on
}

export interface IFormAdditionalParams {
  email: string;
  projectId: string;
  sceneId: string;
  number: number;
  fileName: string;

  [key: string]: string | number; // Add this line
}

export enum StudioJoyRideEnum {
  STORY_LINE = 'story_line',
  TELEPROMPTER = 'teleprompter',
  EDIT = 'edit',
  START_RECORD = 'start_record',
}
