import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '../configuration.service';
import { Observable } from 'rxjs';
import { Stripe } from 'stripe';

@Injectable({
  providedIn: 'root',
})
export class PlansApiService {
  path = `${this.config.apiIP}/auth/plans`;
  redirectPath = `${this.config.apiIP}`;

  constructor(
    private http: HttpClient,
    private config: ConfigurationService
  ) {}

  unifiedPlan(): Observable<{ product: Stripe.Product; price: Stripe.Price }> {
    return this.http.get<any>(`${this.path}/unified`);
  }

  listPlans(): Observable<{ product: Stripe.Product; price: Stripe.Price }[]> {
    return this.http.get<any>(`${this.path}/list`);
  }

  createSetupIntent(data: { productId: string; priceId: string }) {
    return this.http.post<any>(`${this.path}/create-setup-intent`, data);
  }

  createSubscription(data: { setupIntentId: string; productId: string }) {
    return this.http.post<any>(`${this.path}/create-subscription`, data);
  }

  confirmPayment(data: { setupIntentId: string; productId: string }) {
    return this.http.post<any>(`${this.path}/confirm-payment`, data);
  }

  //It's a temporary endpoint.
  //This endpoint is used to redirect from the payments page to the login page, so it should be a public route
  redirect() {
    return this.http.get<any>(`${this.redirectPath}/redirect-to-login`);
  }
}
