import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {playerFactory} from '../../app.module';
import {LottieCacheModule, LottieModule} from 'ngx-lottie';
import {ArchiveFilterPipe} from 'src/app/pipes/archiveFilter.pipe';
import {ThumbnailPipe} from 'src/app/pipes/post-production-pipes';
import {MaterialModule} from '../material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FullScreenLoadingComponent} from '../../components/minor/full-screen-loading/full-screen-loading.component';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';
import {FileDropComponent} from '../../components/file-drop/file-drop.component';
import {ColorPickerRGBADirective} from '../../directives/color-picker-rgba.directive';
import {ColorPickerModule} from 'ngx-color-picker';
import {RenderedDirective} from 'src/app/directives/rendered.directive';
import {FeatureFlagDirective} from '../../directives/feature-flag.directive';
import {LoadingIndicatorComponent} from '../../components/minor/loading-indicator/loading-indicator.component';

@NgModule({
    declarations: [
        ArchiveFilterPipe,
        ThumbnailPipe,
        FullScreenLoadingComponent,
        LoadingIndicatorComponent,
        FileDropComponent,
        ColorPickerRGBADirective,
        RenderedDirective,
        FeatureFlagDirective,
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        LottieModule.forRoot({player: playerFactory}),
        LottieCacheModule.forRoot(),
        ColorPickerModule,
    ],
    exports: [
        LottieModule,
        LottieCacheModule,
        CommonModule,
        MaterialModule,
        FormsModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FullScreenLoadingComponent,
        LoadingIndicatorComponent,

        FileDropComponent,
        ColorPickerRGBADirective,
        RenderedDirective,
        ColorPickerModule,
        FeatureFlagDirective,
    ],
})
export class SharedModule {
}
