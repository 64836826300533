<div class="wrapper">
  <div
    [class.hideme]="this.recordingManager.isCountdownInProgress$ | async"
    *ngIf="stagePositions"
    class="media-overlay"
    id="positions">
    <div
      *ngFor="let position of stagePositions"
      [style.height]="position.height + '%'"
      [style.left]="position.x + '%'"
      [style.top]="position.y + '%'"
      [style.width]="position.width + '%'"
      class="position">
      <ng-container
        *ngIf="
          recordingManager.mediaModel$ | async as mediaModel;
          else videoNotAvailable
        ">
        <video
          #currentVideo
          *ngIf="mediaModel.mediaStream && (animationCreated | async)"
          [muted]="'muted'"
          [srcObject]="mediaModel.mediaStream"
          autoplay
          playsinline></video>
      </ng-container>
      <ng-template #videoNotAvailable>
        <app-silhouette [stagePositions]="stagePositions"></app-silhouette>
      </ng-template>
    </div>
  </div>
  <dynamic-lottie
    *ngIf="
      stagePositions && !(this.recordingManager.isCountdownInProgress$ | async)
    "
    [config]="{
      layout: scene.composition.layouts[0],
      dynamicLottieChanges: take.copy.dynamicLottieChanges,
      basePath: designBasePath,
    }"
    [lottieOptions]="{ loop: 0 }"
    (animationCreated)="animationCreated.next(true)"
    [to-loop]="true"
    class="lottie-overlay"></dynamic-lottie>

  <!-- Countdown -->
  <div
    *ngIf="this.recordingManager.isCountdownInProgress$ | async"
    class="countdown-video">
    <video
      (ended)="this.recordingManager.initiateRecordingAsync()"
      [src]="COUNTDOWN_VIDEO_RELATIVE_PATH"
      autoplay
      height="100%"
      playsinline
      preload="auto"
      [muted]="'muted'"
      width="100%"></video>
  </div>
</div>
