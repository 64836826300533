import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../configuration.service';
import { FeatureGroupEnum, User } from '../../../models/user';
import { BehaviorSubject } from 'rxjs';
import { IOnBoardingQuestionConfigs } from 'src/app/models/defines';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  INVITE_KEY = 'InviteID';
  VP_KEY = 'vp';
  VP_HUBSPOT_KEY = 'marketing_value_proposition';
  googleApiEndpoint = '';
  path: string = '/auth/user';
  private userChangedBehaviorSubject = new BehaviorSubject<any>(null);
  userChangedObservable = this.userChangedBehaviorSubject.asObservable();
  private saveResponseBehaviourSubject = new BehaviorSubject<any>('');
  messageChangedObservable = this.saveResponseBehaviourSubject.asObservable();
  isIOS: boolean;

  constructor(
    private http: HttpClient,
    private config: ConfigurationService
  ) {
    this.googleApiEndpoint =
      config.googleFunction + '/update-db-and-convertkit';
    this.path = this.config.apiIP + this.path;
    this.isIOS = this.isUserFromIOS();
  }

  // Todo: save path in a variable and one location only -> /auth/user
  me(): Promise<User> {
    return this.http.post<User>(this.path + '/me', '').toPromise();
  }

  public registerToDBAndConvertkit(
    invite: string,
    email: string,
    name: string
  ) {
    this.http
      .post<any>(this.googleApiEndpoint, { invite, email, name })
      .subscribe((reuslt) => {
        console.log('googleApiEndpoint', reuslt);
      });
  }

  finishOnboarding(onboardingResults: IOnBoardingQuestionConfigs[]) {
    return this.http.post<User>(
      this.path + '/onboarding',
      {
        onboardingResults: onboardingResults,
      },
      { withCredentials: true }
    );
  }

  async updateDBandConvertkit(
    email: string,
    name: string,
    invite: string = null
  ) {
    if (invite === null) {
      invite = localStorage.getItem(this.INVITE_KEY);
    }
    // Hopfully we got it from the localstorage if not provided before
    if (invite !== null) {
      this.registerToDBAndConvertkit(invite, email, name);
    }
  }

  isMobileUser(): boolean {
    let isMobile;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      isMobile = true;
    } else {
      isMobile = false;
    }
    return isMobile;
  }

  getBrowser(): string {
    const userAgent = navigator.userAgent;
    let browserName: string = 'Unknown';

    switch (true) {
      case userAgent.indexOf('Firefox') > -1:
        browserName = 'Mozilla Firefox';
        break;
      case userAgent.indexOf('OPR') > -1 || userAgent.indexOf('Opera') > -1:
        browserName = 'Opera';
        break;
      case userAgent.indexOf('Chrome') > -1:
        browserName = 'Google Chrome';
        break;
      case userAgent.indexOf('Safari') > -1 &&
        userAgent.indexOf('Chrome') === -1:
        browserName = 'Safari'; 
        break;
      case userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1:
        browserName = 'Internet Explorer';
        break;
      default:
        browserName = 'Unknown';
    }

    return browserName;
  }

  isUserFromIOS() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
}
