<div style="height: 10px" *ngIf="loadingData; else hello">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<ng-template #hello>
  <div class="dashboard-page padded" [class.hosted]="this.hosted">
    <div class="header row-spacebetween">
      <h2 class="header__title no-margin">
        <app-sidenav-toggle title="Assets"></app-sidenav-toggle>
      </h2>
      <button
        *ngIf="this.dialogType !== AssetType.VIDEO"
        mat-raised-button
        color="primary"
        (click)="generateImage()" fxHide fxShow.gt-sm>
        <mat-icon> auto_fix_normal</mat-icon>
        Generate
      </button>

      <button mat-mini-fab color="primary" (click)="generateImage()" fxShow fxHide.gt-sm>
        <mat-icon>auto_fix_normal</mat-icon>
      </button>
    </div>

    <ng-container *ngIf="selectedFolderSubject | async as selectedFolder">
      <div class="container">
        <div class="breadcrumbs">
          <button mat-button class="breadcrumb" (click)="selectFolder('/')">
            <h3 class="breadcrumb-text">Folders</h3>
          </button>
          <mat-icon *ngIf="selectedFolder !== '/'">chevron_right</mat-icon>
          <button
            *ngIf="selectedFolder !== '/'"
            mat-button
            class="breadcrumb"
            (click)="selectFolder('/')">
            <h3 class="breadcrumb-text">
              {{ selectedFolder }}
            </h3>
          </button>
        </div>

        <div class="row-centered" *ngIf="selectedFolder === '/'">
          <mat-grid-list
            class="folders"
            [cols]="cols"
            rowHeight="80px"
            gutterSize="20px">
            <mat-grid-tile
              class="folder-wrapper"
              *ngFor="let folder of foldersWithoutParents | keyvalue"
              matRipple
              matRippleColor="primary"
              (click)="selectFolder(folder.key)">
              <div class="folder">
                <div class="icon">
                  <mat-icon>folder_icon</mat-icon>
                </div>
                <div class="folder-name-wrap">
                  <h4 [matTooltip]="folder.key">
                    {{ folder.key }}
                  </h4>
                  <h5>
                    {{ countNonFolders(folder) }}
                  </h5>
                </div>
                <button
                  mat-icon-button
                  class="menu"
                  (click)="$event.stopPropagation()"
                  [matMenuTriggerFor]="folderMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #folderMenu="matMenu">
                  <button mat-menu-item (click)="renameFolder(folder.key)">
                    <mat-icon>edit</mat-icon>
                    Rename
                  </button>
                  <button mat-menu-item (click)="deleteFolder(folder.key)">
                    <mat-icon>delete</mat-icon>
                    Delete
                  </button>
                </mat-menu>
              </div>
            </mat-grid-tile>

            <mat-grid-tile
              class="folder-wrapper new"
              matRipple
              matRippleColor="primary"
              (click)="openNewFolderDialog()">
              <div class="folder">
                <div class="icon">
                  <mat-icon>create_new_folder_icon</mat-icon>
                </div>
                <div class="folder-name-wrap">
                  <h4>New Folder</h4>
                  <!--            <h5>-->
                  <!--              {{folder.numberOfFiles}}-->
                  <!--            </h5>-->
                </div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>

          <!--      <app-file-drop></app-file-drop>-->
        </div>
      </div>

      <div class="container">
        <h3 class="files-title" *ngIf="selectedFolder === '/'">Files</h3>
        <div class="row-centered">
          <app-file-drop
            accepts=".svg,.png,.mp4"
            [uploadAPI]="assetsApi.upload"
            [extraData]="{ folder: this.selectedFolder }"
            (uploadFinished)="getAllAssets()"></app-file-drop>
        </div>
      </div>
    </ng-container>
    <div class="container" *ngIf="filesOfCurrentFolder">
      <mat-grid-list
        class="files"
        [cols]="cols"
        rowHeight="280px"
        gutterSize="20px">
        <mat-grid-tile
          class="file-wrapper"
          *ngFor="let file of filesOfCurrentFolder"
          [class.selected]="file === selectedAsset"
          matRipple
          matRippleColor="primary"
          (click)="selectFile(file)">
          <div class="file">
            <dynamic-asset-content [asset]="file"></dynamic-asset-content>

            <!-- <div
              *ngIf="!(file.mimetype === MimetypeEnum.VIDEO); else video"
              class="asset-file"
              [style.background-image]="'url(' + getFullPath(file.filePath) + ')'"
            ></div>
            <img
              *ngIf="!(file.mimetype === 'video/mp4'); else video"
              class="asset-file"
              src="'url(' + getFullPath(file.filePath) + ')'"
            />
            <ng-template #video>
              <video autoplay class="video-file">
                <source [src]="getFullPath(file.filePath)" type="video/mp4" />
              </video>SS
            </ng-template> -->
            <div class="file-name-wrap">
              <h4 [matTooltip]="file.displayName">
                {{ file.displayName }}
              </h4>
              <h5></h5>
              <button
                mat-icon-button
                class="menu"
                (click)="$event.stopPropagation()"
                [matMenuTriggerFor]="fileMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #fileMenu="matMenu">
                <button mat-menu-item [matMenuTriggerFor]="moveToFolder">
                  <mat-icon>drive_file_move</mat-icon>
                  Move
                </button>
                <button mat-menu-item (click)="renameFile(file)">
                  <mat-icon>edit</mat-icon>
                  Rename
                </button>
                <button mat-menu-item (click)="deleteFile(file)">
                  <mat-icon>delete</mat-icon>
                  Delete
                </button>
              </mat-menu>

              <mat-menu #moveToFolder="matMenu">
                <ng-container *ngIf="foldersSubject | async as folders">
                  <button
                    mat-menu-item
                    *ngFor="let folder of folders | keyvalue"
                    (click)="moveFile(file, folder.key)"
                    [disabled]="folder.key === file.folder">
                    {{ folder.key }}
                  </button>
                </ng-container>
              </mat-menu>
            </div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
</ng-template>
<div class="bottom-buttons" *ngIf="hosted">
  <button [mat-dialog-close]="null" mat-raised-button>Cancel</button>
  <button
    [mat-dialog-close]="selectedAsset"
    [disabled]="!selectedAsset"
    color="primary"
    mat-raised-button>
    Select
  </button>
</div>
