import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  ColorPalettesService,
  IColorPallete,
} from '../../../../services/utils/color-palettes.service';
import { BrandingAuthApiService } from '../../../../services/api/auth/branding-auth-api.service';
import { IBranding } from '../../../../models/user';
import { ProfileService } from '../../../../services/show/profile.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { WalkthroughPanelService } from 'src/app/services/walkthrough-panel.service';
import { TaskTypeEnum } from 'src/app/models/walkthrough';

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.scss'],
})
export class BrandingComponent implements OnInit {
  @Input()
  realPlatformMode = true;

  @Input()
  branding: IBranding;

  decodeUrl = decodeURIComponent(
    'https://content.shuffll.com/users-assets/aviv%40castingdepot.com%2Fassets%2Ffile%2F92f1015c-2f69-4c89-8413-46b767d96bc2.svg'
  );
  // branding: IBranding;

  displayWaterMark = true;
  assetBasePath: string;
  loadingData = true;
  selectedColorPallete: IColorPallete;
  collorPalleteFormControl = new FormControl();
  designFormControl = new FormControl();
  logoColorPallete: IColorPallete = {
    name: 'LOGO',
    value: { colorPrimary: '', colorSecondary: '', colorExtra: '' },
  };
  defaultPalette: IColorPallete = {
    name: 'Default',
    value: { colorPrimary: '', colorSecondary: '', colorExtra: '' },
  };
  customPalette: IColorPallete = { name: 'Custom', value: null };
  showCustomPallete = false;
  companyName = '';
  companyAbout = '';
  taskType: TaskTypeEnum = TaskTypeEnum.BRANDING;

  constructor(
    private _snackBar: MatSnackBar,
    public colorPalettesService: ColorPalettesService,
    public brandingAPI: BrandingAuthApiService,
    public profile: ProfileService,
    private config: ConfigurationService,
    private walkthroughPanelService: WalkthroughPanelService
  ) {
    this.assetBasePath = this.config.baseCdnUrl;
  }

  newColorSelected() {
    let newPallet = this.collorPalleteFormControl.value;

    console.log('New Colors', newPallet);
    if (newPallet) {
      this.branding.colors = newPallet.value;
      this.updateBranding('colors');
    }
  }

  defaultBranding() {
    this.branding.colors = {
      colorPrimary: '#84b636',
      colorSecondary: '#fff',
      colorExtra: '#84b636',
    };
    this.branding.logo = '/files/platform/nvidia-demo/nvidia_logo.png';
  }

  public updateBranding(property, updateMessage = null) {
    if (!this.realPlatformMode) {
      /// For local changes
      this.profile.updateUserBranding(this.branding);
      return;
    }
    /// We need to avoid sending multiple updates simultaniusly
    this.loadingData = true;
    this.brandingAPI
      .updateProperty(property, this.branding[property])
      .subscribe((x) => {
        // this.profile.user.branding = this.branding;

        if (updateMessage) {
          this._snackBar.open(updateMessage, 'Dismiss', { duration: 3000 });
          this.companyAbout = this.branding.about;
          if (this.branding.company.domain) {
            this.companyName = this.branding.company.domain.split('.')[0];
          }

          this.profile.updateUserBranding(this.branding);
        }

        this.loadingData = false;
      });
  }

  saveCompanyStory() {
    this.branding.about = this.companyAbout;
    this.updateBranding('about', 'Brand Story Updated');
  }

  saveCompanyName() {
    this.branding.company = { name: this.companyName };
    this.updateBranding('company', 'Brand Name Updated');
  }

  getBrandingFromApi(updateToLogoColors: boolean) {
    this.loadingData = true;
    this.brandingAPI.get().subscribe((branding: IBranding) => {
      console.log(branding);
      this.loadBranding(branding, updateToLogoColors);
    });
  }

  public loadBranding(branding: IBranding, updateToLogoColors = false) {
    if (branding) {
      this.setDefaultColors(branding);

      this.branding = branding;

      if (updateToLogoColors) {
        this.setLogoColors(branding);
        this.collorPalleteFormControl.setValue(this.logoColorPallete);
        this.newColorSelected();
      }
      if (this.branding?.company?.domain) {
        this.companyName = this.branding.company.domain.split('.')[0];
      }
      this.companyAbout = this.branding?.about ?? '';
      if (!this.walkthroughPanelService.checkIfTaskCompleted(this.taskType)) {
        this.walkthroughPanelService.tasksCompleted$.next(this.taskType);
      }

      /// Update the user as well
    }
    this.loadingData = false;
  }

  ngOnInit(): void {
    if (this.profile.user$.value.branding.company.name) {
      this.companyName = this.profile.user$.value.branding.company.name;
    }
    this.designFormControl.setValue('Proffessional');
    this.designFormControl.disable();

    // this.getBrandingFromApi(false);

    if (this.realPlatformMode) {
      this.getBrandingFromApi(false);
    } else {
      /// Load from local
      if (this.branding) {
        console.log('loading default branding', this.branding);
        this.loadBranding(this.branding);
      }
    }
  }

  newDesignSelected() {
    this.updateBranding('design');
  }

  singleColorChange(ev) {
    console.log(ev);
    this.showCustomPallete = true;
    this.collorPalleteFormControl.setValue(this.customPalette, {
      emitEvent: false,
    });
    this.updateBranding('colors', 'Brand Colors Updated');
  }

  setDefaultColors(branding: IBranding) {
    // PREVIOUS COLORS
    this.defaultPalette.value.colorPrimary = branding.colors.colorPrimary;
    this.defaultPalette.value.colorExtra = branding.colors.colorExtra;
    this.defaultPalette.value.colorSecondary = branding.colors.colorSecondary;

    /// Set the picker to the previuos color selection - we call it 'default'
    this.collorPalleteFormControl.setValue(this.defaultPalette, {
      emitEvent: false,
    });

    ///LOGO COLORS
    this.setLogoColors(branding);
  }

  private setLogoColors(branding: IBranding) {
    if (branding.logoColors) {
      this.logoColorPallete.value.colorPrimary =
        branding.logoColors.colorPrimary;
      this.logoColorPallete.value.colorExtra = branding.logoColors.colorExtra;
      this.logoColorPallete.value.colorSecondary =
        branding.logoColors.colorSecondary;
    }
    this.profile.user$.value.branding = branding;
  }
}
