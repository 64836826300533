import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  geteEditSpecificStyleUrl,
  getStylesGeneralUrl,
} from 'src/app/constants/private/urls/projects/edit-style.urls';
import {
  getDetailsEditUrl,
  getSaveEditUrlPUT,
} from 'src/app/constants/private/urls/projects/edit.urls';
import { ISubtitleStyle, SUBTITLE_STRATEGY } from 'subtitles-helper/dist/types';
const STRATEGY_URL = '/strategy';

@Injectable({
  providedIn: 'root',
})
export class EditStyleApiService {
  constructor(private http: HttpClient) {}

  updateSubtitleStyleProperty$(params: {
    projectId: string;
    editId: string;
    settingId: string;
    key: string;
    value: any;
  }) {
    const putPropertyToEdittUrl = geteEditSpecificStyleUrl(
      params.projectId,
      params.editId,
      params.settingId
    );
    return this.http.put<ISubtitleStyle>(putPropertyToEdittUrl, params);
  }

  updateStrategyProperty$(params: {
    projectId: string;
    editId: string;
    strategy: SUBTITLE_STRATEGY;
  }) {
    const url = getSaveEditUrlPUT(params.projectId, params.editId);

    return this.http.put<SUBTITLE_STRATEGY>(`${url}${STRATEGY_URL}`, params);
  }
}
