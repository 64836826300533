<div class="tracks-controls">
  <div class="left-buttons" id="tracksWrapper" #tracksWrapper>
    <!-- <button mat-icon-button (click)="cutScene()">
          <mat-icon>content_cut</mat-icon>
        </button> -->
    <button mat-icon-button (click)="deleteScene()">
      <mat-icon>delete_outline</mat-icon>
    </button>
  </div>
  <div
    class="center-buttons"
    *ngIf="this.newTimelineDuration$ | async as timelineDuration">
    {{ currentTime | date: 'mm:ss' }}/{{ timelineDuration | timeFormat }}

    <button mat-icon-button (click)="pauseOrPlayPressed()" tabindex="0">
      <mat-icon> {{ this.isRunning ? 'pause' : 'play_arrow' }}</mat-icon>
    </button>
  </div>
  <div class="right-buttons">
    <button mat-icon-button (click)="zoom(1.2)">
      <mat-icon>zoom_in</mat-icon>
    </button>
    <button mat-icon-button (click)="zoom(0.8)">
      <mat-icon>zoom_out</mat-icon>
    </button>
  </div>
</div>
<div class="tracks-and-icons">
  <div class="track-icons">
    <mat-icon class="small-icon subtitles-icon" matTooltip="Subtitles Track"
      >subtitles</mat-icon
    >
    <mat-icon
      class="small-icon animation-icon"
      matTooltip="Animation Overlay Track"
      >animation</mat-icon
    >
    <mat-icon class="small-icon video-icon" matTooltip="Video Track"
      >slideshow</mat-icon
    >
    <mat-icon class="small-icon audio-icon" matTooltip="Background Music Track"
      >audiotrack</mat-icon
    >
  </div>

  <div class="tracks black-scrollbar">
    <div id="lines-holder" class="lines-holder">
      <div
        id="ruler"
        #ruler
        class="time-bar-ruler"
        (mousemove)="mouseOverTracks($event)"
        (click)="mouseClickTracks($event)"
        (mouseleave)="mouseLeavesTrack()"
        (mouseenter)="mouseEntersTrack()">
        <div class="ruler-numbers">
          <div
            class="ruller-number-section"
            *ngFor="let x of timelineSections"
            [style.width]="this.baseWidth * TICKS_PER_NUMBER + 'px'">
            <div class="ticks"></div>
            <div class="ruler-inner-number">{{ x }}</div>
          </div>
        </div>
      </div>

      <!-- follow white line -->
      <div
        class="follow-marker"
        [class.hidden]="!showFollowLineParent || hideFollowLineChildren"
        style="pointer-events: none"
        [style.left]="this.mouseposition + 'px'">
        <div class="follow-marker-tooltip">
          {{ followLineTime | date: 'mm:ss.S' }}
        </div>
      </div>

      <!-- Purple Stable Line -->
      <div
        *ngIf="editRoomStateService.markerPosition$ | async as markerPosition"
        #markerPosition
        class="stable-marker"
        cdkDragBoundary="#ruler"
        [style.left]="markerPosition + 'px'"
        cdkDragLockAxis="x"
        cdkDrag
        (cdkDragMoved)="onMarkerDragged($event)"
        (cdkDragEnded)="onMarkedEnded($event)"
        (cdkDragStarted)="onMarkedDragStarted($event)">
        <svg
          width="12px"
          height="12px"
          viewBox="0 0 13 13"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          class="trimmer">
          <g
            id="stroke-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd">
            <g
              id="trimer"
              transform="translate(-189.000000, -503.000000)"
              fill="#553bc9">
              <polygon
                id="poly"
                points="189 503 202 503 202 511 195.5 516 189 511"></polygon>
            </g>
          </g>
        </svg>
      </div>

      <div class="tracks-holder">
        <div
          class="subtitle-track"
          [style.width]="
            !videoEditTakes.length ? screenWidth - 300 + 'px' : 'unset'
          ">
          <!--     Subtitle Group     -->
          <div
            class="subtitle-group"
            [ngClass]="'subtitle-group-' + i"
            *ngFor="let videoEditTake of this.videoEditTakes; let i = index"
            [style.width]="videoEditTake.style.width">
            <ng-container
              *ngIf="
                videoEditTake?.take?.copy?.transcript
                  ?.sentences as transcriptSentences
              ">
              <div
                *ngIf="transcriptSentences.length > 0"
                class="subtitle-holder">
                <!-- Subtitble-->
                <div
                  class="subtitle unselectable"
                  [cdkDragBoundary]="'.subtitle-group-' + i"
                  *ngFor="let subtitle of transcriptSentences; let j = index"
                  [cdkDragFreeDragPosition]="{
                    x: (subtitle.start / 1000) * this.baseWidth,
                    y: 0,
                  }"
                  [style.width]="
                    ((subtitle.end - subtitle.start) * this.baseWidth) / 1000 +
                    'px'
                  "
                  [matTooltip]="subtitle.text"
                  cdkDrag
                  cdkDragLockAxis="x">
                  {{ subtitle.text }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div
          cdkDropList
          cdkDropListOrientation="horizontal"
          class="video-track"
          [style.width]="
            !videoEditTakes.length ? screenWidth - 300 + 'px' : 'unset'
          "
          [id]="videoTrackerId">
          <!--    [style.width]="simpleLength * timePeriod.length"-->

          <div
            class="take"
            *ngFor="let videoScene of this.videoEditTakes; let i = index"
            (mouseleave)="hideFollowLineChildren = false"
            (mouseenter)="hideFollowLineChildren = true"
            [style.width]="videoScene.style.width"
            mwlResizable
            (resizing)="resizing($event)"
            [class.selected]="videoScene === this.selectedScene"
            (click)="selectScene(videoScene); $event.stopPropagation()"
            [validateResize]="validateResize.bind(this)"
            (resizeEnd)="onResizeEnd({ scene: videoScene, data: $event })"
            [enableGhostResize]="true"
            [resizeSnapGrid]="{ left: 5, right: 5 }">
            <div
              class="resize-handle-left"
              mwlResizeHandle
              [resizeEdges]="{ left: true }">
              ||
            </div>

            <div class="inner-draggable">
              <div class="scene-details">
                <div class="scene-number">
                  {{ videoScene.name }} - Take
                  {{ videoScene.take.number + 1 }}
                </div>
                <div class="scene-duration">
                  {{ videoScene.durationOnTrack / 1000 | number: '1.1-1' }}s
                </div>
              </div>
              <video-thumbnails
                [videoUrl]="videoScene.take?.videoLayers[0]?.uploadPath"
                [duration]="videoScene.duration / 1000"
                class="thumbnails-wrapper"
                [style.width]="videoScene.duration * this.baseWidth + 'px'"
                [style.left]="-1 * videoScene.trimStart * this.baseWidth + 'px'"
                [style.margin-right]="
                  -1 * videoScene.trimEnd * this.baseWidth + 'px'
                ">
              </video-thumbnails>
              <!--              <div class="lottie-layer">Lottie layer</div>-->

              <div
                class="resize-handle-right"
                mwlResizeHandle
                [resizeEdges]="{ right: true }">
                ||
              </div>
            </div>
            <div *ngIf="!videoEditTakes.length" style="font-size: 16px">
              Add scenes
            </div>
          </div>
        </div>

        <div class="audio-track" id="selected-audio-scenes">
          <div
            class="take"
            *ngFor="let audioScene of audioEditTakes; let i = index"
            (mouseleave)="hideFollowLineChildren = false"
            (mouseenter)="hideFollowLineChildren = true"
            (click)="selectScene(audioScene); $event.stopPropagation()"
            [style]="audioScene.style"
            mwlResizable
            [validateResize]="validateResize"
            (resizeEnd)="onResizeEnd({ scene: audioScene, data: $event })"
            [enableGhostResize]="true"
            [resizeSnapGrid]="{ left: 5, right: 5 }">
            <div
              class="resize-handle-left"
              mwlResizeHandle
              [resizeEdges]="{ left: true }">
              ||
            </div>
            <div class="inner-draggable">
              <div class="scene-details">
                <div class="scene-number">
                  {{ i < 10 ? '0' : '' }}{{ i + 1 }}
                </div>
                <div class="scene-duration">
                  {{
                    audioScene.duration -
                      audioScene.trimEnd -
                      audioScene.trimStart | number: '1.1-1'
                  }}s
                </div>
              </div>

              <div
                class="wave-holder"
                [style.width]="audioScene.duration * baseWidth + 'px'"
                [style.left]="-1 * audioScene.trimStart * baseWidth + 'px'">
                <app-waveform
                  [audioUrl]="this.baseCdnUrl + audioScene.audioPath"
                  [height]="42"></app-waveform>
              </div>
            </div>

            <div
              class="resize-handle-right"
              mwlResizeHandle
              [resizeEdges]="{ right: true }">
              ||
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
