import {Component, Input} from '@angular/core';
import {IScene} from "../../../../../../../models/project/scene-model";

@Component({
  selector: 'studio-static-scene',
  templateUrl: './static-scene.component.html',
  styleUrls: ['./static-scene.component.scss']
})
export class StaticSceneComponent {

  @Input() scene: IScene;
  @Input() designBasePath: string;

  
}
