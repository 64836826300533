import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,} from '@angular/core';
import {AnimationItem} from 'lottie-web';
import {ConfigurationService} from 'src/app/services/configuration.service';
import {IDynamicLottieChange} from 'lottie-json-helper/lib/types';
import {ArtDirectorService} from 'src/app/services/art-director.service';
import {v4 as uuidv4} from 'uuid';
import {LottiePlayerService} from 'src/app/services/lottie-player.service';
import {ILayout} from 'src/app/models/defines';
import {IDesignGroup, IExtendedDesignGroup} from "../../../models/design.model";


@Component({
    selector: 'design-list',
    templateUrl: './design-list.component.html',
    styleUrls: ['./design-list.component.scss'],
})
export class DesignListComponent implements OnInit, OnChanges {

    // formats: IFormat[];
    /// Will only look at the first
    selectedDesigns: IDesignGroup[];
    // kee the lottie icons of the formats
    animatedFormatIcons = [];
    baseCdnPath: string;
    hidePreviewAnimation: boolean = true;
    currentPlayingFormatAnimationId: string; /// To know which format needs to display his preview and disable in everything else.
    personalizedDynamics: IDynamicLottieChange[];
    animationItems = new Map<string, AnimationItem>();

    @Input('displayCreators') displayFormatCreatorList = true;
    @Input('clickToNavigate') clickToNavigate = false;
    @Input('enableAllDesigns') enableAllDesigns = false;
    @Input('suggestedDesigns') suggestedDesigns: IExtendedDesignGroup[] = [];
    @Input('privateDesigns') privateDesigns: IExtendedDesignGroup[] = [];

    @Output() onFormatSelected = new EventEmitter<IDesignGroup>();
    @Output() newSuggestion = new EventEmitter<boolean>();

    @Input('filter') filter = null;
    @Input('expend') expand = 0;
    @Input('autoscroll') autoscroll = true;
    reveal = false;

    constructor(private config: ConfigurationService, private artDirector: ArtDirectorService, private lottiePlayer: LottiePlayerService) {
        this.baseCdnPath = this.config.baseCdnUrl;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.suggestedDesigns) {
            this.suggestedDesigns.forEach((suggestedFormat) => {
                const iconLayout: ILayout = this.createIconLayer(suggestedFormat);
                suggestedFormat.iconLayout = iconLayout;
            });
        }
        if (changes.privateDesigns) {
            this.privateDesigns.forEach((privateFormat) => {
                const iconLayout: ILayout = this.createIconLayer(privateFormat);
                privateFormat.iconLayout = iconLayout;
            });
        }
    }

    ngOnInit(): void {
        this.personalizedDynamics = this.artDirector.extractDynamicLottieByUserBranding();
    }

    playAnimation(id: string) {
        // this.lottieOptions$.next({ autoplay: true });
        const animationItem = this.animationItems.get(id);
        if (!animationItem) return;

        this.lottiePlayer.playAnimation(animationItem);
        // this.hidePreviewAnimation = false;
        // this.currentPlayingFormatAnimationId = id;
        // // let animation = this.animatedFormatIcons[id];
        // // if (animation) {
        // //   animation.play();
        // // }
    }

    pauseAnimation(id: string) {
        // this.lottieOptions$.next({ autoplay: false });

        const animationItem = this.animationItems.get(id);
        if (!animationItem) return;

        this.lottiePlayer.goToAndPause(animationItem, 1);
        // this.hidePreviewAnimation = true;
        // this.currentPlayingFormatAnimationId = null;
        // // let animation = this.animatedFormatIcons[id];
        // // if (animation) {
        // //   animation.stop();
        // // }
    }

    public animationCreated(anmimationItem: AnimationItem, id: string) {
        this.animationItems.set(id, anmimationItem);
        this.lottiePlayer.goToAndPause(anmimationItem, 1);
    }

    select(event) {
        if (event) {
            // console.log(event)
            this.onFormatSelected.next(event?.options?.length ? event?.options[0]?._value : null);
        }
    }

    public generateNewSuggestionDesigns() {
        this.newSuggestion.emit(true);
    }

    private createIconLayer(suggestedFormat: IExtendedDesignGroup) {
        const id = uuidv4();
        const iconLayout: ILayout = {
            _id: id, lottiePath: suggestedFormat.icon,
        };
        return iconLayout;
    }
}
