import {
  ExportQualityEnum as ExportQualityEnum,
  IPathAndDash,
  ISceneAndEditTakes,
} from '../../defines';
import {
  EditJobStatusEnum as EditJobStatusEnum,
  VideoEditTake,
  ISceneStyle,
} from '../../job/edit-job-schema';
import { ICommonSceneData, IScene } from '../scene-model';
import { ITakeWithLottieComposed, ITake, ITakeInDTO } from '../take/take-model';
import {
  IBasicEditPlugins,
  IEditPlugins,
  IEditPluginsInDTO,
  IEditPluginsOutDTO,
} from './subtitles/styles/edit-styles.model';

export enum AspectRatioEnum {
  _16x9 = '16/9',
  _9x16 = '9/16',

  _1x1 = '1/1',
}

export interface IExportUrls {
  originalExported?: IPathAndDash;
  exportedWithAudioClean?: IPathAndDash;
  maxineUrls?: IPathAndDash;
}

export interface ITrim {
  start: number;
  end: number;
}

export interface ITrimLayers {
  videoTrims: ITrim | null;
  lottieTrims: ITrim;
}

export interface IBasicSceneTakePair {
  sceneId: string;
  takeId: string;
  trims: ITrimLayers;
}

export interface ISelectedShots {
  selectedShots: IBasicSceneTakePair[];
}

// sceneId?: string;
// duration?: number;
// trimStart?: number;
// trimEnd?: number;
// title?: string;

// style?: ISceneStyle;
// durationOnTrack?: number; // Only for client
// takeId: string;
// baseVolume?: number;
// fadeType?: string;
// secondsToFadeInAudio?: number;
// secondsToFadeOutAudio?: number;
// transitionDurationVideo?: number;
// calculatedThumbnails?: IThumbnail[];
// isPlaying?: boolean; // Only for client
interface IBasicEditTake {
  id: string;
  trimStart: number;
  trimEnd: number;
  duration: number;
  baseVolume: number;
  fadeType: string;
  secondsToFadeInAudio: number;
  secondsToFadeOutAudio: number;
  transitionDurationVideo: number;
}

export interface IVideoEditTakeInDTO extends IBasicEditTake {
  take: ITakeInDTO;
  trims: ITrimLayers;
}

export interface ICommonLocalEditTake extends IBasicEditTake {
  style: ISceneStyle;
  durationOnTrack: number;

  updateTrims(): void;
}

export interface IVideoEditTake extends ICommonLocalEditTake {
  take: ITakeWithLottieComposed; /// Basic data of take, id duration and not much more so we don't use the local take
  sceneId: string;
  name: string;
  isPlaying: boolean;
  trims: ITrimLayers;
}

export interface IVideoEditOutDTO extends IBasicEditTake {}

export interface IAudioEditTake extends ICommonLocalEditTake {
  audioPath: string; // path to the audio that the user upload/generated bg music :D
  fileName: string;
  startTime: number; // getting it from the user
  endTime: number; // getting it from the user ( we have duration so maybe this is not relevant )
  isPlaying?: boolean;
}

export interface IBasicEditModel {
  toMaxine: boolean;
  exportQuality: ExportQualityEnum;
  aspectRatio: AspectRatioEnum;
  plugins: IBasicEditPlugins;
}

interface ISharedDataFromInAndOut {
  id: string;
  status: EditJobStatusEnum;
  createdAt: Date;
  exports: IExportUrls;
}

export interface IEditInDTO extends IBasicEditModel, ISharedDataFromInAndOut {
  videoEditTakes: IVideoEditTakeInDTO[];
  plugins: IEditPluginsInDTO;
}

export interface IExportEditOutDTO extends IBasicEditModel, ISelectedShots {
  projectId: string;
  plugins: IEditPluginsOutDTO;
}

export interface IExportEditJob
  extends IBasicEditModel,
    ISharedDataFromInAndOut {
  videoEditTakes: VideoEditTake[];
  plugins: IEditPlugins;
}
