import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { VoiceRecognitionService } from '../../../services/voice-recognition.service';
import { ColorPalettesService } from '../../../services/utils/color-palettes.service';
import { MatDialog } from '@angular/material/dialog';
import { TextPortion } from '../../../models/Teleprompter';
import { DirectorCommand } from '../../../models/defines';
import { CopywriterService } from '../../../services/show/copywriter.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrompterSyncronizerService } from '../../../services/prompter-syncronizer.service';
import { v4 as uuidv4 } from 'uuid';
import { ApplyPrompterTextComponent } from '../../dialogs/apply-prompter-text/apply-prompter-text.component';

@Component({
  selector: 'app-teleprompter',
  templateUrl: './teleprompter.component.html',
  styleUrls: ['./teleprompter.component.scss'],
})
export class TeleprompterComponent implements OnInit, OnDestroy {
  textAlignments = {
    LEFT: { align: 'left', icon: 'format_align_left' },
    RIGHT: { align: 'right', icon: 'format_align_right' },
    Center: { align: 'center', icon: 'format_align_center' },
  };
  displayNotes = false;
  prompterID = uuidv4();
  @Output('finished-walkthrough-step') finishedWalkthroughStep =
    new BehaviorSubject<boolean>(false);

  @ViewChild('scroller') scroller: ElementRef<HTMLTextAreaElement>;
  @ViewChild('scrollerWrap') scrollerWrap: ElementRef<HTMLTextAreaElement>;
  @Input() hosted = false;

  @Output('text-changed')
  textChanged = new EventEmitter<string>();

  textAlignment = this.textAlignments.LEFT;
  flippedX = false;
  flippedY = false;
  notes = '';
  NOTES_LOCALSTORAGE_KEY = 'prompterNotes';
  @Input('backgroundColor')
  backgroundColor = '#222';
  manualText = '';
  previousText: string = '';
  @Input('textColor')
  textColor = '#fff';

  @Input('fontSize')
  fontSize = 30;
  speed = 7;
  speedToScroll: number = Math.max(this.speed / 4, 1);
  margin = 2;
  playing = false;
  scrollInterval;

  @Input('aiMode')
  aiMode = false;
  masterUp = false;
  notificationOpen = false;
  private onDestroy$: Subject<boolean> = new Subject();

  constructor(
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public copywriter: CopywriterService,
    private _snackBar: MatSnackBar,
    public prompterSync: PrompterSyncronizerService,
    public voiceRecognition: VoiceRecognitionService,
    public colorPalettesService: ColorPalettesService
  ) {
    // this.voiceRecognition.init()
  }

  publish() {
    this.prompterSync.publish(this.prompterID, 'Version1', this.manualText);
    this._snackBar.open('🥳 Script published to guests!', 'Dismiss', {
      duration: 3000,
    });

    this.setCopy(this.manualText, false);
  }

  notifyUp() {
    this.prompterSync.connectSlave(this.prompterID);
  }

  onSpeedChange(newSpeed: number) {
    this.speedToScroll = Math.max(newSpeed / 4, 1);
  }
  resetTeleprompterState() {
    this.rewindToStart('smooth');
    /// Only if we play we reset the button
    if (this.playing) {
      this.playPause(false);
    }
  }
  ngOnInit(): void {
    this.subscribeToPromptSync();

    let prompterNotes = localStorage.getItem(this.NOTES_LOCALSTORAGE_KEY);
    this.notes = prompterNotes ?? '';

    /// Connect
    if (!this.hosted) {
      this.prompterSync.masterUp.subscribe((status) => {
        console.log('Changing Master to  ' + status);
        this.masterUp = status;
        this.cdRef.detectChanges();
      });
      this.notifyUp();

      this.prompterSync.grabPrompterText.subscribe((messageData) => {
        console.log(messageData);

        if (messageData && !this.notificationOpen) {
          this.notificationOpen = true;
          const applyPrompterTextDialog = this.dialog.open(
            ApplyPrompterTextComponent,
            {
              disableClose: false,
              panelClass: 'padding-modal',
            }
          );

          applyPrompterTextDialog.afterClosed().subscribe((approval) => {
            this.notificationOpen = false;
            // console.log('End Live Dialog result:', approval);
            // If the user approved, end live
            if (approval) {
              if (messageData.version && messageData.text) {
                this.manualText = messageData.text;
              }
            }
          });
        }
      });
    } else {
      /// For the hosted prompter
      this.prompterSync.publishPrompterText.subscribe((message) => {
        // console.log('Messsaaaage', message)
        if (message?.text) {
          this.manualText = message.text;
          this.copywriter.copy.next(message.text);
        }
      });
    }
  }

  onTextBlur(event: Event): void {
    const inputElement = event.target as HTMLTextAreaElement;
    const newText = inputElement.value;

    if (newText !== this.previousText) {
      this.manualText = newText;
      this.previousText = newText;
      // this.sendTextToServer(newText);
      this.textChanged.emit(this.previousText);
    }
  }

  saveNotes() {
    if (this.notes) {
      localStorage.setItem(this.NOTES_LOCALSTORAGE_KEY, this.notes);
    }
  }

  startService() {
    this.voiceRecognition.start();
  }

  stopService() {
    this.voiceRecognition.stop();
  }

  toggleTextAlign() {
    if (this.textAlignment === this.textAlignments.LEFT) {
      this.textAlignment = this.textAlignments.Center;
    } else if (this.textAlignment === this.textAlignments.Center) {
      this.textAlignment = this.textAlignments.RIGHT;
    } else {
      this.textAlignment = this.textAlignments.LEFT;
    }
  }

  rewindToStart(behavior: ScrollBehavior) {
    if (this.scrollerWrap?.nativeElement) {
      this.scrollerWrap.nativeElement.scrollTo({ behavior: behavior, top: 0 });
    }
  }

  flip() {}

  setCopy(copy, showSnackbar = true) {
    this.copywriter.setCopy(copy);
    if (showSnackbar) {
      this._snackBar.open('Copy Saved', 'Dismiss', { duration: 2000 });
    }
  }

  togglleEditText() {
    this.aiMode = !this.aiMode;
    if (this.aiMode) {
    }
  }

  slideToggleChange(event) {
    this.aiMode = !this.aiMode;
  }

  displayNotesChange(event) {
    this.displayNotes = !this.displayNotes;
  }

  /**
   * Toggles play and pause states with different behaviors for AI and Manual modes.
   *
   * @param toPlayOrPause - Boolean indicating whether to play (true) or pause (false).
   */
  playPause(toPlayOrPause: boolean): void {
    this.playing = toPlayOrPause;

    if (this.aiMode) {
      this.handleAiMode();
    } else {
      this.handleManualMode();
    }
  }

  private handleAiMode(): void {
    if (this.playing) {
      this.startVoiceRecognition();
    } else {
      this.stopVoiceRecognition();
    }
  }

  private startVoiceRecognition(): void {
    this.voiceRecognition.start();
  }

  private stopVoiceRecognition(): void {
    this.voiceRecognition.stop();
  }

  /**
   * Handles the play/pause behavior in Manual mode.
   */
  private handleManualMode(): void {
    if (this.playing) {
      this.startManualScrolling();
    } else {
      this.stopManualScrolling();
    }
  }

  /**
   * Starts the manual scrolling process.
   */
  private startManualScrolling(): void {
    this.rewindToStart('auto');
    this.stopManualScrolling();
    this.scrollInterval = setInterval(() => {
      this.scrollContent();
    }, 100);
  }

  /**
   * Stops the manual scrolling process.
   */
  private stopManualScrolling(): void {
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
    }
  }

  /**
   * Scrolls the content smoothly based on the current speed.
   */
  private scrollContent(): void {
    this.scrollerWrap.nativeElement.scrollTo({
      behavior: 'smooth',
      top: this.scrollerWrap.nativeElement.scrollTop + this.speedToScroll ?? 1,
    });
  }

  getText() {
    return Array.from(this.voiceRecognition.splitText.values());
  }

  removeEvent(
    text: TextPortion,
    eventType: DirectorCommand = DirectorCommand.NEXT
  ) {
    // this.voiceRecognition.addEventAfterWord(text, eventType)
    let word = this.voiceRecognition.splitText.get(text.uuid);

    if (word.events?.length) {
      word.events = word.events.filter((obj) => {
        return obj !== eventType;
      });
      text.events = word.events;
    }
  }

  createEvent(
    text: TextPortion,
    eventType: DirectorCommand = DirectorCommand.NEXT
  ) {
    // this.voiceRecognition.addEventAfterWord(text, eventType)
    let word = this.voiceRecognition.splitText.get(text.uuid);

    word.events = word.events ?? [];
    word.events.push(eventType);
    // To Keep thing tidy
    text.events = word.events;
    //
    // let foundInWordsList = this.voiceRecognition.splitTextOnlyWords.find(x => x.uuid === text.uuid);
    // if (foundInWordsList) {
    //   foundInWordsList.events = text.events;
    // }
    console.log(this.voiceRecognition.splitText.values());
  }

  openPrompterInANewWindow() {
    window.open('/prompter', 'newwindow', 'width=800,height=600');
  }

  private subscribeToPromptSync() {
    this.prompterSync.currentPrompterText$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((newText) => {
        if (this.manualText === newText) return;

        this.manualText = newText ?? '';
        this.previousText = this.manualText;
        this.resetTeleprompterState();
      });

    this.prompterSync.requestToStartTeleprompt
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((toStart) => {
        this.playPause(toStart);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
    }
  }
}
