import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {v4 as uuidv4} from 'uuid';
import {DirectorCommand, StreamStatus} from '../models/defines';
import {TextPortion, TextType} from '../models/Teleprompter';
import {CopywriterService} from "./show/copywriter.service";

declare var webkitSpeechRecognition: any;


@Injectable({
    providedIn: 'root'
})
export class VoiceRecognitionService {

    slidingWindow = 5;

    stopWords = ['hmm', 'it', 'i', 'you', 'this', 'the', 'is', 'a', 'are', 'as', 'but'];

    recognition = null;
    speechRecognitionOn = false;

    public speechText = ''
    currentSpeechIndex;

    public splitText = new Map<string, TextPortion>();
    public splitTextOnlyWords: TextPortion[] = []
    public currentIndex = 0;
    lastFoundWord = new BehaviorSubject<TextPortion>(null);
    // currentSeachIndex = 0;

    splitterRegexp = new RegExp(`(?<${TextType.Word}>\\w+)|(?<${TextType.Space}>\\s+)|(?<${TextType.Symbol}>[^\\s\\w]+)`, "g");

    speechTempWords = '';

//text.match(/\w+|\s+|[^\s\w]+/g)
    constructor(private copywriter: CopywriterService) {
        if (typeof webkitSpeechRecognition !== 'undefined') {
            this.recognition = new webkitSpeechRecognition();
        } else {
            console.warn('webkitSpeechRecognition is not supported in this environment.');
            // Handle the case where speech recognition is not available
        }

        this.init()

        this.copywriter.copy.subscribe((newText) => {
            if (newText) {
                this.splitText = new Map<string, {
                    text: string;
                    type: TextType;
                    done: boolean;
                    uuid: string,
                    index: number
                }>();

                let lastMatch;
                let index = 0;

                while (lastMatch = this.splitterRegexp.exec(newText)) {
                    index++;
                    // console.log(lastMatch);


                    /// Split the text to 3 Groups: WORD SPACE AND SYMBOL
                    if (lastMatch) {
                        let word = lastMatch.groups[TextType?.Word];
                        let space = lastMatch.groups[TextType?.Space];
                        let symbol = lastMatch.groups[TextType?.Symbol];

                        let uuid = uuidv4()
                        if (word) {
                            this.splitText.set(uuid, {text: word, type: TextType.Word, done: false, uuid: uuid, index: index});
                            // If it's a stop word, don't add it

                            const lowerCased = word.toLowerCase();
                            if (!this.stopWords.some(word => {
                                return word === lowerCased
                            })) {
                                /// Push the lower cased word
                                this.splitTextOnlyWords.push({
                                    text: lowerCased,
                                    type: TextType.Word,
                                    done: false,
                                    uuid: uuid,
                                    index: index
                                });
                            }


                        } else if (space) {
                            this.splitText.set(uuid, {text: space, type: TextType.Space, done: false, uuid: uuid, index: index});
                        } else if (symbol) {
                            this.splitText.set(uuid, {text: symbol, type: TextType.Symbol, done: false, uuid: uuid, index: index});
                        }
                    }
                    // Avoid infinite loop
                    if (!this.splitterRegexp.global) break;
                }


                // let result = splitterRegexp.exec(newText);
                // console.log('All text', this.splitText)
                // console.log('Words', this.splitTextOnlyWords)
            }

        })

    }


    init() {
        if (!this.recognition) {
            return;
        }

        this.recognition.interimResults = true;
        this.recognition.lang = 'en-US';
        // this.recognition.continuous = true;

        this.recognition.addEventListener('result', (e) => {
            if (!(this.speechTempWords?.length > 0)) {
                return;
            }
            // console.log(e.results)
            const transcript = Array.from(e.results)
                .map((result) => result[0])
                .map((result) => result.transcript)
                .join('');

            if (transcript) {
                const regex = new RegExp(`\\b(${this.speechTempWords})\\b`);
                let newAddition = transcript;

                /// Remove the previous words from the result to get only what's new
                /// Avoid replacing very short words
                if (this.speechTempWords?.length > 1) {
                    newAddition = transcript.toLowerCase().replace(regex, '')
                }
                // console.log('New text', {transcript: transcript, previousText: this.speechTempWords, addition: newAddition});
                /// Split to words
                let srTextWords = newAddition.match(/\w+/g)
                // console.log(srTextWords)
                if (srTextWords) {
                    srTextWords.forEach(word => this.newWord(word));

                }
            }
            this.speechTempWords = transcript.toLowerCase();
            // console.log(transcript);
        });
    }


    jumpToWord(word) {
        this.currentIndex = this.splitTextOnlyWords.findIndex(splitWord => {
            return splitWord.uuid === word.uuid
        })
        this.lastFoundWord.next(this.splitTextOnlyWords[this.currentIndex]);
    }

    newWord(word) {

        let slidingWindowArr = this.splitTextOnlyWords.slice(this.currentIndex, Math.min(this.currentIndex + this.slidingWindow, this.splitTextOnlyWords.length))

        // console.log('slidingWindow', slidingWindowArr);

        for (let i = 0; i < slidingWindowArr.length; i++) {
            let slidingWord = slidingWindowArr[i];

            if (slidingWord.text === word) {
                // console.log(`word "${slidingWord.text}" found in position ${i}`);
                const newIndex = this.currentIndex + i + 1

                // run over the previous elements again invoke their events
                for (let f = 0; f <= i; f++) {
                    // console.log()

                    let originalFromSplitArray = this.splitText.get(slidingWindowArr[f].uuid)
                    // console.log('should invoke', originalFromSplitArray);

                    if (originalFromSplitArray.events?.length > 0) {
                        this.invokeWordEvent(originalFromSplitArray);
                    }
                }
                this.currentIndex = newIndex;

                this.lastFoundWord.next(slidingWord);
                break;
            }
        }
    }

    invokeWordEvent(betweenWord: TextPortion) {
        // betweenWord.events.forEach((event) => {
        //     // console.log('invoking event', event)
        //     this.formatManagerService.notifyDirectorCommand.next(event as DirectorCommand);
        // })
    }

    // addEventAfterWord(text: TextPortion, eventType: DirectorCommand) {
    //   findPosition()
    //
    //   const arr = Array.from(this.);
    //   arr.splice(index, 0, [key, value]);
    //   return new Map(arr);
    // }

    start() {
        if (this.speechRecognitionOn) {
            return;
        }
        this.speechRecognitionOn = true;
        this.recognition.start();
        // console.log("Speech recognition started")
        this.recognition.addEventListener('end', (condition) => {
            if (!this.speechRecognitionOn) {
                this.recognition.stop();
                // console.log("End speech recognition")
            } else {
                this.wordConcat()
                /// Restart the service
                this.recognition.start();
            }
        });
    }

    stop() {
        if (!this.speechRecognitionOn) {
            return;
        }
        this.speechRecognitionOn = false;
        this.wordConcat()
        this.recognition.stop();
        // console.log("End speech recognition")
    }

    wordConcat() {
        /// here we get the full text when someone stops talking.
        this.speechText = this.speechText + ' ' + this.speechTempWords;

        // console.log(srTextWords);
        //
        // // next window
        // let slidingWindowArr = this.splitTextOnlyWords.splice(this.currentIndex, this.slidingWindow)
        //
        // for (let i = 0; i < srTextWords.length; i++) {
        //   // run over the window to look for the word
        //   // if()
        // }

        this.speechTempWords = '';
    }

}
