import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { IVideoEditTake } from '../../../../../../../../models/project/edit/edit-model';

@Component({
  selector: 'app-subtitles-list',
  templateUrl: './subtitles-list.component.html',
  styleUrl: './subtitles-list.component.scss',
})
export class SubtitlesListComponent implements OnInit {
  ngOnInit(): void {
    console.log('videos on track-', this.videosOnTrack);
  }
  @Input({ required: true, alias: 'projectId' }) projectId: string;
  @Input({ required: true, alias: 'videosOnTrack' })
  videosOnTrack: IVideoEditTake[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.videosOnTrack) {
      this.videosOnTrack = changes.videosOnTrack.currentValue;
    }
  }
}
