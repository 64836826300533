import {Component, Input} from '@angular/core';

@Component({
  selector: 'loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrl: './loading-indicator.component.scss'
})
export class LoadingIndicatorComponent {
  @Input() size: 'small' | 'large' | 'extra-large' | 'extra-small' = 'small';

}
