import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getTranscriptUrlPOST } from '../../../../constants/private/urls/projects/transcript.urls';
import { ITakeInDTO } from '../../../../models/project/take/take-model';
import { IProjectInDTO } from '../../../../models/project-model';

@Injectable({
  providedIn: 'root',
})
export class TranscriptApiService {
  constructor(private http: HttpClient) {}

  generateTranscript$(projectId: string) {
    const url = getTranscriptUrlPOST(projectId);

    return this.http.post<IProjectInDTO>(url, {});
  }
}
