import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    constructor() {
    }

    public environment = process.env.NODE_ENV;
    public apiIP = environment.api;
    public prettyUrl = environment.prettyUrl;
    public debugLog = environment.debugLog;
    public enforceInvitations = environment.enforceInvitations;
    public googleFunction = environment.googleFunctions;
    public googleClientId = environment.googleClientId;
    public unleashConfig = environment.unleashConfig;
    public stripe = environment.stripe;
    public twitchClientId = null;
    public youtubeClientId = null;
    public mixpanelToken = environment.mixpanelToken;
    public isProduction = environment.production;
    public trackUserEvents =
        environment.production || environment.trackUserEvents;
    public baseCdnUrl = environment.baseCdnUrl;
    public version = environment.version;
    public hubspotProxyApi = environment.hubspotProxyApi;
    public intercomKey = environment.intercomKey;
    mediasoup = {
        address: environment.mediasoup,
        requestTimeout: 10000,
        videoConstraints: {
            frameRate: {
                ideal: 26,
                max: 30,
                min: 18,
            },
        },
        audioConstraints: {
            autoGainControl: true,
            echoCancellation: true,
            noiseSuppression: true,
        },
    };
}
