import { Injectable, OnDestroy } from '@angular/core';
import { UnleashClient } from 'unleash-proxy-client';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  of,
  Subscription,
  timer,
} from 'rxjs';
import { catchError, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';
import { ProfileService } from './show/profile.service';
import { UserService } from './api/auth/user.service';

export enum UserDeviceEnum {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}
@Injectable({
  providedIn: 'root',
})
export class FeatureService implements OnDestroy {
  private unleash: UnleashClient;
  private featureCache: Map<string, BehaviorSubject<boolean>> = new Map();
  private userSubscription: Subscription;
  private featureFlagChange$ = new BehaviorSubject<void>(null);
  userDevice: string;
  userBrowser: string;

  constructor(
    private config: ConfigurationService,
    private profileService: ProfileService,
    private userService: UserService
  ) {
    if (this.userService.isMobileUser() === true) {
      this.userDevice = UserDeviceEnum.MOBILE;
    } else {
      this.userDevice = UserDeviceEnum.DESKTOP;
    }
    this.userBrowser = this.userService.getBrowser();

    this.unleash = new UnleashClient(config.unleashConfig);
    this.subscribeToUserChanges();
    this.unleash.start();
  }

  hasFeatureAccess(feature: string): Observable<boolean> {
    if (!this.featureCache.has(feature)) {
      const subject = new BehaviorSubject<boolean>(false);
      this.featureCache.set(feature, subject);
      this.updateFeatureFlag(feature);
    }
    return this.featureCache.get(feature).asObservable();
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  private subscribeToUserChanges(): void {
    this.userSubscription = this.profileService.user$
      .pipe(
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        ),
        catchError((error) => {
          console.error('Error in user subscription:', error);
          return EMPTY;
        })
      )
      .subscribe(async (user) => {
        if (user) {
          try {
            // console.log('Feature-Service: Updating user context:', user);
            this.unleash.updateContext({
              properties: {
                'user._id': user._id,
                'user.email': user.email,
                'user.plan': user.plan,
                'user.device': this.userDevice ? this.userDevice : '',
                'user.browser': this.userBrowser ? this.userBrowser : '',
              },
              userId: user.email,
            });
            // console.log('Feature-Service: Updated user context:', user);
            this.invalidateCache();
          } catch (error) {
            console.error('Error updating Unleash context:', error);
          }
        } else {
          this.unleash.updateContext({});
          this.invalidateCache();
        }
      });
  }

  private updateFeatureFlag(feature: string, forceUpdate = false): void {
    timer(0, this.config.unleashConfig.cacheInterval)
      .pipe(
        switchMap(() => this.checkFeature(feature)),
        catchError((error) => {
          console.error(`Error checking feature ${feature}:`, error);
          return of(false);
        })
      )
      .subscribe((isEnabled) => {
        if (this.featureCache.has(feature)) {
          this.featureCache.get(feature).next(isEnabled);
        }
      });
  }

  private checkFeature(feature: string): Observable<boolean> {
    try {
      const isEnabled = this.unleash.isEnabled(feature);
      return of(isEnabled);
    } catch (error) {
      console.error(`Error checking feature ${feature}:`, error);
      return of(false);
    }
  }

  get featureFlagChange(): Observable<void> {
    return this.featureFlagChange$.asObservable();
  }

  private invalidateCache() {
    this.featureCache.forEach((_, feature) =>
      this.forceUpdateFeatureFlag(feature)
    );
    this.featureFlagChange$.next();
  }

  /**
   *  Forces an update of a feature flag from unleash.
   * @param feature
   * @private
   */
  private forceUpdateFeatureFlag(feature: string) {
    if (!this.featureCache.has(feature)) {
      this.featureCache.set(feature, new BehaviorSubject<boolean>(false));
    }
    this.featureCache.get(feature).next(this.unleash.isEnabled(feature));
  }
}
