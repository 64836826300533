import { Injectable } from '@angular/core';
import { VideoEditTake } from 'src/app/models/job/edit-job-schema';
import { IConvertor } from 'src/app/models/project-model';

import { IScene } from 'src/app/models/project/scene-model';
import {
  ITake,
  ITakeWithLottieComposed,
} from 'src/app/models/project/take/take-model';
import { IComposeAndPlayOrNot } from '../../../../models/job/editor-defines';
import {
  IBasicSceneTakePair,
  IEditInDTO,
  IExportEditJob,
  IExportEditOutDTO,
  IVideoEditTakeInDTO,
} from 'src/app/models/project/edit/edit-model';
import { EditStylesConverterService } from './styles/edit-styles.converter.service';
import { ExportQualityEnum } from '../../../../models/defines';
import { IEditPlugins } from '../../../../models/project/edit/subtitles/styles/edit-styles.model';

@Injectable({
  providedIn: 'root',
})
export class EditConvertorService
  implements IConvertor<IEditInDTO, IExportEditJob, IExportEditOutDTO>
{
  constructor(private editStyleConverter: EditStylesConverterService) {}

  inToLocal(
    input: IEditInDTO,
    scenes: IScene[],
    baseProjectPath: string,
    projectId: string
  ): IExportEditJob {
    if (!input || !scenes || !baseProjectPath) {
      console.error(
        `Could not convert in edit dto to local because one of the arguments is null.`
      );
      return;
    }

    const localVideosOnTrack: VideoEditTake[] = [];
    const copyOfVideoTakes: IVideoEditTakeInDTO[] = JSON.parse(
      JSON.stringify(input.videoEditTakes)
    );
    const copyOfScenes: IScene[] = scenes.map((scene) =>
      scene.clone(projectId)
    );

    for (const videoScene of copyOfVideoTakes) {
      let currentScene: IScene;
      let currentTake: ITake;
      for (const scene of copyOfScenes) {
        const take = scene.takes.find((take) => take.id === videoScene.take.id);
        if (!take || !take.startTime || !take.endTime) {
          continue;
        }
        currentTake = take;
        currentScene = scene;
        break;
      }
      if (!currentScene || !currentTake) {
        continue;
      }

      /// We want to override the current lottie video composed of the local take because we want to display it in the track
      /// And inside the track we have trims for our video layers
      const newLottieComposedConfigs: IComposeAndPlayOrNot = JSON.parse(
        JSON.stringify(currentTake.lottieComposedConfigs)
      );

      const takeWithComposeConfigs: ITakeWithLottieComposed = {
        ...videoScene.take,
        title: currentTake.title,
        lottieComposedConfigs: newLottieComposedConfigs,
      };

      const localVideoScene = new VideoEditTake(
        takeWithComposeConfigs,
        {},
        currentScene.id,
        currentScene.name,
        videoScene.trims
      );
      localVideosOnTrack.push(localVideoScene);
    }
    const localEditStyle = input.plugins
      ? this.editStyleConverter.inToLocal(input.plugins)
      : null;
    const localEdit: IExportEditJob = {
      id: input.id,
      aspectRatio: input.aspectRatio,
      createdAt: input.createdAt,
      exportQuality: input.exportQuality,
      exports: input.exports,
      status: input.status,
      toMaxine: input.toMaxine,
      videoEditTakes: localVideosOnTrack,
      plugins: localEditStyle,
    };
    return localEdit;
  }

  localToOut(
    client: IExportEditJob,
    projectId: string,
    selectedShots: IBasicSceneTakePair[]
  ): IExportEditOutDTO {
    if (!client) {
      console.error(
        `Could not convert in edit dto to local because one of the arguments is null.`
      );
      return;
    }

    const outEditStyle = this.editStyleConverter.localToOut(client.plugins);

    const outEditExport: IExportEditOutDTO = {
      projectId: projectId,
      exportQuality: client.exportQuality ?? ExportQualityEnum.P1080,
      aspectRatio: client.aspectRatio,
      selectedShots: selectedShots,
      toMaxine: client.toMaxine,
      plugins: outEditStyle,
    };
    return outEditExport;
  }
}
