
export enum TextType {
  Word = 'word',
  Space = 'space',
  Symbol = "symbol",
}

export  interface TextPortion {
  text: string;
  type: TextType;
  done: boolean;
  uuid: string;
  index: number;
  events?: string[];
}
