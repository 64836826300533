import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-replace-take-dialog',
  templateUrl: './replace-take-dialog.component.html',
  styleUrl: './replace-take-dialog.component.scss',
})
export class ReplaceTakeDialogComponent {
  constructor(public dialogRef: MatDialogRef<ReplaceTakeDialogComponent>) {}

  replaceTake() {
    this.closeDialog(true);
  }

  closeDialog(confirmReplace) {
    this.dialogRef.close(confirmReplace);
  }
}
