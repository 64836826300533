import { SnackbarActionEnum } from 'src/app/services/utils/snack-bar.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throttleTime } from 'rxjs';
import {
  IProject,
  ScenePropertiesToUpdateEnum,
} from 'src/app/models/project-model';
import { IScene } from 'src/app/models/project/scene-model';
import { ITake } from 'src/app/models/project/take/take-model';
import { SnackBarService } from '../utils/snack-bar.service';
import { ProjectStoreService } from 'src/app/services/state-management/project/project-store.service';
import { ProjectAuthApiService } from 'src/app/services/api/auth/project-auth-api.service';
import { ProjectStatusEnum } from 'src/app/models/defines';
import { ArtDirectorService } from '../art-director.service';

@Injectable({
  providedIn: 'root',
})
export class StudioProjectManagerService {
  private _currentScene$ = new BehaviorSubject<IScene>(null);
  private _currentTake$ = new BehaviorSubject<ITake>(null);
  project: IProject;

  public project$ = this.projectStoreService.projectSource$;
  public currentScene$ = this._currentScene$.asObservable();
  public currentTake$ = this._currentTake$.asObservable();

  private currentSceneId: string;

  constructor(
    private snackBarService: SnackBarService,
    private projectStoreService: ProjectStoreService,
    private projectApiService: ProjectAuthApiService,
    private artDirectorService: ArtDirectorService
  ) {
    this.projectStoreService.projectSource$.subscribe((project) => {
      this.project = project;

      if (!project) return;

      /// if the scene/take inside project was updated, we want to know about it here and
      /// update our current scene/take values
      const scene = project.scenes.find(
        (scene) => scene.id === this.currentSceneId
      );
      this._currentScene$.next(scene);
    });

    this.currentScene$.subscribe((scene) => {
      /// If scene is null, also current scene id is null :)
      this.currentSceneId = scene?.id;
      /// If scene is null, also current take is null. :)
      this._currentTake$.next(scene?.chosenTake);
    });
  }

  public setCurrentSceneById(sceneId: string) {
    if (!sceneId) return;
    const scene = this.project?.scenes.find((scene) => scene.id === sceneId);
    if (!scene) {
      return;
    }
    this._currentScene$.next(scene);
  }

  public async getProjectDataAsync(
    projectId: string,
    waitForLocalVideos: boolean
  ) {
    let project: IProject;
    try {
      project =
        await this.projectStoreService.setProjectSourceIfNotExistedAsync(
          projectId,
          waitForLocalVideos,
          null
        );
    } catch (error: any) {
      console.error(
        `An error occurred while trying to get project. error: ${error.message}`
      );
      this.snackBarService.openMessage(
        'WOOPS, An Error Occurred',
        SnackbarActionEnum.Dismiss,
        10000
      );
      return;
    }

    if (!project) {
      console.error(`Could not find project!`);
      this.snackBarService.openMessage(
        `Could not find project!`,
        SnackbarActionEnum.Dismiss,
        10000
      );
      return;
    }
    if (!project.designGroup) {
      console.error(`No format in project.`);
      this.snackBarService.openMessage(
        'WOOPS, An Error Occurred',
        SnackbarActionEnum.Dismiss,
        10000
      );
      return;
    }
    try {
      this.projectApiService
        .updateProjectStatus$(
          project.id,
          project.statuses,
          ProjectStatusEnum.RECORDING
        )
        ?.subscribe((response) => {});
    } catch (error: any) {
      console.error(
        `An error occurred while trying to update project status. error: ${error.message}`
      );
      this.snackBarService.openMessage(
        'WOOPS, An Error Occurred',
        SnackbarActionEnum.Dismiss,
        10000
      );
      return;
    }

    return this.artDirectorService.preloadProjectAssetsAsync(project);
  }

  public async updateSceneTeleprompterAsync(nextText: string, scene: IScene) {
    //TODO: Think how to solve this because once we will add more properties like formControls that cannot be parsed as JSON it will cause problems
    const fakeScene: IScene = JSON.parse(JSON.stringify(scene, replacerFunc()));

    // const fakeScene: IScene = JSON.parse(JSON.stringify(safeScene));
    fakeScene.copy.script = nextText;

    const params = {
      projectId: this.project.id,
      sceneId: scene.id,
      value: fakeScene.copy,
      key: ScenePropertiesToUpdateEnum.COPY,
    };
    this.projectApiService
      .updateSceneProperty$(params)
      .pipe(
        throttleTime(500) // Allow at most 1 request per 500ms
      )
      .subscribe((updatedScene) => {
        if (!updatedScene) return;
        this.projectStoreService.updateSceneProperty(
          params.projectId,
          params.sceneId,
          params.key,
          params.value
        );
      });
  }
}

const replacerFunc = () => {
  const visited = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (visited.has(value)) {
        return;
      }
      visited.add(value);
    }
    return value;
  };
};
