import {
  IComposedEditTakeConfigs,
  IExtendedComposedConfigs,
} from '../../../../../models/job/editor-defines';
import { IVideoEditTake } from '../../../../../models/project/edit/edit-model';
import { IEditAudioScene } from '../../../../../models/job/edit-job-schema';
import { ISceneAndEditTakes } from '../../../../../models/defines';

export enum PlayType {
  SPECIFIC_SCENE = 'specific_scene',
  TIMELINE = 'timeline',
}

export interface IComposedConfigsTracker {
  composedConfigs: IExtendedComposedConfigs;
  videoEditTakes: IVideoEditTake[];
  audioScenes: IEditAudioScene[];
}

export interface IEditState {
  scenesAndVideos: ISceneAndEditTakes[];
  videos: IVideoEditTake[];
  audios: IEditAudioScene[];
  /// to transform this into marker position .
  currentRunningTime: number;
}
